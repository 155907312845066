import React from 'react';
import { faCircleCaretDown, faCircleCaretUp } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';

/**
 *
 * @description Implements sorting UI component
 * @param {boolean} ascSortOrder - defines if sort order is ascendant (true, default) or descendant (false)
 * @param {string} sortBy - defines by which value the object is sorted by
 * @param {string} forHeader - defines a constant comparison element with sortBy parameters to determine if the color must be active or not
 * @param {string} [sortColor=THEME_COLORS.brand] - sort icon color
 * @returns {React.Component} the sort component
 */

const Sort = ({ ascSortOrder, sortBy, forHeader, sortColor = THEME_COLORS.brand }) => {
  const activeSort = sortBy === forHeader;
  return (
    <Box margin={{ left: 'xsmall' }}>
      {ascSortOrder ? (
        <FontAwesomeIcon icon={activeSort ? faCircleCaretUp : faCaretUp} color={sortColor} />
      ) : (
        <FontAwesomeIcon icon={activeSort ? faCircleCaretDown : faCaretDown} color={sortColor} />
      )}
    </Box>
  );
};

export default Sort;
