import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'grommet';
import { theme } from 'src/config/theme';

export const NoMatchingEvent = ({ dateFilter = '', specialtyFilter = '' }) => {
  const { t } = useTranslation();
  let messageId;
  if (dateFilter && specialtyFilter) {
    messageId = 'no.event.for.criteria';
  } else {
    if (dateFilter) {
      messageId = 'no.event.for.date';
    } else {
      messageId = 'no.event.for.type';
    }
  }
  return (
    <NoData pad="medium" margin={{ top: 'small' }} descriptionColor={theme.global.colors.text}>
      {t(messageId)}
    </NoData>
  );
};

const NoData = ({ children, descriptionColor = 'brand', titleColor = 'brand', title, ...rest } = {}) => {
  return (
    <Box align="center" flex={false} {...rest} gap="medium">
      {title && (
        <Text textAlign="center" size="large" weight={700} color={titleColor}>
          {title}
        </Text>
      )}
      <Text color={descriptionColor} textAlign="center" size="medium">
        {children}
      </Text>
    </Box>
  );
};

export default NoData;
