import React from 'react';
import { useTranslation } from 'react-i18next';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Text } from 'grommet';
import { upperCase } from 'lodash';
import { Modal } from 'src/components';
import { theme, THEME_COLORS } from 'src/config/theme';

/**
 * Modal alert
 * @component
 * @param {Object} props - component properties
 * @param {boolean} props.show - if true Modal shows
 * @param {callback} props.setShow - function to call to change Modal display state
 * @param {string} props.message - alert message
 * @param {string} [props.details=''] - optional details message
 * @param {Object} [props.icon=defaultIcon] - optional custom alert icon
 * @param {callback} [props.closeAction=undefined] - optional callback to trigger when alert closes
 * @returns {ReactFragment}
 */
const Alert = ({ show, setShow, message, details = '', icon, closeAction }) => {
  const { t } = useTranslation();

  const defaultIcon = <FontAwesomeIcon icon={faTriangleExclamation} color={THEME_COLORS.red} size="2x" />;

  return (
    <Modal show={show} setShow={setShow} flex={false} pad="none">
      <Box gap="medium" justify="center" align="center" pad="medium">
        <Box gap="small" direction="row">
          {icon ? icon : defaultIcon}
          <Box align="center" justify="center" pad="none" gap="small">
            <Text textAlign="center" level="h2" color={theme.global.colors.title}>
              {message}
            </Text>
            {details !== '' && (
              <Text size="small" textAlign="center">
                {details}
              </Text>
            )}
          </Box>
        </Box>
        <Box align="center">
          <Button
            primary
            label={upperCase(t('common.label.ok'))}
            onClick={() => {
              setShow(false);
              if (closeAction) {
                closeAction();
              }
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default Alert;
