import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { pdfTableColumnsWidth } from 'src/utility/table';

const borderColor = '#000';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 55,
    textAlign: 'center',
    flexGrow: 1,
    fontSize: 10
  },
  cellBorder: { borderRightColor: borderColor, borderWidth: 1 },
  cell: {
    height: '100%',
    padding: '10px 5px',
    width: '15%',
    backgroundColor: '#E8E8E8',
    flexGrow: 1,
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'bold'
  }
});

const TableHeader = ({ groupSessionEvent, eventHasRevenueInfo }) => {
  const { t } = useTranslation();
  const columns = pdfTableColumnsWidth(groupSessionEvent);
  return (
    <View style={styles.container}>
      {!groupSessionEvent && (
        <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.slot }}>
          {t('pdf.events.analytics.slot')}
        </Text>
      )}
      <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.nameWidth }}>
        {t('pdf.events.analytics.lastName')}
      </Text>
      <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.nameWidth }}>
        {t('pdf.events.analytics.firstName')}
      </Text>
      <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.status }}>
        {t(groupSessionEvent ? 'pdf.events.analytics.presence.status' : 'pdf.events.analytics.appointment.status')}
      </Text>
      <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.confirmation }}>
        {t(
          groupSessionEvent ? 'pdf.events.analytics.attendee.confirmation' : 'pdf.events.analytics.patient.confirmation'
        )}
      </Text>
      {eventHasRevenueInfo && (
        <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.revenue }}>
          {t('pdf.events.analytics.revenue')}
        </Text>
      )}
      <Text style={{ ...styles.cell, ...styles.cellBorder }}>{t('pdf.events.analytics.validity')}</Text>
    </View>
  );
};

export default TableHeader;
