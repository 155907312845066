import { Text } from 'grommet';
import i18n, { t } from 'i18next';
import { PRACTITIONER_PRICE_MAX_VAL, PRACTITIONER_PRICE_MIN_VAL } from 'src/constants';
import store from '../../app/store';

// used for specialty visibility bitmask
// can be any combination of values, not zero meaning enabled otherwise disabled
export const SPECIALTY_VISIBILITY = {
  disabled: 0,
  practitioner: 1, // only for NON group session specialty
  company: 2,
  speaker: 4, // only for group session specialty
  practitionerAndCompany: 3, // only for non group session specialty
  speakerAndCompany: 6 // only for group session specialty
};

/**
 * Options for the regulated input displayed on the specialty form
 */
export let regulatedOptions;
/**
 * Options for the visibility input displayed on the specialty form
 */
export let visibilityOptions;
/**
 * Options for the consultationTypeOptions input displayed on the specialty form
 */
export let consultationTypeOptions = [];

i18n.on('initialized', function () {
  regulatedOptions = [
    { label: <Text size="14px">{t('common.label.specialty.regulated')}</Text>, value: true },
    { label: <Text size="14px">{t('common.label.specialty.not.regulated')}</Text>, value: false }
  ];
  visibilityOptions = [
    { label: t('specialty.practitioner.visibility.label'), value: SPECIALTY_VISIBILITY.practitioner },
    { label: t('specialty.speaker.visibility.label'), value: SPECIALTY_VISIBILITY.speaker },
    { label: t('specialty.company.visibility.label'), value: SPECIALTY_VISIBILITY.company }
  ];
  consultationTypeOptions = [
    { label: t('common.specialty.consultationType.individual'), value: SPECIALTY_CONSULTATION_TYPE_INDIVIDUAL },
    { label: t('common.specialty.consultationType.group'), value: SPECIALTY_CONSULTATION_TYPE_GROUP },
    { label: t('common.specialty.consultationType.live'), value: SPECIALTY_CONSULTATION_TYPE_LIVE },
    { label: t('common.specialty.consultationType.phone'), value: SPECIALTY_CONSULTATION_TYPE_PHONE },
    { label: t('common.specialty.consultationType.video'), value: SPECIALTY_CONSULTATION_TYPE_VIDEO }
  ];
});

/**
 * returns specialty visibility label based on visibility bitmask value
 * @param {number} visibility - the specialty visibility bitmask value
 * @returns {string} the specialty visibility label
 */
export const getSpecialtyVisibilityLabel = (visibility) => {
  if (visibility === SPECIALTY_VISIBILITY.disabled) {
    return t('specialty.disabled.label');
  }
  let label = '';
  if (visibility & SPECIALTY_VISIBILITY.practitioner) {
    label = t('specialty.practitioner.visibility.label');
  }
  if (visibility & SPECIALTY_VISIBILITY.speaker) {
    label = t('specialty.speaker.visibility.label');
  }
  if (visibility & SPECIALTY_VISIBILITY.company) {
    if (label) {
      label += ' et ';
    }
    label += t('specialty.company.visibility.label');
  }
  return label.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
};

/**
 * returns an array of visibility options based on given bitmask
 * @param {number} bitmask visibility bitmask
 * @returns {Object[]} array of visibility options
 */
export const decodeVisibilityOptions = (bitmask) => {
  return visibilityOptions.filter((option) => bitmask & option.value);
};

// used for specialty appointment pricing label
export const SPECIALTY_APPOINTMENT_PRICING_FREE = -1;
export const SPECIALTY_APPOINTMENT_PRICING_VARIABLE = 0; // depends on consultation act
export const SPECIALTY_APPOINTMENT_PRICING_FIXED = 1;
export const SPECIALTY_APPOINTMENT_PRICING_CUSTOM = 2; // practitioner allowed to edit price

/**
 * returns specialty appointment pricing label based on appointmentPricing int value
 * @param {object} specialty - the specialty to get price details from
 * @returns {string} the specialty appointment pricing label
 */
export const getSpecialtyAppointmentPricingLabel = (specialty) => {
  const {
    appointmentPricing,
    minCustomPrice = PRACTITIONER_PRICE_MIN_VAL,
    maxCustomPrice = PRACTITIONER_PRICE_MAX_VAL,
    customPrice = false
  } = specialty;
  switch (appointmentPricing) {
    case SPECIALTY_APPOINTMENT_PRICING_FREE:
      return t('common.specialty.pricing.free');
    case SPECIALTY_APPOINTMENT_PRICING_VARIABLE:
      return t('common.specialty.appointment.pricing.variable');
    default:
      if (customPrice) {
        return `${t('common.specialty.appointment.pricing.custom')} : ${t(
          'common.label.pricing'
        )} ${appointmentPricing}€\u00A0\u00A0-\u00A0\u00A0[${minCustomPrice}€ - ${maxCustomPrice}€]`;
      }
      return `${t('common.specialty.appointment.pricing.fixed')}\u00A0\u00A0-\u00A0\u00A0${appointmentPricing}€`;
  }
};

export const customPriceLabel = (customPrice, appointmentPrice, minCustomPrice, maxCustomPrice) => {
  if (!customPrice) {
    return `${t('common.specialty.appointment.pricing.custom')}\u00A0\u00A0-\u00A0\u00A0${t(
      'common.label.pricing.undefined'
    )}`;
  }
  const data =
    appointmentPrice !== '' && appointmentPrice >= minCustomPrice && appointmentPrice <= maxCustomPrice
      ? `${t(
          'common.label.pricing'
        )} : ${appointmentPrice}€\u00A0\u00A0-\u00A0\u00A0[${minCustomPrice}€ - ${maxCustomPrice}€]`
      : t('common.label.pricing.undefined');
  return `${t('common.specialty.appointment.pricing.custom')}\u00A0\u00A0-\u00A0\u00A0${data}`;
};

/**
 * Options for the appointmentPricing input displayed on the specialty form
 * if specialty is delegate, custom and variable prices are disabled
 */
export const getAppointmentPricingOptions = (values) => {
  const { appointmentPrice, customPrice, minCustomPrice, maxCustomPrice, delegate, consultationType } = values;
  const isGroupSessionEvent = consultationType & SPECIALTY_CONSULTATION_TYPE_GROUP;
  return isGroupSessionEvent
    ? [
        { label: t('common.specialty.pricing.free'), value: SPECIALTY_APPOINTMENT_PRICING_FREE },
        { label: t('common.specialty.appointment.pricing.fixed'), value: SPECIALTY_APPOINTMENT_PRICING_FIXED }
      ]
    : delegate
      ? [
          { label: t('common.specialty.pricing.free'), value: SPECIALTY_APPOINTMENT_PRICING_FREE },
          { label: t('common.specialty.appointment.pricing.fixed'), value: SPECIALTY_APPOINTMENT_PRICING_FIXED }
        ]
      : [
          { label: t('common.specialty.pricing.free'), value: SPECIALTY_APPOINTMENT_PRICING_FREE },
          { label: t('common.specialty.appointment.pricing.variable'), value: SPECIALTY_APPOINTMENT_PRICING_VARIABLE },
          { label: t('common.specialty.appointment.pricing.fixed'), value: SPECIALTY_APPOINTMENT_PRICING_FIXED },
          {
            label: customPriceLabel(customPrice, appointmentPrice, minCustomPrice, maxCustomPrice),
            value: SPECIALTY_APPOINTMENT_PRICING_CUSTOM
          }
        ];
};

/**
 * returns a boolean that defines if the fixed appointmentPricing option is selected or not
 * @param {number|string} value appointmentPricing option value
 * @returns {boolean}
 */
export const isFixedAppointmentPricing = (value) => {
  return value > 0 || value === '';
};

/**
 * returns the appointmentPricing option on given value
 * @param {number | string} value appointmentPricing value
 * @returns {Object} appointmentPricing option
 */
export const decodeAppointmentPricingOptions = (values) => {
  const { appointmentPricing, appointmentPrice, customPrice, minCustomPrice, maxCustomPrice } = values;
  if (isFixedAppointmentPricing(appointmentPricing)) {
    if (customPrice) {
      return { value: 2, label: customPriceLabel(appointmentPrice, minCustomPrice, maxCustomPrice) };
    }
    return { value: 1, label: 'Fixe' };
  }
  const targetOption = getAppointmentPricingOptions(values).find((option) => appointmentPricing === option.value);
  return targetOption || '';
};

// used for specialty consultation type bitmask
// consultationType property value can be any combination of values
// except INDIVIDUAL and GROUP that can't be set simultaneously
export const SPECIALTY_CONSULTATION_TYPE_INDIVIDUAL = 1;
export const SPECIALTY_CONSULTATION_TYPE_GROUP = 2;
export const SPECIALTY_CONSULTATION_TYPE_LIVE = 4;
export const SPECIALTY_CONSULTATION_TYPE_PHONE = 8;
export const SPECIALTY_CONSULTATION_TYPE_VIDEO = 16;

/**
 * returns specialty consultation type label based on consultationType bitmask value
 * @param {number} consultationType - the specialty consultationType bitmask value
 * @returns {string} the specialty consultation type label
 */
export const getSpecialtyConsultationTypeLabel = (consultationType) => {
  let label = '';

  if (consultationType & SPECIALTY_CONSULTATION_TYPE_INDIVIDUAL) {
    label = `${t('common.specialty.consultationType.individual')}, `;
  }

  if (consultationType & SPECIALTY_CONSULTATION_TYPE_GROUP) {
    label += `${t('common.specialty.consultationType.group')}, `;
  }

  if (consultationType & SPECIALTY_CONSULTATION_TYPE_LIVE) {
    label += `${t('common.specialty.consultationType.live')}, `;
  }

  if (consultationType & SPECIALTY_CONSULTATION_TYPE_PHONE) {
    label += `${t('common.specialty.consultationType.phone')}, `;
  }

  if (consultationType & SPECIALTY_CONSULTATION_TYPE_VIDEO) {
    label += `${t('common.specialty.consultationType.video')}, `;
  }

  return label
    .toLowerCase()
    .replace(/^\w/, (c) => c.toUpperCase())
    .substr(0, label.length - 2);
};

/**
 * returns an array of consultationType options based on a given bitmask
 * @param {number} bitmask consultationType bitmask
 * @returns {Object[]} array of consultationType options
 */
export const decodeConsultationTypeOptions = (bitmask) => {
  return consultationTypeOptions.filter((option) => bitmask & option.value);
};

/**
 * checks if select option should be disabled or not
 * useful to dynamically disable video or phone types when live option selected
 * and when video and/or phone options are selected => disable live option
 * group & individual consultation types are exclusive, if one is selected, the other is disabled
 * @param {Object} option - current select option
 * @param {number} bitmask - current bitmask value for selected consultation types
 * @returns {boolean} true if option should be disabled
 */
export const isDisabledConsultationTypeOptions = (option, bitmask) => {
  const { value } = option;
  switch (value) {
    case SPECIALTY_CONSULTATION_TYPE_INDIVIDUAL:
      return !!(bitmask & SPECIALTY_CONSULTATION_TYPE_GROUP);
    case SPECIALTY_CONSULTATION_TYPE_GROUP:
      return !!(bitmask & SPECIALTY_CONSULTATION_TYPE_INDIVIDUAL);
    case SPECIALTY_CONSULTATION_TYPE_LIVE:
      if (bitmask & SPECIALTY_CONSULTATION_TYPE_PHONE || bitmask & SPECIALTY_CONSULTATION_TYPE_VIDEO) {
        return true;
      }
      break;
    case SPECIALTY_CONSULTATION_TYPE_PHONE:
    case SPECIALTY_CONSULTATION_TYPE_VIDEO:
      if (bitmask & SPECIALTY_CONSULTATION_TYPE_LIVE) {
        return true;
      }
      break;
    default:
      return false;
  }
  return false;
};

/**
 * returns a bitmask calculated from options
 * @param {Object[]} values - selected options from the multiselect input
 * @returns {number} bitmask of the selected options
 */
export const encodeOptions = (values) => {
  return values.reduce((acc, option) => {
    return acc + option.value;
  }, 0);
};

/**
 * returns the list of groups to which the specialty belongs
 * @param {string} currentSpecialtyId - the current specialty id
 * @param {Object[]} allSpecialtyGroups - Array of existing groups
 * @returns {Object[]} Array of groups containing the current specialty
 */
export const getCurrentSpecialtyGroups = (currentSpecialtyId, allSpecialtyGroups) => {
  return allSpecialtyGroups.reduce((acc, group) => {
    const foundSpecialty = group.specialties.findIndex((specialty) => specialty === currentSpecialtyId);
    if (foundSpecialty !== -1) {
      acc.push({ id: group._id, label: group.label });
    }
    return acc;
  }, []);
};

/**
 * returns the list of specialties that are part of the current specialty group
 * @param {Object[]} currentGroupSpecialties - array with the specialties of the current specialty group
 * @param {Object[]} allSpecialties - Array of existing specialties
 * @returns {Object[]} Array of specialties that are related to the current group
 */
export const getCurrentGroupSpecialtyList = (currentGroupSpecialties, allSpecialties) => {
  return allSpecialties.reduce((acc, specialty) => {
    currentGroupSpecialties.forEach((currentSpecialty) => {
      if (specialty?._id === currentSpecialty) {
        acc.push(specialty);
      }
    });
    return acc;
  }, []);
};

/**
 * Position form option record to be used by form Select
 * @typedef {Object} PositionFormOption
 * @property {string} key
 * @property {string} label
 */

/**
 * Get the list of all specialties
 * @param {boolean} includeDelegate if true include specialties that are flagged as delegate
 * @returns {Object[]} - Array specialties
 */
const getAllSpecialties = (includeDelegate) => {
  const state = store.getState();
  const specialties = state?.specialties?.list || [];
  return includeDelegate ? [...specialties] : specialties.filter((specialty) => !specialty.delegate);
};

/**
 * Get the list of all specialties that are not disabled
 * @returns {Object[]} - Array of enabled specialties
 */
export const getEnabledSpecialties = () => {
  const state = store.getState();
  const specialties = state?.specialties?.list || [];
  return specialties.reduce((acc, specialty) => {
    if (specialty?.visibility !== SPECIALTY_VISIBILITY.disabled) {
      acc.push(specialty);
    }
    return acc;
  }, []);
};

/**
 * Get the list of specialties that a practitioner can sign up for
 * @returns {Object[]} - Array of specialties available to practitioners
 */
export const getPractitionerAvailableSpecialties = () => {
  const state = store.getState();
  const specialties = state?.specialties?.list || [];
  return specialties.reduce((acc, specialty) => {
    if (specialty?.visibility & SPECIALTY_VISIBILITY.practitioner) {
      acc.push(specialty);
    }
    return acc;
  }, []);
};

/**
 * Get the list of specialties that a speaker can sign up for
 * a speaker is the user who can register a to event with specialty of group session type
 * @returns {Object[]} - Array of specialties available to speakers
 */
export const getSpeakerAvailableSpecialties = () => {
  const state = store.getState();
  const specialties = state?.specialties?.list || [];
  return specialties.reduce((acc, specialty) => {
    if (specialty?.visibility & SPECIALTY_VISIBILITY.speaker) {
      acc.push(specialty);
    }
    return acc;
  }, []);
};

/**
 * Get the list of specialties that can be added to delegate list on a delegated specialty
 * @returns {Object[]} - Array of specialties available to delegation
 */
export const getDelegateReadySpecialties = () => {
  const state = store.getState();
  const specialties = state?.specialties?.list || [];
  return specialties.reduce((acc, specialty) => {
    if (specialty?.enableDelegate) {
      acc.push(specialty);
    }
    return acc;
  }, []);
};

/**
 * Get the list of specialties that can be assigned to companies by VB admin
 * @returns {Object[]} - Array of specialties available to companies
 */
export const getCompanyAvailableSpecialties = () => {
  const state = store.getState();
  const specialties = state?.specialties?.list || [];
  return specialties.reduce((acc, specialty) => {
    if (specialty?.visibility & SPECIALTY_VISIBILITY.company) {
      acc.push(specialty);
    }
    return acc;
  }, []);
};

/**
 * Get the list of specialties that are member of the default (Starter) group
 * this function already excludes specialties that are disabled (visibility = 0)
 * as group notion only exists for Companies, visibility must have company flag (bitmask)
 * to be valid
 * @returns {Object[]} - Array of active specialties member of the default group and avail for companies
 */
export const getDefaultGroupSpecialties = () => {
  const state = store.getState();
  const specialties = state?.specialties?.list || [];
  return specialties.reduce((acc, specialty) => {
    if (specialty?.defaultGroupMember && specialty?.visibility & SPECIALTY_VISIBILITY.company) {
      acc.push(specialty);
    }
    return acc;
  }, []);
};

/**
 * Get the list of options to build form delegate list Select options
 * for all specialties that allows register on delegated one
 * list sorted in alphabetical order on label values
 * @returns {PositionFormOption[]}  List of options for all delegation authorized specialties
 */
export const getDelegateListOptions = () => {
  const delegateReadySpecialty = getDelegateReadySpecialties();
  return delegateReadySpecialty
    .sort((specialty1, specialty2) => (specialty1.practitionerTitle > specialty2.practitionerTitle ? 1 : -1))
    .map((specialty) => ({ key: specialty._id, label: specialty.practitionerTitle }));
};

/**
 * Get the list of options to build form specialties Select options
 * @param {boolean} [useTitle = true] - if true use practitionerTitle field, otherwise label field
 * @param {boolean} [includeDelegate = false] - if true include specialties that are flagged as delegate
 * @returns {Object} List of all existing specialties options
 */
export const getAllSpecialtyOptions = (useTitle = true, includeDelegate = false) => {
  const allSpecialtiesOption = { key: 'all', label: `${t('common.label.all.specialties')}` };
  const field = useTitle ? 'practitionerTitle' : 'label';
  const options = getAllSpecialties(includeDelegate)
    .sort((specialty1, specialty2) => (specialty1[field] > specialty2[field] ? 1 : -1))
    .map((specialty) => ({ key: specialty._id, label: specialty[field] }));
  options.unshift(allSpecialtiesOption);
  return { options, allSpecialtiesOption };
};

/**
 * Get the list of options to build form specialties Select options
 * for all enabled specialties as practitioner
 * depending on useTitle flag, options labels are:
 * if useTitle = true => practitioner title for specialty
 * example : Opticien, Ostéopathe, ...
 * useTitle = false  => specialty label
 * example: Optique, Ostéopathie, Coaching, ...
 * list sorted in alphabetical order on label values
 * @param {boolean} [useTitle=true] - if true use practitionerTitle field, otherwise label field
 * @returns {PositionFormOption[]}  List of options for all enabled specialties as practitioner
 */
export const getPractitionerOptions = (useTitle = true) => {
  const field = useTitle ? 'practitionerTitle' : 'label';
  const availSpecialties = getPractitionerAvailableSpecialties();
  return availSpecialties
    .sort((specialty1, specialty2) => (specialty1[field] > specialty2[field] ? 1 : -1))
    .map((specialty) => ({ key: specialty._id, label: specialty[field] }));
};

/**
 * Get the list of options to build form specialties Select options
 * for all enabled specialties as speaker
 * depending on useTitle flag, options labels are:
 * if useTitle = true => speaker title for specialty
 * useTitle = false  => specialty label
 * list sorted in alphabetical order on label values
 * @param {boolean} [useTitle=true] - if true use practitionerTitle field, otherwise label field
 * @returns {PositionFormOption[]}  List of options for all enabled specialties as speaker
 */
export const getSpeakerOptions = (useTitle = true) => {
  const field = useTitle ? 'practitionerTitle' : 'label';
  const availSpecialties = getSpeakerAvailableSpecialties();
  return availSpecialties
    .sort((specialty1, specialty2) => (specialty1[field] > specialty2[field] ? 1 : -1))
    .map((specialty) => ({ key: specialty._id, label: specialty[field] }));
};

/**
 * Get the list of options to build form specialties Select options
 * for all enabled specialties as company
 * if useTitle = true => practitioner title for specialty label
 * each option object has specialty Id as key and contains a label and the specialty object itself
 * example: Optique, Ostéopathie, Coaching, ...
 * useTitle = false  => specialty label
 * example : Opticien, Ostéopathe, ...
 * list sorted in alphabetical order on label values
 * NOTE : companyGroupIds never includes the default group. As such specialties of this group are
 * automatically added here during processing
 * @param {string[]} companyGroupIds - list of specialty group Ids that are assigned to company
 * @param {boolean} [onlyOutOfBundle=false] - if true, only keep specialties with out of bundle billing mode
 * @param {boolean} [useTitle=false] - if false use label field, otherwise  practitionerTitle field
 * @returns {PositionFormOption[]}  List of options for all enabled specialties for the company
 */
export const getCompanySelectOptions = (companyGroupIds = [], onlyOutOfBundle = false, useTitle = false) => {
  const field = useTitle ? 'practitionerTitle' : 'label';
  const state = store.getState();
  const specialtyGroups = state.specialties.groups;
  const specialties = state?.specialties?.list || [];
  // get list of groups assigned to company
  const companyGroups = specialtyGroups.filter((group) => !group.disabled && companyGroupIds.includes(group._id));
  //  from all company groups, build company available list of unique specialties that are enabled
  // JS set ensures uniqueness as specialty may exist in several groups
  const specialtyIds = new Set();
  for (const group of companyGroups) {
    for (const id of group?.specialties) {
      // ignore disabled specialties & specialties that don't have company flag
      const specialty = specialties.find((specialty) => specialty._id === id);
      if (specialty && specialty?.visibility & SPECIALTY_VISIBILITY.company) {
        specialtyIds.add(id);
      }
    }
  }
  // add default group specialties that are enabled and avail for company
  getDefaultGroupSpecialties().forEach((specialty) => specialtyIds.add(specialty._id));
  return specialties
    .filter((specialty) => {
      if (specialtyIds.has(specialty._id)) {
        return onlyOutOfBundle ? specialty?.outOfBundle : true;
      }
      return false;
    })
    .sort((specialty1, specialty2) => (specialty1[field] > specialty2[field] ? 1 : -1))
    .map((specialty) => ({ key: specialty._id, label: specialty[field], specialty }));
};

/**
 * check if there is at least one delegate event in a provided events list
 * @param {Array} eventList list of events
 * @returns {boolean} true is there is one delegate event in the list, false otherwise
 */
export function computeHasDelegateEvents(eventList) {
  return eventList.some((event) => event.specialty.delegate === true);
}

/**
 * Checks if specialty is configured to generate revenue for practitioner
 * @param {Object} specialty - specialty to check
 * @returns {boolean} true if specialty is expected to generate revenue for practitioner
 */
export function specialtyGeneratesRevenue(specialty) {
  if (specialty) {
    // specialty where appointment price is free and NO equipment based pricing, so appointment completely
    // free for beneficiary, there's no need to display revenue (CA) column
    const { appointmentPricing, equipmentBasedPricing, delegate, delegationPrice } = specialty;
    // as for now for delegated specialties, the practitioner on site only manages delegated revenue
    // return false if delegatePrice is 0
    if (delegate && delegationPrice === 0) {
      return false;
    }
    return !(appointmentPricing === SPECIALTY_APPOINTMENT_PRICING_FREE && !equipmentBasedPricing);
  }
  return false;
}

export function isEquipmentAndConsultationPriceEvent(specialty) {
  if (specialty) {
    // returns true for specialty where appointment price is Fixed or custom
    // and equipment based pricing is true
    const { appointmentPricing, equipmentBasedPricing } = specialty;
    const hasConsultationPrice = appointmentPricing > 0;
    return hasConsultationPrice && equipmentBasedPricing;
  }
  return false;
}

/**
 * Checks if specialty configured to allow practitioner custom price
 * @param {Object} specialty - specialty to check
 * @returns {boolean} true if specialty accepts practitioner custom price
 */
export function isCustomPriceSpecialty(specialty) {
  return !!specialty?.customPrice;
}

/**
 * checks if specialty has a description document configured
 * @param {Object} specialty - the specialty to check
 * @returns {boolean} true if document available, false otherwise
 */
export const hasDocument = (specialty) => {
  if (specialty) {
    const { documentFileName = '' } = specialty;
    return !!documentFileName;
  }
  console.warn('hasDocument() called with undefined specialty!');
  return false;
};

/**
 * checks if an appointment for an event of this specialty has to be remote
 * ie. is either using videoconferencing or phone call
 * but practitioner will not be on site
 * @param {Object} specialty - the specialty to check
 * @returns {boolean} true if an appointment for an event of this specialty has to be remote
 */
export const isRemoteSpecialty = (specialty) => {
  const { consultationType = 0 } = specialty ?? {};
  if (consultationType & SPECIALTY_CONSULTATION_TYPE_LIVE) {
    return false;
  }
  return !!(
    consultationType & SPECIALTY_CONSULTATION_TYPE_PHONE || consultationType & SPECIALTY_CONSULTATION_TYPE_VIDEO
  );
};

/**
 * checks if specialty allows for group session events
 * @param {Object} specialty - the specialty to check
 * @returns {boolean} true if an event of this specialty expected to be of group session type
 */
export const isGroupSessionEventSpecialty = (specialty) => {
  const { consultationType = 0 } = specialty ?? {};
  return !!(consultationType & SPECIALTY_CONSULTATION_TYPE_GROUP);
};

/**
 * checks if specialty allows for videoconferencing as appointment type
 * @param {Object} specialty - the specialty to check
 * @returns {boolean} true if an appointment for an event of this specialty can use videoconferencing
 */
export const isVideoSpecialty = (specialty) => {
  const { consultationType = 0 } = specialty ?? {};
  return !!(consultationType & SPECIALTY_CONSULTATION_TYPE_VIDEO);
};

/**
 * checks if specialty allows for phone calls as appointment type
 * @param {Object} specialty - the specialty to check
 * @returns {boolean} true if an appointment for an event of this specialty can use phone calls
 */
export const isPhoneSpecialty = (specialty) => {
  const { consultationType = 0 } = specialty ?? {};
  return !!(consultationType & SPECIALTY_CONSULTATION_TYPE_PHONE);
};

/**
 * checks if specialty allows for live (face to face) appointment type
 * @param {Object} specialty - the specialty to check
 * @returns {boolean} true if an appointment for an event of this specialty are face to face
 */
export const isLiveEventSpecialty = (specialty) => {
  const { consultationType = 0 } = specialty ?? {};
  return !!(consultationType & SPECIALTY_CONSULTATION_TYPE_LIVE);
};

/**
 * computes valid visibility options for specialty based on current consultationType bitmask value
 * @param {number} consultationType - specialty current consultation type bitmask value
 * @returns {object[]} valid visibility options for specialty
 */
export const getVisibilityOptions = (consultationType) => {
  if (consultationType & SPECIALTY_CONSULTATION_TYPE_GROUP) {
    // practitioner visibility doesn't apply for group session events, keep company & speaker visibility
    return visibilityOptions.filter((option) => option.value !== SPECIALTY_VISIBILITY.practitioner);
  }
  // otherwise speaker visibility doesn't apply for group session events, keep company & practitioner visibility
  return visibilityOptions.filter((option) => option.value !== SPECIALTY_VISIBILITY.speaker);
};

/**
 * checks if specialty implies extra billing that is not included in company subscription
 * @param {Object} specialty - the specialty to check
 * @returns {boolean} true if specialty will be charged to company
 */
export const isOutOfBundlePricingSpecialty = (specialty) => {
  const { outOfBundle = false } = specialty ?? {};
  return outOfBundle;
};

/**
 * computes the count of specialties that are available for company
 * that have the outOfBundle flag set
 * out of bundle true means that specialty requires extra billing
 * @param {Object} company - company to check
 * @returns {number} count of outOfBundle specialties available for company
 */
export const getCompanyOutOfBundleSpecialtiesCount = (company) => {
  const { specialtyGroups = [] } = company ?? {};
  const state = store.getState();
  const specialtyGroupList = state.specialties.groups;
  const specialties = state?.specialties?.list || [];
  // get list of groups assigned to company
  const companyGroups = specialtyGroupList.filter((group) => !group.disabled && specialtyGroups.includes(group._id));
  // from all company groups, build company available list of unique specialties that are enabled
  // and outOfBundle flag set
  // JS set ensures uniqueness as specialty may exist in several groups
  const outOfBundleSpecialtyIds = new Set();
  for (const group of companyGroups) {
    for (const id of group?.specialties) {
      // ignore disabled specialties & specialties that don't have company flag
      const specialty = specialties.find((specialty) => specialty._id === id);
      if (
        specialty &&
        specialty?.visibility & SPECIALTY_VISIBILITY.company &&
        isOutOfBundlePricingSpecialty(specialty)
      ) {
        outOfBundleSpecialtyIds.add(id);
      }
    }
  }
  // add default group specialties that are enabled and avail for company and outOfBundle flag set
  getDefaultGroupSpecialties().forEach((specialty) => {
    if (isOutOfBundlePricingSpecialty(specialty)) {
      outOfBundleSpecialtyIds.add(specialty._id);
    }
  });
  // return set count
  return outOfBundleSpecialtyIds.size;
};
