import React from 'react';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';

const Tail = ({ sites, name, textSize, textColor, root = false }) => (
  <Text size={textSize} color={textColor} weight={700}>
    {sites && sites.length - 1 > 0
      ? `${name} (${sites.length - 1} ${sites.length - 1 > 1 ? 'sites' : 'site'})`
      : `${root ? name : ''}`}
  </Text>
);

/**
 * Implements the company hierarchical path separator component
 * @returns {React.Component}
 */
const PathSeparator = () => (
  <Box margin={{ left: '4px', right: '4px' }}>
    <FontAwesomeIcon icon={faCaretRight} color={THEME_COLORS.brand} />
  </Box>
);

const DepthNode = ({ depth, textSize, textColor }) => {
  return (
    <>
      <Text size={textSize} color={textColor} weight={700}>
        ... {depth - 2} {depth - 2 > 1 ? 'niveaux' : 'niveau'} ...
      </Text>
      <PathSeparator />
    </>
  );
};

const PathNode = ({ handleClick, parent, root, parentId, rootId, textColor, textSize }) => (
  <>
    <Text
      style={{
        textDecoration: handleClick ? 'underline' : 'none',
        cursor: handleClick ? 'pointer' : 'auto'
      }}
      size={textSize}
      color={textColor}
      weight={700}
      onClick={handleClick ? () => handleClick(parentId ? parentId : rootId) : () => null}
    >
      {parent ? parent : root}
    </Text>
    <PathSeparator />
  </>
);

const CompanySitesPath = ({
  root,
  rootId = null,
  depth,
  parent,
  parentId = null,
  name,
  sites,
  textColor = 'darkgrey',
  textSize = 'small',
  handleClick = null
}) => {
  return (
    <Box direction="row" gap="small" alignContent="center" style={{ flex: 1, alignItems: 'center' }}>
      {root ? (
        <>
          <PathNode handleClick={handleClick} root={root} rootId={rootId} textColor={textColor} textSize={textSize} />
          {depth > 2 && <DepthNode depth={depth} textColor={textColor} textSize={textSize} />}
          {root !== parent && (
            <PathNode
              handleClick={handleClick}
              parent={parent}
              parentId={parentId}
              textColor={textColor}
              textSize={textSize}
            />
          )}
          <Tail sites={sites} name={name} textSize={textSize} textColor={textColor} root={true} />
        </>
      ) : (
        <Tail sites={sites} name={name} textSize={textSize} textColor={textColor} />
      )}
    </Box>
  );
};

export default CompanySitesPath;
