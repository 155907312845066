import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { Box, Text } from 'grommet';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { eventWaitingListSize, groupSessionEventSeatStats } from 'src/utility/eventUtils';
import EventWaitingListInfo from '../../features/events/EventWaitingListInfo';

const circularProgressStyles = buildStyles({
  rotation: 1 / 2 + 1 / 8,
  strokeLinecap: 'butt',
  trailColor: 'brand',
  textSize: '34px'
});
circularProgressStyles.text.fontWeight = 'bold';

/**
 * Renders group session event attendees stats with
 * X = # of employees who registered
 * Y = # of available seats
 * Format is the following:
 * if X = 0 => "Aucune inscription" as red text
 * if X = Y => "COMPLET (Y places)"
 * otherwise X inscrit(s) / Y places
 * display of waiting list size if:
 *  - event is full
 *  - AND property showWaitingList set to true
 * @component
 * @param {Object} props Component props
 * @param {Object} props.event The event to display attendees count for
 * @param {boolean} [props.hasBookingText] Display the attendees counts as text
 * @param {boolean} [props.hasBookingMeter] Display the attendees counts as circular meter
 * @param {boolean} [props.showWaitingList=false] if true display event waiting list size
 * @returns {Component} the event attendees stats component
 */
const AttendeesCount = ({ event, hasBookingText, hasBookingMeter, showWaitingList = false, ...restProps }) => {
  const { t } = useTranslation();
  const textSize = '16px';
  const { groupSessionEvent, seats } = event ?? {};

  if (!groupSessionEvent) {
    // this component only processes group session events
    return null;
  }

  const { registered } = groupSessionEventSeatStats(event);
  const value = Math.round((registered / seats) * 100);
  if (value <= 0) {
    return (
      <Box pad="small" align="center" justify="center" style={{ minHeight: '75px' }} {...restProps}>
        <Text weight={700} size={textSize} color="status-critical" textAlign="center">
          {t('event.state.no.registration')}
        </Text>
      </Box>
    );
  }

  if (value >= 100) {
    return (
      <Box pad="small" direction="column" align="center" justify="center" style={{ minHeight: '75px' }} {...restProps}>
        <Text size={textSize} weight={600} color="status-critical">
          {t('event.state.full')}
        </Text>
        <Text size="small">{t('event.seats.count', { count: seats })}</Text>
        {showWaitingList && (
          <EventWaitingListInfo
            size={eventWaitingListSize(event)}
            textColor="#dd2e44"
            textSize="12px"
            textWeight={700}
          />
        )}
      </Box>
    );
  }

  return (
    <>
      <Box
        pad={{ horizontal: 'small', top: 'small', bottom: 'none' }}
        align="center"
        style={{ display: 'grid', grid: 'auto / 80px 60px' }}
        {...restProps}
      >
        {hasBookingText && (
          // Adding \u00a0 (non-breaking space) for better readability
          <Text size="small" margin={{ horizontal: '4px' }}>
            {t('event.user.registered', { count: registered })}
            {' /'}
            <br />
            {t('event.seats.count', { count: seats })}
          </Text>
        )}
        {hasBookingMeter && (
          <Box style={{ width: '50px', height: '50px', justifySelf: 'end' }} margin={{ horizontal: '4px' }}>
            <CircularProgressbar
              value={value}
              text={`${value}%`}
              circleRatio={0.75}
              strokeWidth={10}
              styles={circularProgressStyles}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default AttendeesCount;
