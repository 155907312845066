import React, { lazy, StrictMode, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch, useHistory, useLocation } from 'react-router-dom';
import { faCircleCheck, faRightToBracket, faTriangleExclamation, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Grommet, Text } from 'grommet';
import queryString from 'query-string';
import { i18nReady } from './utility/i18n';
import './assets/app.css';
import appInfo from './_generated_/appInfo';
import { AppBar, loader, Modal, Spinner } from './components';
import { UserRole } from './config';
import { theme, THEME_COLORS } from './config/theme';
import { logout } from './features/auth/authSlice';
import SignUpPartner from './features/auth/SignUpPartner';
import { switchToSite } from './features/companies/companiesSlice';
import AppError from './features/errors/AppError';
import { updateNavigation } from './features/navigation/navigationSlice';
import { DeployEnv, getAppConfig } from './utility/appConfig';

const SignIn = lazy(() => import('./features/auth/SignIn'));
const SendResetEmail = lazy(() => import('./features/auth/SendResetEmail'));
const SignUpCompany = lazy(() => import('./features/auth/SignUpCompany'));
const ResetPassword = lazy(() => import('./features/auth/ResetPassword'));
const SignUpEmployee = lazy(() => import('./features/auth/SignUpEmployee'));
const SignUpBeneficiaryByCode = lazy(() => import('./features/auth/SignUpBeneficiaryByCode'));
const RegistrationWelcome = lazy(() => import('./features/auth/RegistrationWelcome'));
const UserRegistration = lazy(() => import('./features/auth/UserRegistration'));
const AdminSessionMode = lazy(() => import('./features/auth/SessionMode'));
const CompanyDashboard = lazy(() => import('./features/dashboard/company/CompanyLayout'));
const EmployeeDashboard = lazy(() => import('./features/dashboard/employee/EmployeeLayout'));
const SignUpReferentPractitioner = lazy(() => import('./features/auth/SignUpReferentPractitioner'));
const PractitionerSignupWizard = lazy(() => import('./features/auth/PractitionerSignupWizard'));
const PractitionerOnboardingSetup = lazy(() => import('./features/auth/PractitionerOnboardingSetup'));
const PractitionerOnboardingSignIn = lazy(() => import('./features/auth/PractitionerOnboardingSignIn'));
const PartnerDashboard = lazy(() => import('./features/dashboard/partner/PartnerDashboard'));
const AdminDashboard = lazy(() => import('./features/dashboard/admin/AdminDashboard'));
const EmailSentLandingPage = lazy(() => import('./features/auth/EmailSentLandingPage'));
const EmailChangeValidation = lazy(() => import('./features/auth/EmailChangeValidation'));
const OptOut = lazy(() => import('./features/errors/OptOut'));
const NetworkError = lazy(() => import('./features/errors/NetworkError'));

const { deployEnv } = getAppConfig();

/**
 * Component that shows when notified by Stripe success/error webhook callback
 * after stripe checkout was requested
 * informs users about operation success or failure
 * @component
 * @param {boolean} show show if true modal dialog displays
 * @param {function} setShow function to call in order to close dialog
 * @param {"success"|"failed"} status Stripe checkout status either 'success' or 'failed'
 * @returns {Component}
 */
const StripeModal = ({ show, setShow, status }) => {
  let title = 'Souscription confirmée !';
  let modalText = 'Merci d’avoir souscrit à Viabeez.';
  let modalText2 = 'Vous pouvez désormais utiliser pleinement la plateforme.';
  let buttonLabel = 'Commencer';
  let titleColor = '#444444';
  if (status === 'failed') {
    title = 'Echec de la souscription !';
    buttonLabel = 'OK';
    modalText = "L'opération a échouée.";
    modalText2 = 'Veuillez retenter ultérieurement ou contactez le service client.';
    titleColor = 'red';
  }
  return (
    <Modal show={show} setShow={setShow} flex={false}>
      <Box pad={{ vertical: 'medium', horizontal: 'small' }} align="center" justify="center" width="medium">
        <Box width="237">
          {status === 'success' ? (
            <FontAwesomeIcon icon={faCircleCheck} color={THEME_COLORS.brand} size="3x" />
          ) : (
            <FontAwesomeIcon icon={faTriangleExclamation} color={THEME_COLORS.red} size="3x" />
          )}
        </Box>
        <Box align="center" justify="center">
          <Text level="h2" color={titleColor}>
            {title}
          </Text>
          <Text textAlign="center">
            {modalText}
            <br />
            {modalText2}
          </Text>
        </Box>
        <Box align="center" margin={{ top: 'medium' }}>
          <Button primary label={buttonLabel} onClick={() => setShow(false)} />
        </Box>
      </Box>
    </Modal>
  );
};

/**
 * App Home Component ( / path)
 * @component
 * @returns {Component}
 */
const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  dispatch(logout());
  if (deployEnv !== DeployEnv.Dev) {
    // redirect to signin page if no path provided in production mode
    history.push('/signin');
  } else {
    // show Dev Menu page
    const menuItems = [
      {
        label: <Text size={'small'}>Se connecter</Text>,
        gap: 'small',
        icon: <FontAwesomeIcon icon={faRightToBracket} size="lg" color={THEME_COLORS.brand} />,
        onClick: () => {
          history.push('/signin');
        }
      },
      {
        label: <Text size={'small'}>Créer un compte entreprise</Text>,
        gap: 'small',
        icon: <FontAwesomeIcon icon={faUserPlus} size="lg" color={THEME_COLORS.brand} />,
        onClick: () => {
          history.push('/signup-entreprise');
        }
      },
      {
        label: <Text size={'small'}>Créer un compte Professionnel de santé</Text>,
        gap: 'small',
        icon: <FontAwesomeIcon icon={faUserPlus} size="lg" color={THEME_COLORS.brand} />,
        onClick: () => {
          history.push('/onboarding-pro');
        }
      },
      {
        label: <Text size={'small'}>Créer un compte Professionnel Référent</Text>,
        gap: 'small',
        icon: <FontAwesomeIcon icon={faUserPlus} size="lg" color={THEME_COLORS.brand} />,
        onClick: () => {
          history.push('/signup-professionnel-de-sante');
        }
      },
      {
        label: <Text size={'small'}>Créer un compte bénéficiaire par code</Text>,
        gap: 'small',
        icon: <FontAwesomeIcon icon={faUserPlus} size="lg" color={THEME_COLORS.brand} />,
        onClick: () => {
          history.push('/inscription');
        }
      }
    ];

    return (
      <AppBar
        menuConfig={{
          menuItems
        }}
      />
    );
  }
};

// There are basically 3 routes types : first is the good old react-router Route, then
// there is the ProtectedRoute used for specific user dashboards, finally the AuthRoute
// that redirects the user if he is already authenticated.

// a protected route (depending on user role)
const ProtectedRoute = (props) => {
  const { allowedRoles = [] } = props;
  const history = useHistory();
  const role = useSelector((state) => state.auth?.user?.role);

  // check if the user has one of the allowed roles
  // else redirect to signin
  useEffect(() => {
    if (!allowedRoles.includes(role)) {
      history.push('/signin');
    }
  }, [role, allowedRoles, history]);

  return <Route {...props}>{allowedRoles.includes(role) && props.children}</Route>;
};

// a route that checks if user is authenticated and redirects him
// to his dashboard if so
const AuthRoute = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const company = useSelector((state) => state?.auth?.user?.company);
  const managedSite = useSelector((state) => state.auth.managedSite);
  const history = useHistory();

  // redirect the user to its dashboard if he is authenticated
  useEffect(() => {
    const switchToManagedSite = async (id) => {
      await dispatch(switchToSite(id, false));
    };

    if (user.role) {
      switch (user.role) {
        case UserRole.admin:
          history.push('/admin');
          break;
        case UserRole.adminCompany:
          if (managedSite) {
            if (user?.sites?.length) {
              if (company?._id !== managedSite) {
                // Not default admin site, need to redirect to selected site
                switchToManagedSite(managedSite);
              }
            }
            history.push('/company');
          } else {
            history.push('/session');
          }
          break;
        case UserRole.employee:
          history.push('/beneficiary');
          break;
        case UserRole.practitioner:
          // practitioner with no onBoardingCompleted property or property set to false
          // have to complete onboarding wizard first
          if (user.hasOwnProperty('onBoardingCompleted') && user.onBoardingCompleted) {
            history.push('/practitioner');
          } else {
            history.push('/onboarding-pro-setup');
          }
          break;
        case UserRole.speaker:
          history.push('/speaker');
          break;
        default:
          history.push('/signin');
          break;
      }
    }
  }, [user, managedSite, company?._id, history, dispatch]);
  return <Route>{props.children}</Route>;
};

function logAppRenderingError(error, info) {
  console.error(`Application rendering error! Error: ${error} info: ${info?.componentStack}`);
}

const AppContent = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [stripeStatus, setStripeStatus] = useState('');

  // check if there are navigation query parameters
  useEffect(() => {
    if (location.search) {
      // also check for google cross domain tracking param (_gl) and remove it if found
      // to avoid reloading loop
      let { index, subIndex, eventId, _gl, ...rest } = queryString.parse(location.search);

      if (index) {
        dispatch(updateNavigation({ index: parseInt(index) }));
      }
      if (subIndex) {
        dispatch(updateNavigation({ subIndex: parseInt(subIndex) }));
      }

      if (eventId) {
        dispatch(updateNavigation({ eventId }));
      }

      history.replace({
        search: queryString.stringify(rest)
      });
    }
  }, [dispatch, history, location.search]);

  // check success stripe query param
  // when coming from stripe callback (portal, checkout)
  useEffect(() => {
    let searchQuery = queryString.parse(location.search);
    if (searchQuery?.successStripe || searchQuery?.errorStripe) {
      // show success modal
      setModal(true);
      setStripeStatus(searchQuery?.successStripe ? 'success' : 'failed');
      // remove query param
      if (searchQuery?.successStripe) {
        delete searchQuery.successStripe;
      } else {
        delete searchQuery.errorStripe;
      }
      history.replace({
        search: queryString.stringify(searchQuery)
      });
    }
  }, [history, location.search]);

  return (
    <Grommet theme={theme} fill="horizontal">
      <StripeModal show={modal} setShow={setModal} status={stripeStatus} />
      <Suspense fallback={loader()}>
        <Switch>
          <Route exact path="/error">
            <NetworkError />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
          <AuthRoute path="/signin">
            <SignIn />
          </AuthRoute>
          <Route path="/email-sent">
            <EmailSentLandingPage />
          </Route>
          <Route path={['/forgot-password', '/mot-de-passe-oublie']}>
            <SendResetEmail />
          </Route>
          <Route path={['/reset-password', '/reset-mot-de-passe']}>
            <ResetPassword />
          </Route>
          <Route path="/change-email">
            <EmailChangeValidation />
          </Route>
          <AuthRoute path="/signup-entreprise">
            <SignUpCompany />
          </AuthRoute>
          <AuthRoute path="/signup-employee">
            <SignUpEmployee />
          </AuthRoute>
          {/* landing page route for users who want to register */}
          <AuthRoute path={['/inscription', '/registration']}>
            <RegistrationWelcome />
          </AuthRoute>
          {/* landing page route for user who has No account => register options */}
          <AuthRoute path="/signup-beneficiary">
            <UserRegistration />
          </AuthRoute>
          {/* route for beneficiary signing up with company code */}
          <AuthRoute path="/code-signup">
            <SignUpBeneficiaryByCode />
          </AuthRoute>
          {/* route for practitioner other than referent account 
              onboarding initial (signup) wizard */}
          <AuthRoute path="/onboarding-pro">
            <PractitionerSignupWizard />
          </AuthRoute>
          {/* route for practitioner other than referent account 
              onboarding final (account setup) wizard */}
          <AuthRoute path="/onboarding-pro-signin">
            <PractitionerOnboardingSignIn />
          </AuthRoute>
          <AuthRoute path="/signup-professionnel-de-sante">
            <SignUpReferentPractitioner />
          </AuthRoute>
          <AuthRoute path="/signup-partner">
            <SignUpPartner />
          </AuthRoute>
          <ProtectedRoute exact path="/onboarding-pro-setup" allowedRoles={[UserRole.practitioner]}>
            <Grommet theme={theme} overflow="auto" full background="back">
              <PractitionerOnboardingSetup />
            </Grommet>
          </ProtectedRoute>
          <ProtectedRoute exact path="/session" allowedRoles={[UserRole.adminCompany]}>
            <Grommet theme={theme} overflow="auto" full background="back">
              <AdminSessionMode />
            </Grommet>
          </ProtectedRoute>
          <ProtectedRoute exact path="/company" allowedRoles={[UserRole.adminCompany, UserRole.admin]}>
            <Grommet theme={theme} overflow="auto" full background="back">
              <CompanyDashboard />
            </Grommet>
          </ProtectedRoute>
          <ProtectedRoute path="/beneficiary" allowedRoles={[UserRole.employee, UserRole.adminCompany]}>
            <Grommet theme={theme} overflow="auto" full background="back">
              <EmployeeDashboard />
            </Grommet>
          </ProtectedRoute>
          <ProtectedRoute path="/practitioner" allowedRoles={[UserRole.practitioner]}>
            <Grommet theme={theme} overflow="auto" full background="back">
              <PartnerDashboard />
            </Grommet>
          </ProtectedRoute>
          <ProtectedRoute path="/speaker" allowedRoles={[UserRole.speaker]}>
            <Grommet theme={theme} overflow="auto" full background="back">
              <PartnerDashboard />
            </Grommet>
          </ProtectedRoute>
          <ProtectedRoute path="/admin" allowedRoles={[UserRole.admin]}>
            <Grommet theme={theme} overflow="hidden" fill="horizontal" background="back" style={{ height: '100vh' }}>
              <AdminDashboard />
            </Grommet>
          </ProtectedRoute>
          <Route path="/opt-out">
            <OptOut />
          </Route>
          <Route>
            {/* if path not found, redirect to sign in page */}
            <Redirect to="/signin" />
          </Route>
        </Switch>
      </Suspense>
    </Grommet>
  );
};

const App = () => {
  const [appLoading, setAppLoading] = useState(true);
  useEffect(() => {
    if (appInfo?.deployBranch) {
      setTimeout(() => {
        window.alert(`Deploy Branch: ${appInfo.deployBranch}`);
      }, 1000);
    }
    i18nReady.then(() => {
      setAppLoading(false);
    });
  }, []);
  if (appLoading) {
    return (
      <Box pad="large">
        <Spinner />
      </Box>
    );
  }
  return (
    <StrictMode>
      <ErrorBoundary FallbackComponent={AppError} onError={logAppRenderingError}>
        <Router>
          <AppContent />
        </Router>
      </ErrorBoundary>
    </StrictMode>
  );
};

export default App;
