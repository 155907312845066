import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Image } from 'grommet';
import styled from 'styled-components';
import logo from '../../assets/images/logo.svg';

const CustomBox = styled(Box)`
  box-shadow: 0px 4px 30px 5px rgba(0, 0, 0, 0.07);
  min-height: 100%;
`;

const MainSideMenu = (props) => {
  const history = useHistory();

  // @TODO menu for mobile
  // For now hide it
  return (
    <CustomBox
      style={{ position: 'fixed', height: '100%' }}
      width={props.width || '200px'}
      background="white"
      gap="small"
      pad={{ top: 'small', bottom: 'small' }}
      justify="between"
      {...props}
      align="center"
    >
      <Box overflow="hidden" align="center" height="38px" onClick={() => history.push('/')}>
        <Image src={logo} fit="contain" width="106px" />
      </Box>
      {props.children}
    </CustomBox>
  );
};

export default MainSideMenu;
