import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Anchor, Box, Text } from 'grommet';
import { computeDocumentUrl } from 'src/utility/documentUtils';
import { hasDocument } from './specialtiesUtils';

/**
 * Adds a link to open specialty description document if any
 * @param {Object} props Properties
 * @param {string} props.specialty - the specialty to generate link for
 * @param {string} props.linkTextId - the localization Id of link text
 * @returns {React.ReactElement}
 */
const SpecialtyDocumentLink = ({ specialty, linkTextId = 'specialty.description.document.url' }) => {
  const { t } = useTranslation();
  const appInfo = useSelector((state) => state?.auth?.appInfo || {});
  return (
    <>
      {hasDocument(specialty) && (
        <Box basis="1/3" pad="small">
          <Text size="small" color="brand" weight="bold" textAlign="center">
            <Anchor href={computeDocumentUrl(appInfo, `specialties/${specialty.documentFileName}`)} target="_blank">
              {t(linkTextId)}
            </Anchor>
          </Text>
        </Box>
      )}
    </>
  );
};

export default SpecialtyDocumentLink;
