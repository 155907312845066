import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, FormField, ResponsiveContext, Text } from 'grommet';
import { Button, Modal, PasswordReveal, PasswordValidator, T, TextInput } from 'src/components';
import { updateAdminPwd, updateAuthUserPassword as updatePwd } from 'src/features/auth/authSlice';
import { INVALID_PWD_ERROR, PASSWD_REGEXP } from 'src/utility/formValidation';
import SecurityCodeInput from '../Form/SecurityCode/SecurityCodeInput';

const INIT_PASSWORDS = {
  oldPassword: '',
  newPassword: '',
  newPasswordConf: '',
  securityCode: ''
};

const ModalResetPassword = ({ modal, setModal, requiresCode = false }) => {
  const oldPwdRef = useRef(null); // create ref to target old password input field
  const newPwdRef = useRef(null); // create ref to target new password input field
  const newPwdConfRef = useRef(null); // create ref to target new password confirmation input field
  const { t } = useTranslation();
  const [feedbackModalPwd, setFeedbackModalPwd] = useState({});
  const [passwords, setPasswords] = useState(INIT_PASSWORDS);
  const [passwordOk, setPasswordOk] = useState(false);
  const [cancelDisabled, setCancelDisabled] = useState(false);
  const dispatch = useDispatch();
  const size = useContext(ResponsiveContext);

  useEffect(() => {
    // whenever the modal is toggled, remove feedback message & clear passwords
    setFeedbackModalPwd({});
    setPasswords(INIT_PASSWORDS);
  }, [modal]);

  const checkPwdValue = useCallback(() => {
    setFeedbackModalPwd({});
    setPasswordOk(false);
    if (passwords.oldPassword !== '' && !passwords.oldPassword.match(PASSWD_REGEXP)) {
      setFeedbackModalPwd({
        status: 'error',
        message: INVALID_PWD_ERROR
      });
      return;
    }
    if (passwords.newPassword !== '' && !passwords.newPassword.match(PASSWD_REGEXP)) {
      setFeedbackModalPwd({
        status: 'error',
        message: `${t('modal.change.pwd.new.pwd.label')} : ${INVALID_PWD_ERROR}`
      });
      return;
    }
    if (passwords.newPasswordConf !== '' && !passwords.newPasswordConf.match(PASSWD_REGEXP)) {
      setFeedbackModalPwd({
        status: 'error',
        message: `${t('modal.change.pwd.confirm.pwd')} : ${INVALID_PWD_ERROR}`
      });
      return;
    } else {
      if (passwords.newPassword === '' || passwords.newPasswordConf === '') {
        return;
      }
      if (passwords.newPassword !== passwords.newPasswordConf) {
        setFeedbackModalPwd({
          status: 'error',
          message: t('modal.change.pwd.error.new.pwd.no.match')
        });
        return;
      }
      if (passwords.oldPassword === '') {
        return;
      }
      if (passwords.oldPassword === passwords.newPassword) {
        setFeedbackModalPwd({
          status: 'error',
          message: t('modal.change.pwd.error.no.diff')
        });
        return;
      }
      if (requiresCode && passwords.securityCode === '') {
        setFeedbackModalPwd({
          status: 'error',
          message: t('admin.security.code.required')
        });
        return;
      }
      setPasswordOk(true);
    }
  }, [passwords, t, requiresCode]);

  const updatePwdState = useCallback(
    (field, value, check) => {
      const pwd = { ...passwords };
      pwd[field] = value;
      setPasswords(pwd);
      if (check) {
        checkPwdValue();
      }
    },
    [passwords, setPasswords, checkPwdValue]
  );

  const handleUpdatePassword = useCallback(async () => {
    // update password
    setPasswordOk(false);
    const update = {
      oldPassword: passwords.oldPassword,
      newPassword: passwords.newPassword
    };
    const { status, error } = requiresCode
      ? await dispatch(updateAdminPwd({ ...update, code: passwords.securityCode }))
      : await dispatch(updatePwd(update));
    if (status === 'success') {
      setCancelDisabled(true);
      setFeedbackModalPwd({
        status: 'success',
        message: t('modal.change.pwd.success')
      });
      // give user 3 secs before auto closing dialog
      setTimeout(setModal, 3000, false);
    } else {
      let message;
      let err = error;
      const isFormatError = error.match('pattern') || [];
      if (isFormatError.length) {
        // check for API celebrate error returning msg like
        // "code" with value "XXXX" fails to match the required pattern: /^\d{4}$/,
        // when code provided has invalid format
        err = 'invalid_code';
      }
      switch (err) {
        case 'invalid_password':
          message = t('modal.change.pwd.old.pwd.invalid');
          break;
        case 'invalid_code':
          message = t('invalid.security.code');
          break;
        default:
          message = t('modal.change.pwd.failure');
      }
      setFeedbackModalPwd({ status: 'error', message });
    }
  }, [dispatch, setModal, t, passwords, requiresCode]);

  return (
    <Modal show={modal} setShow={setModal} flex={false} style={size === 'small' ? { height: '100%' } : {}}>
      <Box pad="medium" align="center" justify="between" width="medium">
        <Text size="large" weight="bold" color="brand" margin={{ bottom: 'small' }}>
          {t('modal.change.pwd.title')}
        </Text>
        <form>
          <FormField name="oldPassword" htmlFor="oldPassword" label={t('modal.change.pwd.old.pwd.label')}>
            <PasswordReveal inputRef={oldPwdRef}>
              <TextInput
                ref={oldPwdRef}
                type="password"
                id="oldPassword"
                name="oldPassword"
                value={passwords.oldPassword}
                onBlur={(e) => updatePwdState('oldPassword', e.target.value, true)}
                onChange={(e) => updatePwdState('oldPassword', e.target.value, false)}
                placeholder={
                  <T size="16px" color="lightGrey">
                    {t('modal.change.pwd.old.pwd.label')}
                  </T>
                }
              />
            </PasswordReveal>
          </FormField>
          <FormField name="newPassword" htmlFor="newPassword" label={t('modal.change.pwd.new.pwd.label')}>
            <PasswordReveal inputRef={newPwdRef}>
              <TextInput
                ref={newPwdRef}
                type="password"
                id="newPassword"
                name="newPassword"
                value={passwords.newPassword}
                onBlur={(e) => updatePwdState('newPassword', e.target.value, true)}
                onChange={(e) => updatePwdState('newPassword', e.target.value, false)}
                placeholder={
                  <T size="16px" color="lightGrey">
                    {t('modal.change.pwd.new.pwd.label')}
                  </T>
                }
              />
            </PasswordReveal>
          </FormField>
          <FormField name="newPasswordconf" htmlFor="newPasswordconf" label={t('modal.change.pwd.confirm.pwd.label')}>
            <PasswordReveal inputRef={newPwdConfRef}>
              <TextInput
                ref={newPwdConfRef}
                type="password"
                id="newPasswordconf"
                name="newPasswordconf"
                value={passwords.newPasswordConf}
                onBlur={(e) => updatePwdState('newPasswordConf', e.target.value, true)}
                onChange={(e) => updatePwdState('newPasswordConf', e.target.value, false)}
                placeholder={
                  <T size="16px" color="lightGrey">
                    {t('modal.change.pwd.new.pwd.label')}
                  </T>
                }
              />
            </PasswordReveal>
          </FormField>
          <PasswordValidator value={passwords.newPassword} valueAgain={passwords.newPasswordConf} />
          {requiresCode && (
            <SecurityCodeInput
              name="securityCode"
              id="securityCode"
              value={passwords.securityCode}
              onBlur={(val) => updatePwdState('securityCode', val, true)}
              onChange={(val) => updatePwdState('securityCode', val, false)}
            />
          )}
          {feedbackModalPwd?.status && (
            <Box
              style={{ minHeight: '50px' }}
              align="center"
              margin={{ top: 'small' }}
              animation={{
                type: 'fadeIn',
                duration: 300
              }}
            >
              <Text
                size="xsmall"
                textAlign="center"
                color={feedbackModalPwd.status === 'error' ? 'status-critical' : 'brand'}
              >
                {feedbackModalPwd.message}
              </Text>
            </Box>
          )}
          <Box justify="center" direction="row" gap="small" margin={{ top: 'medium' }} style={{ minHeight: '32px' }}>
            <Button
              disabled={cancelDisabled}
              label={t('common.button.cancel')}
              onClick={() => {
                setFeedbackModalPwd({});
                setModal(false);
              }}
            />
            <Button primary label={t('common.button.validate')} disabled={!passwordOk} onClick={handleUpdatePassword} />
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalResetPassword;
