import { useEffect, useState } from 'react';
import { getUser } from 'src/utility/api';
import { ascendantSort } from 'src/utility/sort';

export function usePopulatedEventRegistration(event, withCanceled, isAdmin = false) {
  const [populatedEventRegistration, setPopulatedEventRegistration] = useState([]);

  useEffect(() => {
    const { groupSessionEvent, appointments = [], attendees = [] } = event ?? {};
    let effectIsUp = true;
    if (isAdmin) {
      // ONLY VB admin is allowed to see event registration details (appointments or attendees)

      (async function () {
        if (groupSessionEvent) {
          if (attendees) {
            const userPromises = attendees.map(async (attendee) => {
              return getUser(attendee?.employee).then(({ user }) => {
                return {
                  ...user,
                  attendanceStatus: attendee?.status ?? '',
                  beneficiaryEquipped: attendee?.beneficiaryEquipped || false,
                  beneficiaryAttended: attendee?.beneficiaryAttended,
                  revenue: attendee?.revenue || 0
                };
              });
            });
            let users = [];
            const results = await Promise.allSettled(userPromises);
            results.forEach((res) => {
              const { status, value, reason } = res;
              if (status === 'fulfilled') {
                users.push(value);
              } else {
                console.error(`failed to retrieve employee, reason: ${reason}`);
              }
            });
            return users.sort((a, b) => ascendantSort(a.lastName, b.lastName));
          }
        } else {
          if (appointments) {
            let filteredEventAppointments = [...appointments].reduce((accuAppointments, appointment) => {
              // Remove appointment with employee === null (canceled)
              if (appointment.employee) {
                accuAppointments.push(appointment);
              }

              if (withCanceled) {
                // Add canceled appointment from history array
                const lastCanceledAppointment = appointment.history && appointment.history[0];
                if (lastCanceledAppointment) {
                  accuAppointments.push({
                    ...appointment,
                    employee: lastCanceledAppointment.employee,
                    status: lastCanceledAppointment.status,
                    isCanceled: true
                  });
                }
              }

              return accuAppointments;
            }, []);

            // Sort and add user object (populated)
            const populatedAppointmentPromises = filteredEventAppointments
              .sort((a, b) => ascendantSort(a.start, b.start))
              .map(async (appointment) => {
                return getUser(appointment.employee).then(({ user }) => {
                  return { ...appointment, populatedEmployee: user };
                });
              });
            return await Promise.all(populatedAppointmentPromises);
          }
        }
      })()
        .then((eventRegistration) => {
          if (effectIsUp) {
            // Only set state if effect is up
            setPopulatedEventRegistration(eventRegistration);
          }
        })
        .catch((err) => {
          console.error('usePopulatedEventRegistration.js/usePopulatedEventRegistration', err);
        });
    }

    return () => {
      effectIsUp = false;
    };
  }, [isAdmin, event, withCanceled]);
  return populatedEventRegistration;
}
