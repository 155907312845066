import React from 'react';
import { Box } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';

/**
 * renders a banner with icon on left side and horizontal rule line below
 * components children expected to contain a title rendering component in most cases
 * @param {object} props - component properties
 * @param {object} props.icon - icon to display
 * @param {object} props.children - children components
 * @returns {React.FC}
 */
const IconBanner = (props) => {
  return (
    <Box gap="small">
      <Box direction="row" gap="small" align="center">
        {props.icon}
        {props.children}
      </Box>
      <Box fill="horizontal" border={{ color: THEME_COLORS.greyBackground, size: '1px' }} />
    </Box>
  );
};

export default IconBanner;
