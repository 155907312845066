import { createSlice } from '@reduxjs/toolkit';
import { CompanyUsersCounter } from 'src/config';
import api from 'src/utility/api';
import { refreshAuthUser, replaceUserCompany, setToken, setUserSite, updateUserCompany } from '../auth/authSlice';
import { fetchUsers } from '../users/usersSlice';

export const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    list: [],
    currentCompany: {}
  },
  reducers: {
    updateCompanies: (state, action) => {
      state.list = action.payload;
    },
    updateCompany: (state, action) => {
      let { id, data } = action.payload;
      if (state?.currentCompany?._id === id) {
        state.currentCompany = { ...state.currentCompany, ...data };
      }
      state.list = state.list.map((c) => (c._id === id ? { ...c, ...data } : c));
    },
    changeCurrentCompany: (state, action) => {
      let id = action.payload;
      let company = state.list.find((c) => c._id === id);
      state.currentCompany = company;
    }
  }
});

export const { updateCompanies, changeCurrentCompany, updateCompany } = companiesSlice.actions;

export const fetchCompanies =
  (status, subscription = false, counter = CompanyUsersCounter.none, admin = false) =>
  async (dispatch) => {
    try {
      const params = new URLSearchParams('');
      let query = '/companies?';
      if (status) {
        params.set('status', status);
      }
      if (subscription) {
        params.set('subscription', '1');
      }
      if (counter !== CompanyUsersCounter.none) {
        params.set('counter', counter);
      }
      if (admin) {
        params.set('admin', '1');
      }
      console.log(
        `fetchCompanies:: Fetching companies with status: ${status} counters: ${counter} ${
          subscription ? ' - subscription data' : ''
        } ${admin ? ' - admin data' : ''}.`
      );
      const { companies } = await api.get(`${query}${params.toString()}`).then((res) => res.data);
      console.log(`fetchCompanies:: Fetched ${companies.length} companies with status: ${status}.`);
      dispatch(updateCompanies(companies));
      return companies;
    } catch (e) {
      console.error(`Error fetching companies : ${e.message}`);
    }
  };

/**
 * Fetch companies based on passed array of ids
 * @param {string[]} companiesArray array of company ids to fetch
 * @returns {object[]} company documents that match array of ids
 */
export const fetchCompaniesArray = (companiesArray) => async (dispatch) => {
  try {
    const { companies } = await api.post(`/companies`, { companiesArray }).then((res) => res.data);
    console.log(`companiesSlice::fetchCompaniesArray - Fetched ${companies.length} company(ies)`);
    dispatch(updateCompanies(companies));
    return companies;
  } catch (e) {
    console.error(`companiesSlice::fetchCompaniesArray - Error fetching companies : ${e.message}`);
  }
};

/**
 * Switches to selected company site (as admin)
 * @param {string} id - Id of company site to switch to
 * @param {boolean} [updateTokens=true] - if true update state with tokens provided by API
 * @returns {object} Object containing target company site definition and auth user token referring to this site
 */
export const switchToSite =
  (id, updateTokens = true) =>
  async (dispatch) => {
    try {
      let { company, token, refreshToken } = await api.get('/companies/site/' + id).then((res) => res.data);
      if (updateTokens) {
        dispatch(setToken(token, refreshToken));
      }
      dispatch(replaceUserCompany(company));
      dispatch(setUserSite(id));
      return { company, token };
    } catch (e) {
      console.error(`Error switching to company site Id: ${id} error: ${e.message}`);
    }
  };

/**
 * Queries the names of root/parent sites for given site
 * returns an object with root and/or parent properties depending on query mode
 * @param {string} id  Id of admin to make query for
 * @param {number} mode Query mode => 1 = parent only, 2 = root only, 3 = both
 * @returns {object} object with root/parent properties
 */
export const getSiteLinks = (id, mode) => async (dispatch) => {
  try {
    let { sites, status, error } = await api
      .get('/companies/site-links/' + id + '?mode=' + mode)
      .then((res) => res.data);
    return { status, sites, error };
  } catch (e) {
    console.error(`Error fetching site links for Site Id: ${id} error: ${e.message}`);
    return { status: 'error', error: e?.response?.data?.errors, sites: null };
  }
};

export const updateCompanyAsAdmin = (id, properties) => async (dispatch) => {
  try {
    const {
      data: { company }
    } = await api.put(`/companies/${id}/admin-update`, properties);
    dispatch(
      updateCompany({
        id,
        data: { ...company }
      })
    );
    return { status: 'success' };
  } catch (e) {
    const { response: { data: { message = '' } = {} } = {} } = e ?? {};
    console.error(`Error during company updateCompanyAsAdmin process: ${message || e?.message}`);
    return { status: 'error', message: message || e?.message };
  }
};

export const addCompanySite = (formData) => async (dispatch) => {
  try {
    const { company, user } = await api.post('/companies/add-company-site', formData).then((res) => res.data);
    dispatch(replaceUserCompany(company));
    if (user) {
      dispatch(fetchUsers());
    }
    dispatch(refreshAuthUser());
    return { status: 'success' };
  } catch (e) {
    console.error(`Error during company addCompanySite process: ${e.message}`);
    return { status: 'error', data: e?.response?.data?.errors };
  }
};

/**
 * Fetches all company sites for given company
 * if adminCount is true, also compute number of admins for each returned site
 * @param {string} id - company Id
 * @param {boolean} adminCount - if true requests computation of number of admins for each returned site
 * @returns {object[]} list of company sites
 */
export const getCompanySites =
  (id, adminCount = false) =>
  async () => {
    try {
      const { data } = await api.get(`/companies/get-company-sites/${id}${adminCount ? '?count=true' : ''}`);
      return { sites: data?.sites ?? [] };
    } catch (e) {
      console.error(`Exception during company getCompanySites processing: ${e.message}`);
      throw e;
    }
  };

export const refreshCompany = (id) => async (dispatch) => {
  try {
    const { company } = await api.get(`/companies/${id}`).then((res) => res.data);
    dispatch(updateCompany({ id, data: company }));
  } catch (e) {
    console.error(`Error during company refreshCompany process: ${e.message}`);
    return { status: 'error', data: e?.response?.data?.errors };
  }
};

/**
 * Updates the company event parameters default values
 * @param {string} id - company Id
 * @param {object} parameters - event parameters default values
 * @returns {object} response object with status/error properties
 */
export const updateCompanyEventParameters = (id, parameters) => async (dispatch) => {
  try {
    console.log(`Updating event default parameters for company: ${id}`);
    const { data } = await api.put(`/companies/${id}/event-parameters`, parameters);
    const { status, company } = data;
    console.log(
      `Update status: ${status} - company event parameters: ${JSON.stringify(company?.eventDefaultParameters ?? {}, null, ' ')}`
    );
    dispatch(updateUserCompany(company));
    return { status: 'success' };
  } catch (e) {
    console.error(`Error during company event parameters update process: ${e.message}`);
    return { status: 'error', error: e?.response?.data?.errors };
  }
};

/**
 * manages the company event tokens
 * 3 possible actions:
 * - add: adds new token with given expiry in months
 * - reset: frees tokens marked as used for an event in "pending" state
 * - delete: deletes unused tokens listed
 * @param {string} id - company Id
 * @param {object} tokenData - data for tokens to process
 * @param {'add'|'reset'|'delete'} action - action to process on tokens
 * @returns {object} response object with status/error properties
 */
export const manageCompanyEventTokens =
  (id, tokenData, action = 'add') =>
  async (dispatch) => {
    try {
      console.log(`Processing '${action}' action on event tokens for company: ${id}`);
      let response;
      const uri = `/companies/event-tokens/${id}`;
      switch (action) {
        case 'delete':
        case 'reset':
          response = await api.put(uri, { ...tokenData, action });
          break;
        case 'add':
          response = await api.post(uri, tokenData);
          break;
        default:
          return { status: 'error', error: 'invalid action' };
      }
      const { data } = response;
      const { status, company } = data;
      console.log(`'${action}' action request status: ${status}`);
      dispatch(updateCompany({ id, data: { ...company } }));
      return { status: 'success', company };
    } catch (e) {
      console.error(`Error during event tokens '${action}' action request processing: ${e.message}`);
      return { status: 'error', error: e?.response?.data?.errors };
    }
  };

export default companiesSlice.reducer;
