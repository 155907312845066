import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'grommet';
import { AvatarWithPlaceholder, EmailLink } from 'src/components';
import { getEventUserAvatarSrc, PractitionerName } from 'src/components/Card/cardData';
import PractitionerProfileModal from 'src/components/Modal/PractitionerProfileModal';
import SpeakerProfileModal from 'src/components/Modal/SpeakerProfileModal';
import { EVENT_STATUS } from 'src/constants';
import PractitionerReassignWarning from 'src/features/dashboard/partner/components/practitioner/PractitionerReassignWarning';
import { getEventPractitioner, getPractitionerTitleLabel } from 'src/utility/practitionerUtils';
import { UserRole } from '../../../../config';

const EventPractitioner = ({ event }) => {
  const { t } = useTranslation();
  const [showProfile, setShowProfile] = useState(false);
  const practitioner = getEventPractitioner(event);
  const { status } = event ?? {};
  const isReassignEvent = status === EVENT_STATUS.reassign;
  const { email = '', specialty = '', role } = practitioner;
  const isSpeaker = role === UserRole.speaker;

  return (
    <Box direction="row" gap="small" align="center" margin={{ horizontal: 'medium', top: 'small' }}>
      <Box
        style={{
          cursor: isReassignEvent ? 'default' : 'pointer'
        }}
        onClick={
          isReassignEvent
            ? () => {}
            : () => {
                setShowProfile((prevState) => !prevState);
              }
        }
        title={isReassignEvent ? '' : t('common.partner.display.profile')}
      >
        <AvatarWithPlaceholder avatarSrc={getEventUserAvatarSrc(event)} />
      </Box>
      <Box>
        <Box
          style={{
            cursor: isReassignEvent ? 'default' : 'pointer'
          }}
          onClick={
            isReassignEvent
              ? () => {}
              : () => {
                  setShowProfile((prevState) => !prevState);
                }
          }
          title={isReassignEvent ? '' : t('common.partner.display.profile')}
        >
          <PractitionerName event={event} displayOptions={{ showSubscriberNetwork: false }} />
        </Box>
        {isSpeaker ? (
          <SpeakerProfileModal speaker={practitioner} show={showProfile} setShow={setShowProfile} />
        ) : (
          <PractitionerProfileModal practitioner={practitioner} show={showProfile} setShow={setShowProfile} />
        )}
        {isReassignEvent ? (
          <PractitionerReassignWarning isSpeaker={isSpeaker} />
        ) : (
          <>
            <Text color="brand">{getPractitionerTitleLabel(isSpeaker ? event?.specialty : specialty)}</Text>
            <EmailLink
              email={email}
              mailToLabelId={isSpeaker ? 'send.email.to.speaker.label' : 'send.email.to.practitioner.label'}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default EventPractitioner;
