// @ts-check
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, ResponsiveContext, Text } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';
import { VIABEEZ_CONSENT_NOTICE_URL, VIABEEZ_COOKIES_URL, VIABEEZ_PRIVACY_URL } from 'src/constants';
import { getAppConfig } from 'src/utility/appConfig';

/**
 * Builds a simple legal link anchor with common legal link LnF
 * @param {Object} props - component props
 * @param {Object} props.url - link url
 * @param {Object} props.labelId - link label localization Id
 * @returns {React.ReactElement} Legal Link component
 */
const LegalLink = ({ url, labelId }) => {
  const { t } = useTranslation();
  return (
    <Anchor href={url} target="_blank" color={THEME_COLORS.title} size="10px">
      {t(labelId)}
    </Anchor>
  );
};

/**
 * Generates App legal links
 * @returns {React.ReactElement} App Legal Links component
 */
const AppLinks = () => {
  const { legalUrl } = getAppConfig();
  return (
    <>
      <LegalLink url={legalUrl} labelId="component.legalLink.cgu" />
      {' - '}
      <LegalLink url={VIABEEZ_CONSENT_NOTICE_URL} labelId="component.legalLink.consent" />
    </>
  );
};

/**
 * Generates Viabeez policies legal links
 * @returns {React.ReactElement} Viabeez policies Legal Links component
 */
const PolicyLinks = () => {
  const { portalUrl } = getAppConfig();
  return (
    <>
      <LegalLink url={`${portalUrl}${VIABEEZ_PRIVACY_URL}`} labelId="component.legalLink.privacy" />
      {' - '}
      <LegalLink url={`${portalUrl}${VIABEEZ_COOKIES_URL}`} labelId="component.legalLink.cookies" />
    </>
  );
};

/**
 * Component displaying a simple line with all GDPR links
 * CGU, consent, privacy & cookies policies
 * @returns {React.ReactElement} Legal Links component
 */
const LegalLinks = () => {
  const size = useContext(ResponsiveContext);
  return (
    <>
      {size === 'small' ? (
        <>
          <Text>
            <AppLinks />
          </Text>
          <Text>
            <PolicyLinks />
          </Text>
        </>
      ) : (
        <Text>
          <AppLinks />
          {' - '}
          <PolicyLinks />
        </Text>
      )}
    </>
  );
};

export default LegalLinks;
