import { t } from 'i18next';
import { capitalize, entries, isEmpty } from 'lodash';
import { displayUserGender } from '../components/Card/cardData';
import { AppointmentStatus, AttendeeStatus, DEFAULT_COMMISSION_PERCENTAGE, PRACTITIONER_ID_TYPE } from '../constants';
import {
  isEquipmentAndConsultationPriceEvent,
  SPECIALTY_APPOINTMENT_PRICING_FREE,
  SPECIALTY_APPOINTMENT_PRICING_VARIABLE
} from '../features/specialties/specialtiesUtils';
import { ADELI_REGEXP, RPPS_REGEXP } from '../utility/formValidation';

const NO_SPECIALTY_PRICE = -1; // no pricing defined for specialty

/**
 * Position Appointment Pricing record
 * @typedef {Object} AppointmentPricing
 * @property {number} price The price to apply
 * @property {string} [label] Additional label
 * @property {string} [tip] Additional tip
 */

/**
 * Get the label associated to practitioner type
 * @param {string} type - the practitioner type to get label for
 * @returns {string} The practitioner type label
 */
export const getPractitionerTypeLabel = (type) => {
  switch (type) {
    case 'default':
      return t('common.user.role.practitioner.default');
    case 'referent':
      return t('common.user.role.practitioner.referent');
    case 'provider':
      return t('common.user.role.practitioner.provider');
    default:
      return '';
  }
};

/**
 * Get the practitioner or event specialty label
 * @param {Object} specialty - specialty to get label for
 * @returns {string|''} The label of the specialty or '' if not found
 */
export const getSpecialtyLabel = (specialty) => {
  if (specialty) {
    return specialty?.label || '';
  }
  console.log('getSpecialtyLabel:: No specialty provided!');
  return '';
};

/**
 * Get the practitioner title for given specialty
 * @param {Object} specialty - specialty to get practitioner title for
 * @returns {string|''} The practitioner title for the specialty or '' if not found
 */
export const getPractitionerTitleLabel = (specialty) => {
  if (specialty) {
    return capitalize(specialty?.practitionerTitle) || '';
  }
  console.log('getPractitionerTitleLabel:: No specialty provided!');
  return '';
};

/**
 * Get the subscription pricing object for the provided position key
 * @param {Object} practitioner - practitioner to check
 * @returns {number} The subscription price
 */
export const getPractitionerSubscriptionPrice = (practitioner) => {
  if (practitioner?.specialty && practitioner.specialty?.subscriptionPricing) {
    return `${practitioner.specialty.subscriptionPricing}€`;
  }
  console.log('getPractitionerSubscriptionPrice:: practitioner has no specialty or subscription pricing!');
  return '?';
};

/**
 * Appointment status select option record
 * @typedef {Object} AppointmentStatusSelectOption
 * @property {string} key
 * @property {string} label
 */

/**
 * Object list of all data associated to an appointment status key
 */
const appointmentStatusData = {
  [AppointmentStatus.seen]: {
    id: 'appointment.status.seen.label'
  },
  [AppointmentStatus.excused]: {
    id: 'appointment.status.excused.label'
  },
  [AppointmentStatus.absent]: {
    id: 'appointment.status.absent.label'
  }
};
Object.freeze(appointmentStatusData);

export const getAppointmentStatusLabel = (t, appointmentStatus, shortLabel = false) => {
  if (t && typeof t === 'function') {
    if (!appointmentStatus || !appointmentStatusData[appointmentStatus]?.id) {
      console.log('practitionerUtils::getAppointmentStatusLabel - Missing appointment status');
      return '';
    }
    const id = appointmentStatusData[appointmentStatus].id;
    const localizationId = shortLabel ? 'short.' + id : id;
    return t(localizationId);
  }
  console.log('practitionerUtils::getAppointmentStatusLabel - Missing I18n translation function');
  return '';
};

export const getAppointmentStatusFormOptions = () => {
  return entries(appointmentStatusData).reduce((accu, [key, data]) => {
    if (data) {
      accu.push({ key: key, id: data.id });
    }
    return accu;
  }, []);
};

/**
 * Object list of all data associated to an attendee status key
 */
const attendeeStatusData = {
  [AttendeeStatus.seen]: {
    id: 'attendee.status.seen.label'
  },
  [AttendeeStatus.excused]: {
    id: 'attendee.status.excused.label'
  },
  [AttendeeStatus.absent]: {
    id: 'attendee.status.absent.label'
  }
};
Object.freeze(attendeeStatusData);

export const getAttendeeStatusLabel = (t, attendeeStatus, shortLabel = false) => {
  if (t && typeof t === 'function') {
    if (!attendeeStatus || !attendeeStatusData[attendeeStatus]?.id) {
      console.log('practitionerUtils::getAttendeeStatusLabel - Missing attendee status');
      return '';
    }
    const id = attendeeStatusData[attendeeStatus].id;
    const localizationId = shortLabel ? 'short.' + id : id;
    return t(localizationId);
  }
  console.log('practitionerUtils::getAttendeeStatusLabel - Missing I18n translation function');
  return '';
};

export const getAttendeeStatusFormOptions = () => {
  return entries(attendeeStatusData).reduce((accu, [key, data]) => {
    if (data) {
      accu.push({ key: key, id: data.id });
    }
    return accu;
  }, []);
};

/**
 * Checks if practitioner is supposed to have a practitioner Id (ADELI or RPPS)
 * true if practitioner specialty is regulated
 * @param {Object} practitioner - practitioner to check
 * @returns {boolean} true if practitioner expected to have a practitioner Id
 */
export const hasPractitionerId = (practitioner) => {
  if (practitioner?.specialty) {
    return practitioner.specialty?.regulated || false;
  }
  console.log('hasPractitionerId:: practitioner has no specialty! returning false...');
  return false;
};

/**
 * Checks if practitioner expected to have a practitioner Id (ADELI or RPPS)
 * has a valid practitionerId property ie:
 * - practitionerId prop exists and not empty
 * - type = ADELI or RPPS
 * - value defined and is valid ADELI or RPPS value
 * @param {Object} practitioner - practitioner to check
 * @returns {boolean} true if practitioner has valid practitioner Id prop
 */
export const isValidPractitionerId = (practitioner) => {
  const { practitionerId = {} } = practitioner ?? {};
  if (isEmpty(practitionerId)) {
    return false;
  }
  const { type, value } = practitionerId;
  if (type === PRACTITIONER_ID_TYPE.unset) {
    return false;
  }
  switch (type) {
    case PRACTITIONER_ID_TYPE.adeli:
      return !isNaN(value) && value.match(ADELI_REGEXP);
    case PRACTITIONER_ID_TYPE.rpps:
      return !isNaN(value) && value.match(RPPS_REGEXP);
    default:
      return false;
  }
};

/**
 * determines event practitioner name
 * able to return provider name if event was taken by a referent practitioner
 * @param {object} event - event to get practitioner name for
 * @returns {string|''} the practitioner formatted name including gender, firstName & lastName
 */
export const getPractitionerName = (event) => {
  const providerData = event?.provider;
  const practitionerData = event?.practitioner;
  if (providerData) {
    return `${displayUserGender(providerData.gender)} ${capitalize(providerData.firstName)} ${capitalize(
      providerData.lastName
    )}`;
  }
  if (practitionerData) {
    return `${displayUserGender(practitionerData.gender)} ${capitalize(practitionerData.firstName)} ${capitalize(
      practitionerData.lastName
    )}`;
  }
  return '';
};

/**
 * determines event practitioner email
 * able to return provider name if event was taken by a referent practitioner
 * @param {object} event - event to get practitioner name for
 * @returns {string|''} the practitioner email address if defined
 */
export const getEventPractitionerEmail = (event) => {
  const providerData = event?.provider;
  const practitionerData = event?.practitioner;
  if (providerData) {
    return providerData?.email || '';
  }
  if (practitionerData) {
    return practitionerData?.email || '';
  }
  return '';
};

/**
 * Get the default commission % for practitioner
 * @param {Object} practitioner - practitioner to check
 * @returns {number} The practitioner specialty default commission % or platform default %
 */
export function getPractitionerDefaultCommission(practitioner) {
  if (practitioner?.specialty) {
    return practitioner.specialty?.defaultCommissionPercentage ?? DEFAULT_COMMISSION_PERCENTAGE;
  }
  console.log('getPractitionerDefaultCommission:: practitioner has no specialty! returning default...');
  return DEFAULT_COMMISSION_PERCENTAGE;
}

/**
 * returns practitioner formatted commission % display value
 * returns user commission if set or default commission
 * default commission is either the practitioner position default commission if defined
 * or global commission default specified by constant DEFAULT_COMMISSION_PERCENTAGE
 * @param {Object} practitioner - the practitioner to get commission % from
 * @param {boolean} [returnDefault=false] - if true return default commission, otherwise not available text
 * @returns {string} the commission % in XX.YY% format or not available text
 */
export const getCommissionDisplay = (practitioner, returnDefault = false) => {
  const fmt = Intl.NumberFormat('fr-FR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 });
  if (practitioner?.commissionPercentage !== undefined) {
    const pct = practitioner.commissionPercentage / 100;
    return fmt.format(pct);
  }
  if (returnDefault) {
    return fmt.format(getPractitionerDefaultCommission(practitioner) / 100);
  }
  return t('common.not.defined.female.label');
};

/**
 * returns practitioner appointment price label (support delegate feature if event is provided)
 * returns more details for VB admin practitioner view (when called with isAdminView is true)
 * @param {Object} practitioner - the practitioner to get price label from
 * @param {boolean} [isAdminView=false] - true for detailed pricing info in VB admin view
 * @param {Object} event - if provided, check wether the event is delegated or not, ignored otherwise
 * @returns {string} the practitioner appointment price label
 */
export const getPriceDisplay = (practitioner, isAdminView = false, event = {}) => {
  const { specialty: practitionerSpecialty } = practitioner;
  const eventSpecialty = event?.specialty;
  const isDelegateEvent = eventSpecialty?.delegate;
  const specialty = isDelegateEvent ? eventSpecialty : practitionerSpecialty;

  if (typeof specialty === 'object') {
    const { appointmentPricing, customPrice, equipmentBasedPricing } = specialty;
    let equipmentLabel = '';
    if (isAdminView) {
      equipmentLabel = equipmentBasedPricing ? ` (${t('common.label.equipment.pricing.enabled')})` : '';
    }
    let priceLabel;
    switch (appointmentPricing) {
      case SPECIALTY_APPOINTMENT_PRICING_VARIABLE:
        priceLabel = t('common.specialty.appointment.pricing.variable');
        break;
      case SPECIALTY_APPOINTMENT_PRICING_FREE:
        priceLabel =
          isDelegateEvent && specialty?.delegationPrice !== 0
            ? `${specialty?.delegationPrice}€`
            : t('common.specialty.appointment.pricing.free');
        break;
      default:
        const type = customPrice ? t('common.label.editable') : t('common.specialty.appointment.pricing.fixed');
        const practitionerCustomPrice = hasCustomAppointmentPrice(practitioner);
        if (customPrice && isAdminView) {
          // more info if custom price in admin view
          const { maxCustomPrice: max, minCustomPrice: min, appointmentPricing: defVal } = specialty;
          if (practitionerCustomPrice) {
            priceLabel = `${type} : ${t('admin.vb.practitioner.custom.consultationPrice')} : ${
              practitioner?.consultationPrice
            }€`;
          } else {
            priceLabel = `${type} : ${defVal}€ [${min}€ - ${max}€] (${t('common.label.default')})`;
          }
        } else {
          const price = practitionerCustomPrice ? practitioner?.consultationPrice : appointmentPricing;
          priceLabel = isDelegateEvent ? `${eventSpecialty.delegationPrice}€` : `${price}€ (${type})`;
        }
    }
    return `${priceLabel}${equipmentLabel}`;
  }
  return t('common.not.defined.male.label');
};

/**
 * returns practitioner commission value
 * returns user commission if set or default commission
 * default commission is either the practitioner position default commission if defined
 * or global commission default specified by constant DEFAULT_COMMISSION_PERCENTAGE
 * @param {Object} practitioner - the practitioner to get commission % from
 * @param {boolean} [returnDefault=false] - if true return default commission, otherwise not available text
 * @returns {number|undefined} the commission value or undefined if no value and no default expected
 */
export const getCommissionValue = (practitioner, returnDefault = false) => {
  if (practitioner?.commissionPercentage !== undefined) {
    return practitioner.commissionPercentage;
  }
  if (returnDefault) {
    return getPractitionerDefaultCommission(practitioner);
  }
  return undefined;
};

/**
 * checks if event pricing model is based on appointment or equipment selling
 * @param {Object} event - event to check
 * @returns {boolean} true if equipment pricing model - false by default
 */
export const isEventWithEquipment = (event) => {
  if (!event?.specialty) {
    console.log('isEventWithEquipment:: no event provided or specialty missing!');
    return false;
  }
  return event.specialty?.equipmentBasedPricing || false;
};

export const getEventCommission = (event) => {
  if (event) {
    const { pricing } = event;
    if (pricing) {
      const { appointment } = pricing;
      return appointment ? appointment.commission : '';
    }
  }
  console.log('getEventCommission:: no event provided or pricing data missing/invalid!');
  return '';
};

const getBeneficiaryPricingFromEvent = (beneficiaryPrice, equipmentBasedPricing, fromEvent = false) => {
  let priceLabel, priceTip;
  switch (beneficiaryPrice) {
    case SPECIALTY_APPOINTMENT_PRICING_VARIABLE:
      priceLabel = t('appointment.variable.price.label');
      priceTip = t('appointment.pricing.health.insurance.tip');
      break;
    case SPECIALTY_APPOINTMENT_PRICING_FREE:
      priceTip = equipmentBasedPricing ? t('appointment.free.pricing.tip') : '';
      priceLabel = t('appointment.free.price.label');
      break;
    default:
      priceLabel = '';
      priceTip = t('appointment.pricing.health.insurance.tip');
  }
  return {
    price: beneficiaryPrice,
    label: priceLabel,
    tip: priceTip,
    eventPricing: fromEvent
  };
};

/**
 * Get the appointment pricing object for the provided event
 * In case of delegate events, this function returns prices for beneficiaries only, since another calculation is applied for practitioner
 * @param {Object} event - event to query
 * @returns {AppointmentPricing} The appointment pricing object
 */
export function getBeneficiaryEventAppointmentPricingData(event) {
  if (event) {
    const { pricing = null, specialty } = event;
    const { equipmentBasedPricing = false } = specialty;
    if (pricing) {
      // event has pricing data
      return getBeneficiaryPricingFromEvent(pricing.beneficiaryPrice, equipmentBasedPricing, true);
    }
  }
  console.log('getBeneficiaryEventAppointmentPricingData:: no event provided or pricing data missing/invalid!');
  return {};
}

/**
 * returns event appointment pricing from pricing data
 * if event specialty is a delegated specialty and delegation price set, returns delegation price
 * otherwise returns specialty appointment price
 * @param {Object} event - event to check
 * @returns {number|0} the appointment price to consider for this event
 */
export const getPractitionerEventAppointmentPricing = (event) => {
  if (event) {
    const { pricing } = event;
    // event has pricing info
    if (pricing) {
      // return the appointment price
      return pricing?.appointment?.price ?? 0;
    }
  }
  console.log('getPractitionerEventAppointmentPricing:: no event provided or pricing data missing/invalid!');
  return 0;
};

export const hasCustomAppointmentPrice = (practitioner) => {
  const specialty = practitioner?.specialty;
  if (specialty) {
    const { appointmentPricing, customPrice, minCustomPrice, maxCustomPrice } = specialty;
    if (customPrice) {
      const price = practitioner?.consultationPrice;
      if (price > 0 && price !== appointmentPricing) {
        return price >= minCustomPrice && price <= maxCustomPrice;
      }
    }
  }
  return false;
};

/**
 * Computes event appointment revenue
 * @param {Object} appointment - appointment to compute revenue for
 * @param {Object} event - event for which appointment was booked
 * @param {boolean} isCanceled - if true appointment was canceled
 * @returns {number|0} appointment revenue if computable or zero
 */
export const computeAppointmentRevenue = (appointment, event, isCanceled) => {
  if (!appointment) {
    console.log('computeAppointmentRevenue:: no appointment provided!');
    return 0;
  }
  if (!event) {
    console.log('computeAppointmentRevenue:: no event provided!');
    return 0;
  }

  const appointmentRevenue = appointment?.revenue || 0;
  const statusSeenOrUndefined = !appointment.status || appointment.status === AppointmentStatus.seen;

  if (!isCanceled && appointment?.employee) {
    if (appointmentRevenue && statusSeenOrUndefined && isEquipmentAndConsultationPriceEvent(event?.specialty)) {
      return appointmentRevenue;
    }
    const appointmentPrice = getPractitionerEventAppointmentPricing(event);
    if (appointmentPrice === NO_SPECIALTY_PRICE) {
      // no pricing defined for specialty
      return appointmentRevenue;
    }
    if (appointmentPrice > 0 && statusSeenOrUndefined) {
      // if appointment confirmed (seen) or no status yet, return appointment price
      return appointmentPrice;
    } else if (!appointmentPrice && appointmentRevenue && statusSeenOrUndefined) {
      // No appointment price but appointment revenue entered and status missing or seen
      // use appointment revenue
      return appointmentRevenue;
    }
  }
  // if appointment canceled or no employee set, consider as no revenue
  return 0;
};

/**
 * returns practitioner default specialty presentation text if defined for practitioner
 * @param {Object} practitioner - the practitioner user
 * @returns {string|''} the practitioner default specialty presentation text
 */
export const getPractitionerDefaultPresentation = (practitioner) => {
  if (!practitioner || !practitioner?.specialty) {
    console.log('getPractitionerDefaultPresentation:: no practitioner provided or specialty missing!');
    return '';
  }
  return practitioner.specialty?.profileDefaultText || '';
};

export const parseRevenue = (revenue) =>
  new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', useGrouping: false }).format(revenue);

/**
 * returns practitioner appointment pricing for his specialty
 * manages user custom price if any
 * can return -1 (free) or0 (variable) if configured
 * @param {Object} practitioner - practitioner to check
 * @returns {number|undefined} the practitioner appointment price for his specialty
 */
export const getPractitionerPriceForSpecialty = (practitioner) => {
  if (practitioner) {
    const { specialty } = practitioner;
    return practitioner?.consultationPrice
      ? practitioner.consultationPrice
      : specialty
        ? specialty?.appointmentPricing
        : undefined;
  }
  return undefined;
};

/**
 * checks if a practitioner has a valid state
 * valid means email validated and onboarding completed
 * ! nothing to do with approved (activated) state, a practitioner can be approved while not valid
 * returns object with current practitioner isEmailValid & onBoardingCompleted properties values
 * and validAccount boolean property that holds true if practitioner account is valid
 * @param {Object} practitioner - practitioner to check
 * @returns {object} - practitioner valid state object
 */
export const isValidPractitioner = (practitioner) => {
  const { isEmailValid, onBoardingCompleted = false } = practitioner ?? {};
  const details = { isEmailValid, onBoardingCompleted };
  return { ...details, validAccount: isEmailValid && onBoardingCompleted };
};

/**
 * returns practitioner that will actually manage the event
 * if defined, event practitioner is the provider, else the practitioner defined by practitioner property
 * @param {Object} event - event to query
 * @returns {Object|null} event practitioner or null
 */
export const getEventPractitioner = (event) => {
  if (event) {
    return event?.provider ?? event?.practitioner ?? null;
  }
  console.log('getEventPractitioner: No event provided');
  return null;
};
