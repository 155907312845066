import React from 'react';
import { UrlLink } from 'src/components';
import { getCompanyAccountCreationByCodeLink } from 'src/utility/companyUtils';

/**
 * displays the company beneficiary account creation link if company code
 * is enabled
 * includes:
 * - clickable link text
 * - copy to clipboard icon
 * @param {Object} props - component properties
 * @param {boolean} props.codeEnabled - true if company code is enabled
 * @param {string} [props.code=''] - company code value
 * @returns {React.FC}
 */
const CompanyCodeLink = ({ codeEnabled, code = '' }) => {
  const codeUrl = codeEnabled ? getCompanyAccountCreationByCodeLink(code) : '';
  return <>{codeEnabled && <UrlLink url={codeUrl} linkTitleId="beneficiary.account.creation.link" />}</>;
};

export default CompanyCodeLink;
