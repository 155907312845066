import React from 'react';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Calendar, Text } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';

/**
 * returns capitalized Month + year
 * @function
 * @param {string} month the month to capitalize
 * @param {string} locale  locale to apply to get month string version
 * @returns {string} Capitalized month string + year
 */
const capitalizeMonth = (month, locale) => {
  const m = month.toLocaleDateString(locale, {
    month: 'long',
    year: 'numeric'
  });
  return m.charAt(0).toUpperCase() + m.slice(1);
};

const Cal = (props) => {
  return (
    <Box
      align="center"
      justify="center"
      border={{ color: '#E8E8E8' }}
      round="medium"
      pad="medium"
      height="medium"
      {...props}
    >
      <Calendar
        disabled={props?.disabled ? props.disabled : null}
        fill
        locale="fr-FR"
        onSelect={(date) => props.setDate(date)}
        date={props.date}
        animate={false}
        header={({ date: currentDate, locale, onPreviousMonth, onNextMonth }) => (
          <Box direction="row" align="center" justify="between" margin={{ left: 'small', bottom: 'medium' }}>
            <Text size="medium">
              <strong>{capitalizeMonth(currentDate, locale)}</strong>
            </Text>
            <Box direction="row" align="center" gap="medium" margin={{ right: 'small' }}>
              <Button onClick={onPreviousMonth}>
                <Box>
                  <FontAwesomeIcon icon={faAngleLeft} color={THEME_COLORS.brand} size="lg" />
                </Box>
              </Button>
              <Button onClick={onNextMonth}>
                <Box>
                  <FontAwesomeIcon icon={faAngleRight} color={THEME_COLORS.brand} size="lg" />
                </Box>
              </Button>
            </Box>
          </Box>
        )}
        daysOfWeek={props?.daysOfWeek ? props.daysOfWeek : false}
        showAdjacentDays={props?.showAdjacentDays ? props.showAdjacentDays : false}
        firstDayOfWeek={props?.firstDayOfWeek ? props.firstDayOfWeek : null}
      />
    </Box>
  );
};

export default Cal;
