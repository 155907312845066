import React from 'react';
import { Layer, ThemeContext } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';

/**
 * @description Specific layer for Modal dialogs
 * adds overlay and important modal behavior properties
 * @Component
 * @property {Object} props - component properties
 * @property {Object} props.children - children component(s)
 * @property {function} [props.onEsc=void()] - callback when modal closed by Esc key
 * @property {function} [props.onClickOutside=void()] - callback when modal closed by click outside
 * @property {function} [props.borderRadius='4px'] - layer border radius
 * @property {Boolean} [props.full=false] - if true layer covers entire viewport
 * @returns {React.FC}
 */

const MODAL_OPACITY = 0.4;

const overlayBackgroundRgba = ((color) => {
  const hexNumbers = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  return `rgba(${parseInt(hexNumbers[1], 16)},${parseInt(hexNumbers[2], 16)},${parseInt(hexNumbers[3], 16)}, ${MODAL_OPACITY})`;
})(THEME_COLORS.lightBrand);

const ModalLayer = ({ children, onEsc = () => {}, onClickOutside = () => {}, borderRadius = '4px', full = false }) => {
  return (
    <ThemeContext.Extend
      value={{
        layer: {
          border: {
            radius: borderRadius
          },
          overlay: {
            background: overlayBackgroundRgba,
            backdropFilter: 'blur(1px)'
          },
          container: {
            elevation: 'medium'
          }
        }
      }}
    >
      <Layer position="center" modal={true} animation="slide" onClickOutside={onClickOutside} onEsc={onEsc} full={full}>
        {children}
      </Layer>
    </ThemeContext.Extend>
  );
};

export default ModalLayer;
