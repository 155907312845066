import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor, Box, Text } from 'grommet';
import { HelpLink } from 'src/components';
import { UserRole } from 'src/config';
import Layout, { FormHead } from './LayoutAuth';

const SignUpCompleted = ({ role }) => {
  const { t } = useTranslation();
  let titleId = 'page.signUp.completed.title';
  let descriptionId = 'page.signUp.completed.description';
  if (role === UserRole.speaker) {
    titleId = 'page.speaker.signUp.completed.title';
    descriptionId = 'page.speaker.signUp.completed.description';
  }
  return (
    <Layout>
      <FormHead
        title={t(titleId)}
        description={
          <>
            <Trans
              i18nKey={descriptionId}
              t={t}
              components={{ br: <br />, bold: <Text weight="bold" />, HomeLink: <Anchor size="15px" href="/signin" /> }}
            />
          </>
        }
      />
      <Box align="center">
        <Anchor as={Link} to="/signin" size="xsmall">
          {t('common.action.backToSignIn')}
        </Anchor>
      </Box>
      <HelpLink role={role} />
    </Layout>
  );
};

export default SignUpCompleted;
