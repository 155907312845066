import React from 'react';
import { Text } from 'grommet';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import { PRACTITIONER_ID_TYPE } from 'src/constants';

/**
 * renders the practitioner Id type & value
 * @param {Object} props - component properties
 * @param {object} props.practitionerId - practitioner Id object to display
 * @param {string} [props.textSize='small'] - text display size
 * @param {string|number} [props.labelWeight='normal'] - practitioner Id label text weight
 * @param {string} [props.labelColor='text'] - practitioner Id label text color
 * @param {string|number} [props.valueWeight='normal'] - practitioner Id value text weight
 * @param {string} [props.valueColor='text'] - practitioner Id value text color
 * @param {string} [props.unsetColor='text'] - text color if no value (unset)
 * @param {string} [props.unsetWeight=500] - text weight if no value (unset)
 * @param {boolean} [props.hideIfEmpty=false] - if true and NO practitioner Id value, render nothing
 * @returns {React.ReactFragment}
 */
const PractitionerIdDisplay = ({
  practitionerId,
  textSize = 'small',
  labelWeight = 'normal',
  labelColor = 'text',
  valueWeight = 'normal',
  valueColor = 'text',
  unsetColor = 'text',
  unsetWeight = 500,
  hideIfEmpty = false
}) => {
  const { type, value } = practitionerId;
  return (
    <>
      {type === PRACTITIONER_ID_TYPE.unset ? (
        <>
          {!hideIfEmpty && (
            <Text size={textSize} color={unsetColor} weight={unsetWeight}>
              {capitalize(t('common.not.defined.male.label'))}
            </Text>
          )}
        </>
      ) : (
        <Text size={textSize} color={labelColor} weight={labelWeight}>
          {`${t('common.label.number')} ${type === PRACTITIONER_ID_TYPE.adeli ? t('common.adeli.label') : t('common.rpps.label')} :`}
          <Text color={valueColor} weight={valueWeight} size={textSize}>
            &nbsp;{value}
          </Text>
        </Text>
      )}
    </>
  );
};

export default PractitionerIdDisplay;
