import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text } from 'grommet';
import { IconBanner } from 'src/components';
import { THEME_COLORS } from 'src/config/theme';

/**
 * Renders default page header
 * with icon and title and optional children components
 * header has a shadow line at bottom
 * @param {Object} props - component props
 * @param {Object} props.headerIcon - header icon when icon is a fontawesome icon reference
 * @param {Object} [props.headerIconComponent=null] - must be set when icon expected to be a component and not directly a fontawesome icon reference
 * @param {Object|string} [props.headerTitle=''] - header title as either string or render component
 * @param {Object} [props.containerStyle={}] - optional CSS for header container
 * @param {Object} [props.contentStyle={}] - optional CSS for header content
 * @param {Object} props.children - header children component(s)
 * @returns {React.FC}
 */
const PageHeader = ({
  headerMargin = 'medium',
  headerIcon,
  headerIconComponent = null,
  headerTitle = '',
  containerStyle = {},
  contentStyle = {},
  children
}) => {
  return (
    <Box margin={headerMargin} flex={false} style={containerStyle}>
      <IconBanner
        icon={
          headerIconComponent ? (
            headerIconComponent
          ) : (
            <FontAwesomeIcon icon={headerIcon} color={THEME_COLORS.title} size="lg" />
          )
        }
      >
        <Box fill="horizontal" direction="row" gap="small" align="center" style={contentStyle}>
          {typeof headerTitle === 'string' ? (
            <Text color="title" weight={700}>
              {headerTitle}
            </Text>
          ) : (
            <>{headerTitle}</>
          )}
          {children}
        </Box>
      </IconBanner>
    </Box>
  );
};

export default PageHeader;
