import React, { useContext, useMemo } from 'react';
import { Box, ResponsiveContext, Text } from 'grommet';
import { FileUploadKind } from 'src/config';
import AvatarWithPlaceholder from '../Card/AvatarWithPlaceholder';

/**
 * Generates title component to display in AppBar
 * @component
 * @returns {component} the dashboard title component
 */
const DashboardTitle = ({ company }) => {
  const companyAvatar = useMemo(
    () => company?.fileUploads?.find((fileUpload) => fileUpload.kind === FileUploadKind.avatar),
    [company]
  );
  const responsiveSize = useContext(ResponsiveContext);

  return (
    <Box direction="row" align="center">
      {responsiveSize !== 'small' && companyAvatar && (
        <AvatarWithPlaceholder
          avatarSrc={companyAvatar?.url}
          sizePx="35"
          isCompany
          avatarStyle={{ border: '1px solid' }}
          avatarMargin="10px"
        />
      )}
      <Text weight={800} color="brand" size={responsiveSize === 'small' ? 'medium' : '20px'}>
        {company?.name}
      </Text>
    </Box>
  );
};

export default DashboardTitle;
