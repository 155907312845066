import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Menu } from 'grommet';
import { t } from 'i18next';
import { T } from 'src/components';
import { THEME_COLORS } from 'src/config/theme';

export const LIST_FILTERS = {
  All: 'All',
  Validated: 'Validated', // isEmailValid true
  Pending: 'Pending', // isEmailValid false
  CodeCreated: 'CodeCreated', // beneficiaries created with company code
  CompanyCreated: 'CompanyCreated', // beneficiaries created by company admin
  Active: 'Active', // for practitioners (active account = validated + onboarding done)
  Inactive: 'Inactive', // for practitioners (not active)
  Standard: 'Standard', // for practitioners (not member of network)
  Network: 'Network', // for practitioners (member of network ie referent or provider)
  CompanyValidated: 'CompanyValidated', // company approved
  CompanyPending: 'CompanyPending', // company not approved yet
  specialtyEnabled: 'specialtyEnabled', // specialty enabled
  specialtyDisabled: 'specialtyDisabled', // specialty disabled
  groupSessionSpecialty: 'groupSessionSpecialty',
  consultationSpecialty: 'consultationSpecialty',
  specialtyGroupEnabled: 'specialtyGroupEnabled', // specialty group enabled
  specialtyGroupDisabled: 'specialtyGroupDisabled' // specialty group disabled
};

export const LIST_USER_FILTER = {
  All: 'All',
  Validated: 'Validated',
  Pending: 'Pending'
};

// build custom List filter menu for beneficiaries based on account creation mode
export const LIST_CODE_FILTER = {
  All: 'All',
  CodeCreated: 'CodeCreated',
  CompanyCreated: 'CompanyCreated'
};

// build custom List filter menu for practitioners active state
export const LIST_PRACTITIONERS_ACTIVE_FILTER = {
  All: 'All',
  Active: 'Active',
  Inactive: 'Inactive'
};

// build custom List filter menu for practitioners active state
export const LIST_SPEAKERS_ACTIVE_FILTER = {
  All: 'All',
  Active: 'Active',
  Inactive: 'Inactive'
};

// build custom List filter menu for practitioners standard vs network (referent & provider) state
export const LIST_PRACTITIONERS_NETWORK_FILTER = {
  All: 'All',
  Standard: 'Standard',
  Network: 'Network'
};

// build custom List filter menu for companies
export const LIST_COMPANIES_FILTER = {
  All: 'All',
  CompanyValidated: 'CompanyValidated',
  CompanyPending: 'CompanyPending'
};

// build custom List filter menu for specialties
export const LIST_SPECIALTIES_FILTER = {
  All: 'All',
  specialtyEnabled: 'specialtyEnabled',
  specialtyDisabled: 'specialtyDisabled',
  groupSessionSpecialty: 'groupSessionSpecialty',
  consultationSpecialty: 'consultationSpecialty'
};

// build custom List filter menu for specialty groups
export const LIST_SPECIALTY_GROUPS_FILTER = {
  All: 'All',
  specialtyGroupEnabled: 'specialtyGroupEnabled',
  specialtyGroupDisabled: 'specialtyGroupDisabled'
};

// All possible list filters labels
const LIST_FILTER_LABELS = {
  All: 'list.filter.menu.item.all', // Label for ALL filter
  Validated: 'list.filter.menu.item.validated',
  Pending: 'list.filter.menu.item.pending',
  CodeCreated: 'list.filter.menu.item.code.created',
  CompanyCreated: 'list.filter.menu.item.company.created',
  Active: 'list.filter.menu.item.active.account', // for practitioners (active account = validated + onboarding done)
  Inactive: 'list.filter.menu.item.inactive.account', // for practitioners (not active)
  Standard: 'list.filter.menu.item.standard.practitioner',
  Network: 'list.filter.menu.item.network.practitioner',
  CompanyValidated: 'list.filter.menu.item.admin.validated', // company admin email validated
  CompanyPending: 'list.filter.menu.item.admin.pending', // company admin email pending
  specialtyEnabled: 'list.filter.menu.item.specialty.enabled', // Enabled specialty
  specialtyDisabled: 'list.filter.menu.item.specialty.disabled', // Disabled specialty
  groupSessionSpecialty: 'list.filter.menu.item.specialty.group', // group session specialties
  consultationSpecialty: 'list.filter.menu.item.specialty.consultation', // consultation based specialties
  specialtyGroupEnabled: 'list.filter.menu.item.specialty.group.enabled', // Enabled specialty group
  specialtyGroupDisabled: 'list.filter.menu.item.specialty.group.disabled' // Disabled specialty group
};

const buildFilterMenu = (filtersList, filterState, userLabel, menuTextSize, changeFilter, itemGender) => {
  const buildMenuItem = (value, label, active) => {
    return {
      label: (
        <T size={menuTextSize} weight={active ? 700 : 'normal'}>
          {label}
        </T>
      ),
      onClick: () => {
        changeFilter(value);
      }
    };
  };
  const menu = [];
  const keys = Object.keys(filtersList);
  keys.forEach((key) => {
    if (key === LIST_FILTERS.All) {
      menu.push(
        buildMenuItem(
          LIST_FILTERS.All,
          `${t(LIST_FILTER_LABELS[key], { context: itemGender })} ${userLabel}`,
          filterState === LIST_FILTERS.All
        )
      );
    } else {
      menu.push(buildMenuItem(key, t(LIST_FILTER_LABELS[key]), filterState === key));
    }
  });
  return menu;
};

/**
 * Renders list filtering options menu component
 * @param {Object} props - component props
 * @param {string} props.filterState - current filter option
 * @param {function} props.changeFilter - callback function to update filter state
 * @param {string} props.userLabel - user label (singular) (admins, providers, beneficiaries, ...)
 * @param {string} [props.menuTextSize='14px'] - menu items labels text size
 * @param {Object} [props.filtersList=LIST_FILTER] - list of possible filter keys in menu
 * @param {'male'|'female'} [props.itemGender='male'] - determines list filter item gender (male or female)
 * @returns {Component} filter menu component
 */
const ListFilterSelect = ({
  filterState,
  changeFilter,
  userLabel,
  menuTextSize = '14px',
  filtersList = LIST_USER_FILTER,
  itemGender = 'male'
}) => {
  const { t } = useTranslation();
  const filterMenu = useMemo(() => {
    const menuList = buildFilterMenu(filtersList, filterState, userLabel, menuTextSize, changeFilter, itemGender);
    return menuList;
  }, [changeFilter, filtersList, filterState, menuTextSize, userLabel, itemGender]);

  return (
    <Box
      background="white"
      width={{ min: '42px' }}
      margin={{ left: '5px' }}
      justify="center"
      elevation="xsmall"
      height="42px"
      round="xsmall"
      align="center"
      title={t('list.filter.menu.tooltip')}
    >
      <Menu
        dropProps={{
          a11yTitle: t('list.filter.menu.label'),
          align: { top: 'bottom', right: 'right' },
          elevation: 'xlarge'
        }}
        label={t('list.filter.menu.label')}
        items={filterMenu}
      >
        <FontAwesomeIcon icon={faFilter} color={THEME_COLORS.brand} size="xl" width="42px" />
      </Menu>
    </Box>
  );
};

export default ListFilterSelect;
