import { Enum } from '../utility/enum';

/**
 * Enum for company event tokens search modes
 * @readonly
 * @enum {number}
 */
export const COMPANY_TOKEN_SEARCH_MODES = {
  expired: 0, // search for expired tokens
  tokenThreshold: 1 // search for companies having available tokens <= given threshold
};

// Numeric values for valid event state
export const EVENT_STATE = {
  PENDING: 0,
  SCHEDULED: 1,
  PAST: 2,
  REASSIGN: 3,
  NO_TOKEN: 4
};

// Valid event status
export const EVENT_STATUS = new Enum(
  { key: 'canceled', value: 'canceled' },
  { key: 'registered', value: 'registered' },
  { key: 'pending', value: 'pending' },
  { key: 'reassign', value: 'reassign' }
);

// Valid company subscription types
export const COMPANY_SUBSCRIPTION_TYPES = {
  DEFAULT: 0, // 2€/month/employee
  STARTUP: 1, // 300€/month for startups only
  PER_EVENT: 2, // 1000€/event scheduled
  CUSTOM: 3 // company is applied a custom price
};
// defines User friendly labels for all supported subscription types (must be ordered the same way)
export const COMPANY_SUBSCRIPTION_LABELS = [
  'Standard (forfait/salarié/mois)',
  'Forfait moins de 100 collaborateurs',
  'Tarification à la journée de santé',
  'Tarification personnalisée'
];

export const COMPANY_SIZE = {
  LESS_100: 0, //  less than 100 employees
  LESS_200: 1, // [100-200] employees
  LESS_500: 2, // [200-500] employees
  LESS_2000: 3, // [500-2000] employees
  LESS_5000: 4, // [2000-5000] employees
  LESS_10000: 5, // [5000-10000] employees
  LARGE: 6 // 10000+ employees
};

// defines company pricing models price in euros
export const COMPANY_PRICING = {
  STANDARD: 2, // 2€/employee/month
  STARTUP: 300, // 300€/month
  EVENT: 1000, // 1000€/event/month
  SANTE_MENTALE: 6 // 6€/month in addition to pricing model
};

// defines user gender
export const USER_GENDER = { FEMALE: 1, MALE: 2, OTHER: 3 };

const USER_GENDER_LABEL = {
  [USER_GENDER.MALE]: 'M.',
  [USER_GENDER.FEMALE]: 'Mme',
  [USER_GENDER.OTHER]: 'Autre'
};

const USER_GENDER_LABEL_ALTERNATIVE = {
  [USER_GENDER.MALE]: 'H',
  [USER_GENDER.FEMALE]: 'F',
  [USER_GENDER.OTHER]: 'A'
};

export const getUserGender = (userGender, alternative = false) => {
  if (alternative) {
    return USER_GENDER_LABEL_ALTERNATIVE[userGender] || 'n/a';
  }
  return USER_GENDER_LABEL[userGender] || '';
};

export const getUserGenderSelectOptions = () => {
  return Object.values(USER_GENDER).map((key) => {
    return { key, label: getUserGender(key), keyString: `${key}` };
  });
};

// defines max radius for practitioner catchment area around his location
export const PRO_MAX_CATCHMENT_RADIUS = 100;

// defines min radius for practitioner catchment area around his location
export const PRO_MIN_CATCHMENT_RADIUS = 10;

// Minimum delay in minutes before an booking slot becomes unavailable if booking done the same day as event start date
export const MIN_DELAY_FOR_BOOKING = 15;

// urls to Viabeez terms of use & user consent documentation files
export const VIABEEZ_TERMS_URL = '/CGU-VIABEEZ.pdf';
export const VIABEEZ_CONSENT_NOTICE_URL = '/VIABEEZ-CONSENT.pdf';

// urls to Viabeez privacy & cookies policies
export const VIABEEZ_PRIVACY_URL = '/politique-de-confidentialite';
export const VIABEEZ_COOKIES_URL = '/politique-de-cookies';

// Viabeez help email
export const VIABEEZ_HELP_EMAIL = 'help@viabeez.com';

// User registration server errors
export const EMAIL_ALREADY_USED = 'email_already_used';

export const ERROR_CODES = {
  USER_HAS_ONGOING_EVENTS: "L'intervenant ne peut pas être supprimé. Il est inscrit à une/des journée(s) de santé."
};

// catchment area value that means unlimited catchment area
export const UNLIMITED_CATCHMENT_VALUE = -1;

export const DEFAULT_CATCHMENT_VALUE = 50;

// default commission percentage rate for practitioners (can be float value)
export const DEFAULT_COMMISSION_PERCENTAGE = 10.0;

// group session specialty attendees default min/max count values
// !!!!! IMPORTANT UI for min/max attendees planned for count <= 100 (regexp)
// !!!!! if MAX_GROUP_SESSION_ATTENDEES_COUNT set to higher value MaskInput regexp to update as well
export const MIN_GROUP_SESSION_ATTENDEES_COUNT = 5;
export const MAX_GROUP_SESSION_ATTENDEES_COUNT = 100;
// group session specialty duration default min/max values
// associated MaskInput regexp planned for values in range [10-995]
// !!! make sure duration min/max values are multiple of 5
export const MIN_GROUP_SESSION_DURATION = 30; // 30 minutes
export const MAX_GROUP_SESSION_DURATION = 600; // 10 hours

// practitioner custom price min value
export const PRACTITIONER_PRICE_MIN_VAL = 10;
export const PRACTITIONER_PRICE_MAX_VAL = 100;

// practitioner title max length
export const PRACTITIONER_TITLE_MAX_LENGTH = 100;

// practitioner presentation text max length
export const PRACTITIONER_PRESENTATION_MAX_LENGTH = 600;

// company info for practitioner max length
export const PRACTITIONER_COMPANY_INFO_MAX_LENGTH = 300;

// practitioner info for employees max length
export const EMPLOYEE_PRACTITIONER_INFO_MAX_LENGTH = 300;

// practitioner beneficiary message max length
export const PRACTITIONER_BENEFICIARY_MESSAGE_MAX_LENGTH = 5000;

// max delay in days for beneficiary past events presence confirmation prompt
export const MAX_PAST_EVENTS_START_DATE_DELAY_CONFIRMATION_PROMPT = 31;

export const VALIDATION_STATUS = {
  activated: 'activated',
  pending: 'pending',
  refused: 'refused',
  unsubscribed: 'unsubscribed'
};

// Group session event attendance status
/**
 * Enum of attendance statuses (group session event)
 */
export const AttendeeStatus = {
  seen: 'seen',
  excused: 'excused',
  absent: 'absent'
};
Object.freeze(AttendeeStatus);

/**
 * Enum of appointment statuses
 */
export const AppointmentStatus = {
  seen: 'seen',
  excused: 'excused',
  absent: 'absent'
};
Object.freeze(AppointmentStatus);

// Event practitioner registration modes
// until set by admin, value is 'pending'.
// 'auto' means email automatically sent to all eligible practitioners
// otherwise ('managed') Admin manages practitioner registration
export const REGISTER_MODE_STATUS = {
  pending: 'pending',
  managed: 'managed',
  auto: 'auto'
};

// specialty label max length
export const SPECIALTY_LABEL_MAX_LENGTH = 100;

// specialty presentation text max length (target = practitioner profile)
export const SPECIALTY_PRESENTATION_MAX_LENGTH = 600;

// specialty description text max length (target = company admin)
export const SPECIALTY_DESCRIPTION_TEXT_MAX_LENGTH = 600;

// specialty room requirements description text max length (target = company admin)
export const SPECIALTY_ROOM_REQUIREMENTS_TEXT_MAX_LENGTH = 300;

// specialty email text max length (target = beneficiaries)
export const SPECIALTY_EMAIL_TEXT_MAX_LENGTH = 600;

// specialty group label max length
export const SPECIALTY_GROUP_LABEL_MAX_LENGTH = 100;

// specialty group description max length
export const SPECIALTY_GROUP_DESCRIPTION_MAX_LENGTH = 300;

// speaker user organization description text max length
export const SPEAKER_ORGANIZATION_DESCRIPTION_MAX_LENGTH = 600;

// supported practitioner professional Id types
export const PRACTITIONER_ID_TYPE = {
  adeli: 'ADELI',
  rpps: 'RPPS',
  unset: '' // Id required but type/value not defined yet
};

// Define event Min & Max duration in minutes
export const EVENT_MIN_DURATION_HOURS = 240;
export const EVENT_MAX_DURATION_HOURS = 600;

// Define number of event tokens that triggers low event tokens warning
export const LOW_EVENT_TOKENS_THRESHOLD = 2;
