import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';

/**
 * Displays the event waiting list size if any
 * @param {number} [size=0] - the waiting list size
 * @param {string} [textSize='small'] - the font size to use
 * @param {string} [textColor=THEME_COLORS.text] - the font color to use
 * @param {number} [textWeight=500] - the text weight to apply
 * @returns {ReactFragment} - Component displaying the event waiting list size if any
 */
const EventWaitingListInfo = ({ size, textSize = 'small', textColor = THEME_COLORS.text, textWeight = 500 }) => {
  const { t } = useTranslation();
  return (
    <>
      {size > 0 && (
        <Text textAlign="center" color={textColor} weight={textWeight} size={textSize}>
          {t('admin.vb.events.planning.queued.user', { userInQueue: size })}
        </Text>
      )}
    </>
  );
};

export default EventWaitingListInfo;
