import React from 'react';
import { useTranslation } from 'react-i18next';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Anchor, Box, Text } from 'grommet';
import { isEmpty } from 'lodash';
import { ClipboardCopy } from 'src/components';
import { THEME_COLORS } from 'src/config/theme';

/**
 * renders a url as a clickable link that opens in new browser window
 * if requested can also contain icon to copy link text into clipboard
 * @param {Object} props - component properties
 * @param {string} props.url - url to process
 * @param {string} [props.linkTitleId=''] - link title localization Id
 * @param {string} [props.linkColor = THEME_COLORS.brand] - link text color (default is brand color)
 * @param {string} [props.linkTextSize = 'xsmall'] - link text size (default is xsmall)
 * @param {object} [props.clipboardData = see below] - if not empty object, adds copy to clipboard icon
 * clipboardData object when not empty requires 2 properties:
 *  iconTipId: default = 'copy.link.to.clipboard' => localization Id for link tip text
 *  copyTipId: default = 'link.copied.to.clipboard' => localization Id for link copied to clipboard confirmation text
 * @returns {React.Component}
 */
const UrlLink = ({
  url,
  linkTitleId = '',
  linkTextSize = 'xsmall',
  linkColor = THEME_COLORS.brand,
  clipboardData = { iconTipId: 'copy.link.to.clipboard', copyTipId: 'link.copied.to.clipboard' }
}) => {
  const { t } = useTranslation();
  return (
    <Box align="center" alignSelf="start" direction="row" gap="small">
      <FontAwesomeIcon icon={faLink} color={THEME_COLORS.brand} size="xs" />
      <Anchor href={url} target="_blank">
        <Text size={linkTextSize} title={linkTitleId ? t(linkTitleId) : ''} color={linkColor}>
          {url}
        </Text>
      </Anchor>
      {!isEmpty(clipboardData) && (
        <ClipboardCopy buffer={url} iconTipId={clipboardData?.iconTipId} copyTipId={clipboardData?.copyTipId} />
      )}
    </Box>
  );
};

export default UrlLink;
