import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ResponsiveContext, Text } from 'grommet';
import {
  isGroupSessionEventSpecialty,
  isLiveEventSpecialty,
  isPhoneSpecialty,
  isRemoteSpecialty,
  isVideoSpecialty
} from '../../features/specialties/specialtiesUtils';

/**
 * renders a text tag component
 * @param {object} props - component properties
 * @param {string} props.label - tag text
 * @param {string} [props.width='90px'] - tag width
 * @returns {React.ReactFragment} Tag component
 */
const EventPropertyTag = ({ label, width = '90px' }) => {
  const size = useContext(ResponsiveContext);
  return (
    <Box
      round="small"
      pad={{ horizontal: 'small' }}
      margin={{ horizontal: '5px', vertical: 'xsmall' }}
      border={{ color: 'brand' }}
      align="center"
      width={width}
    >
      <Text textAlign="center" size={size === 'small' ? 'xsmall' : 'small'} weight={600} color="brand">
        {label}
      </Text>
    </Box>
  );
};

/**
 * renders all event tag components depending on event specialty consultation type flags
 * also manages event specialty teleprocessing flag : event specialty requires teleprocessing
 * @param {object} props - component properties
 * @param {string} props.event - event to process
 * @param {string} [props.margin={ horizontal: 'small', top: 'small'}] - component margin
 * @returns {React.ReactFragment} Event tags component
 */
const EventTags = ({ event, margin = { horizontal: 'small', top: 'small' } }) => {
  const { t } = useTranslation();
  const { specialty = {} } = event ?? {};
  const { teleprocessing = false } = specialty;
  return (
    <Box direction="row" gap="none" align="center" margin={margin} wrap>
      {isRemoteSpecialty(specialty) && (
        <EventPropertyTag label={t('beneficiary.event.consultation.type.remote')} width="130px" />
      )}
      {isLiveEventSpecialty(specialty) && <EventPropertyTag label={t('beneficiary.event.consultation.type.face')} />}
      {isGroupSessionEventSpecialty(specialty) ? (
        <EventPropertyTag label={t('beneficiary.event.group.session')} width="150px" />
      ) : (
        <EventPropertyTag label={t('beneficiary.event.appointment')} width="185px" />
      )}
      {isVideoSpecialty(specialty) && (
        <EventPropertyTag label={t('common.specialty.consultationType.video')} width="115px" />
      )}
      {isPhoneSpecialty(specialty) && (
        <EventPropertyTag label={t('beneficiary.event.consultation.type.phone')} width="200px" />
      )}
      {teleprocessing && <EventPropertyTag label={t('common.label.teleprocessing.appointment')} width="120px" />}
    </Box>
  );
};

export default EventTags;
