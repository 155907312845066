import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { faDeleteRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'grommet';
import { SearchInput } from 'src/components';
import { THEME_COLORS } from 'src/config/theme';

/**
 * Renders a list search box
 * @param {Object} props - component props
 * @param {string} props.filter - current value of search box field
 * @param {function} props.onFilterChange - Id of localization string for user label (singular)
 * @param {string} props.placeHolderStringId - search box input field place holder
 * @param {string} props.width - search box width
 * @returns {Component} search box component
 */
const UserSearch = ({ filter, onFilterChange, placeHolderStringId, width }) => {
  const { t } = useTranslation();
  const handleSetFilter = useCallback(
    (event) => {
      onFilterChange(event.target.value);
    },
    [onFilterChange]
  );

  const handleReset = useCallback(() => {
    onFilterChange('');
  }, [onFilterChange]);

  return (
    <Box flex={false} direction="row" justify="between" elevation="xsmall" round="6px">
      <Box direction="row" gap="medium">
        <Box width={width} direction="row" background="white" align="center" pad={{ left: 'small' }} round="6px">
          <FontAwesomeIcon
            icon={faDeleteRight}
            color={THEME_COLORS.brand}
            size="sm"
            style={{ opacity: filter ? '1' : '0.5' }}
            onClick={handleReset}
          />
          <SearchInput
            icon={<FontAwesomeIcon icon={faMagnifyingGlass} color={THEME_COLORS.brand} />}
            reverse
            placeholder={t(placeHolderStringId)}
            value={filter}
            onChange={handleSetFilter}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserSearch;
