import React from 'react';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';
import styled from 'styled-components';

const ShadowBox = styled(Box)`
  // background: ${(props) => props.background || '#ffffff'};
  box-shadow: 0px 6px 20px rgba(210, 210, 210, 0.08);
  border-radius: 8px;
`;

const Card = (props) => (
  <ShadowBox round="8px" background={props.background || 'white'} {...props}>
    {props.children}
  </ShadowBox>
);

const CardElement = ({ title, content }) => (
  <Box align="start" border={{ color: '#EEEEEE', size: '1px', side: 'bottom' }} pad={{ bottom: 'xsmall' }}>
    <Text size="small" color="title">
      {title}
    </Text>
    {typeof content === 'string' ? <Text size="small">{content}</Text> : <>{content}</>}
  </Box>
);

const MutableCardContent = ({ content, contentTip, onEdit, disabled = false }) => (
  <Box direction="row" gap="small" align="center">
    <>{typeof content === 'string' ? <Text size="small">{content}</Text> : <>{content}</>}</>
    <FontAwesomeIcon
      title={contentTip}
      icon={faPen}
      color={THEME_COLORS.brand}
      size="sm"
      style={disabled ? { opacity: 0.3 } : { cursor: 'pointer' }}
      onClick={disabled ? null : () => onEdit(true)}
    />
  </Box>
);

export { CardElement, MutableCardContent };
export default Card;
