import styled from 'styled-components';

const BulletPoint = styled.div`
  border-radius: 50%;
  height: ${(props) => props.$size || '7px'};
  width: ${(props) => props.$size || '7px'};
  background: ${(props) => (props.$borderOnly ? 'transparent' : props.$pointColor)};
  border: ${(props) => (props.$borderOnly ? `2px solid ${props.$pointColor}` : 'none')};
  flex-shrink: 0;
`;

export default BulletPoint;
