import React from 'react';
import { faStarSharp as faStarSharpEmpty } from '@fortawesome/pro-regular-svg-icons';
import { faSquareFull, faStarSharp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';
import { getPercentage } from 'src/utility/getPercentage';

const StarRating = ({ note, starCount, color, size }) => {
  return (
    <Box style={{ position: 'relative', zIndex: 1, width: 'fit-content' }}>
      <Box
        style={{ background: color, position: 'absolute', width: `${getPercentage(note, starCount)}%` }}
        fill="vertical"
      />
      <Box direction="row" align="center" style={{ zIndex: 900 }}>
        {[...Array(starCount)].map((_, index) => (
          <FontAwesomeIcon
            key={`starFull:${index}`}
            icon={faStarSharp}
            color={THEME_COLORS.white}
            mask={faSquareFull}
            size={size || '1x'}
            viewBox="0 0 512 512"
          />
        ))}
      </Box>
      <Box direction="row" align="center" style={{ position: 'absolute', zIndex: 1000 }}>
        {[...Array(starCount)].map((_, index) => (
          <FontAwesomeIcon
            key={`starEmpty:${index}`}
            icon={faStarSharpEmpty}
            color={color}
            size={size || '1x'}
            viewBox="0 0 512 512"
          />
        ))}
      </Box>
    </Box>
  );
};

export default StarRating;
