import { createSlice } from '@reduxjs/toolkit';

// menu index needed to navigate all admin views
export const menuIndex = {
  calendar: 0,
  events: 1,
  tokens: 2,
  companies: 3,
  practitioners: 4,
  speakers: 5,
  specialties: 6,
  newAccount: 7,
  userSearch: 8,
  statistics: 9
};

// views path containing menu and submenu index needed to navigate through all admin views
export const views = {
  calendar: { menu: menuIndex.calendar, submenu: 0 },
  events: {
    pendingEvents: { menu: menuIndex.events, submenu: 0 },
    canceledEvents: { menu: menuIndex.events, submenu: 1 },
    reassignEvents: { menu: menuIndex.events, submenu: 2 },
    details: { menu: menuIndex.events, submenu: 3 }
  },
  tokens: { menu: menuIndex.tokens, submenu: 0 },
  practitioners: {
    list: { menu: menuIndex.practitioners, submenu: 0 },
    detail: { menu: menuIndex.practitioners, submenu: 1 },
    import: { menu: menuIndex.practitioners, submenu: 2 }
  },
  companies: { list: { menu: menuIndex.companies, submenu: 0 }, detail: { menu: menuIndex.companies, submenu: 1 } },
  speakers: { list: { menu: menuIndex.speakers, submenu: 0 }, detail: { menu: menuIndex.speakers, submenu: 1 } },
  specialties: {
    management: { menu: menuIndex.specialties, submenu: 0 },
    add: { menu: menuIndex.specialties, submenu: 1 },
    detail: { menu: menuIndex.specialties, submenu: 2 },
    groupDetail: { menu: menuIndex.specialties, submenu: 3 },
    groupAdd: { menu: menuIndex.specialties, submenu: 4 }
  },
  newAccount: { menu: menuIndex.newAccount, submenu: 0 },
  userSearch: {
    form: { menu: menuIndex.userSearch, submenu: 0 },
    user: { menu: menuIndex.userSearch, submenu: 1 },
    practitioner: { menu: menuIndex.userSearch, submenu: 2 },
    speaker: { menu: menuIndex.userSearch, submenu: 3 }
  },
  statistics: {
    menu: menuIndex.statistics,
    submenu: 0
  }
};

export const adminDashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState: {
    view: { menu: 0, submenu: 0, tabPractitioners: 0, tabCompanies: 0, tabSpecialties: 0, tabSpeakers: 0 },
    selectedId: '' // Id of selected item - needed for some views, '' means Not set
  },
  reducers: {
    changeView: (state, action) => {
      state.view = { ...state.view, ...action.payload };
    },
    changeId: (state, action) => {
      state.selectedId = action.payload;
    }
  }
});

export const { changeView, changeId } = adminDashboardSlice.actions;

export default adminDashboardSlice.reducer;
