import React from 'react';
import { Box, Grid, Text } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';

const MainMenuItem = ({ activated, icon, children, onClick, textSize = 'medium' }) => {
  const areas = [['icon', 'label']];
  const columns = ['35px', 'auto'];
  const rows = ['auto'];
  return (
    <Box
      gap="small"
      focusIndicator
      onClick={onClick}
      background={activated ? 'brand' : 'white'}
      round="4px"
      pad="small"
      style={{ minHeight: '48px' }}
    >
      <Grid align="center" areas={areas} columns={columns} rows={rows} width="100%" pad={{ right: 'small' }}>
        <Box gridArea="icon">{icon}</Box>
        <Box gridArea="label" pad={{ left: 'small' }}>
          <Text color={activated ? THEME_COLORS.white : THEME_COLORS.title} size={textSize}>
            {children}
          </Text>
        </Box>
      </Grid>
    </Box>
  );
};

export default MainMenuItem;
