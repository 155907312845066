import React, { useContext, useState } from 'react';
import { Box, ResponsiveContext, Text } from 'grommet';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { theme } from 'src/config/theme';

/**
 * Generic slider component
 * @param {Object} props - component properties
 * @param {number} props.min - slider min value
 * @param {number} props.max - slider max value
 * @param {string} [props.unit=''] - slider value unit label, default = no label
 * @param {number} props.value - slider current value
 * @param {string} props.sliderWidth - slider component width
 * @param {number|null} [props.step=null] - slider increase/decrease step value, if null use default
 * @param {Object} [props.marks={}] - slider marks (scale display), if empty object no marks
 * @param {boolean} [props.showValue = false] - if true display current value next to slider
 * @param {function} props.onChange - callback called when value changes
 * @param {function} props.onChangeComplete - callback called on mouseup event after value changed
 * @returns {React.FC} slider component
 */
const Slider = ({
  min,
  max,
  onChange,
  onChangeComplete,
  unit = '',
  value,
  sliderWidth,
  step = null,
  marks = {},
  showValue = false,
  ...otherProps
}) => {
  const responsiveSize = useContext(ResponsiveContext);
  const [sliderValue, setSliderValue] = useState(value ? value : parseInt(min));

  return (
    <Box direction="row" gap="medium" align="center">
      <Box fill direction="row" pad={{ bottom: 'small' }} style={{ position: 'relative', width: sliderWidth }}>
        <RcSlider
          value={value}
          min={parseInt(min)}
          max={parseInt(max)}
          step={step}
          defaultValue={value ? value : parseInt(min)}
          onChange={(val) => {
            setSliderValue(val);
            onChange(val);
          }}
          onChangeComplete={(val) => {
            if (onChangeComplete) {
              onChangeComplete(val);
            }
          }}
          marks={marks}
          dotStyle={{ borderColor: theme.global.colors.brand }}
          activeDotStyle={{ borderColor: theme.global.colors.brand }}
          styles={{
            rail: { backgroundColor: theme.global.colors.lightBrand, height: 3 },
            track: { backgroundColor: theme.global.colors.lightBrand, height: 3 },
            handle: {
              borderColor: theme.global.colors.brand,
              height: 20,
              width: 20,
              marginLeft: 0,
              marginTop: -9,
              backgroundColor: theme.global.colors.lightBrand,
              opacity: 1
            }
          }}
          {...otherProps}
        />
      </Box>
      {unit && responsiveSize !== 'small' && (
        <Box>
          <Text size="15px" color="text">
            {unit}
          </Text>
        </Box>
      )}
      {showValue && (
        <Box
          pad={{ horizontal: '8px' }}
          border={{
            color: 'brand',
            size: '1px',
            style: 'solid'
          }}
          round="5px"
        >
          <Text size="medium" weight={700} color="brand">
            {sliderValue}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default Slider;
