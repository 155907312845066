import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { capitalize } from 'lodash';
import { pdfTableColumnsWidth } from 'src/utility/table';

const borderColor = '#000';
const errorBgColor = '#fbdddd';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 55,
    textAlign: 'center',
    flexGrow: 1,
    fontSize: 10
  },
  cellBorder: { borderRightColor: borderColor, borderWidth: 1 },
  cell: {
    height: '100%',
    padding: '10px 5px',
    width: '15%',
    flexGrow: 1
  }
});

const TableRow = ({ data, groupSessionEvent, eventHasRevenueInfo }) => {
  const columns = pdfTableColumnsWidth(groupSessionEvent);
  return (
    <View
      style={{ ...styles.container, ...(!data.confirmationMatch && { backgroundColor: errorBgColor }) }}
      wrap={false}
    >
      {!groupSessionEvent && (
        <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.slot }}>{data.time}</Text>
      )}
      <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.nameWidth }}>
        {data.lastName.toUpperCase()}
      </Text>
      <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.nameWidth }}>
        {capitalize(data.firstName)}
      </Text>
      <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.status }}>{data.presenceStatus}</Text>
      <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.confirmation }}>
        {data.beneficiaryConfirmation}
      </Text>
      {eventHasRevenueInfo && (
        <Text style={{ ...styles.cell, ...styles.cellBorder, width: columns.revenue }}>{data.revenue}</Text>
      )}
      <Text style={{ ...styles.cell, ...styles.cellBorder }}>{data.validity}</Text>
    </View>
  );
};

export default TableRow;
