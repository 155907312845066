import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Text } from 'grommet';
import { ToolTip } from 'src/components';
import { THEME_COLORS } from 'src/config/theme';

/**
 * Renders a button instance that is used at top of dashboard page
 * @property {Object} props - component props
 * @property {string} props.buttonLabelId - Id of localization string for button label
 * @property {Icon} props.buttonIcon - button Icon
 * @property {string} [props.dataTip=''] - string for data-tip property
 * @property {boolean} [props.disabled=false] - if true button should be in disabled state
 * @property {function} props.handleClick - callback to use when button is clicked
 * @property {string} [props.textSize="11px"] - string that determines button text size
 * @returns {Component} Button component
 */
const PageHeaderButton = ({
  buttonLabelId,
  buttonIcon,
  dataTip = '',
  disabled = false,
  handleClick,
  textSize = '11px'
}) => {
  const { t } = useTranslation();

  return (
    <ToolTip content={dataTip} placement="bottom">
      <Button
        style={{ padding: '6px 11px' }}
        data-tip={dataTip}
        onClick={handleClick}
        disabled={disabled}
        primary
        label={
          <Box direction="row" align="center" justify="center" gap="small" height="20px">
            <FontAwesomeIcon icon={buttonIcon} color={THEME_COLORS.white} />
            <Text color="white" size={textSize} weight={700}>
              {t(buttonLabelId)}
            </Text>
          </Box>
        }
        fill
      />
    </ToolTip>
  );
};

export default PageHeaderButton;
