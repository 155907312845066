import { createSlice } from '@reduxjs/toolkit';
import { UserRole } from 'src/config';
import api, {
  addOrUpdateFileUpload,
  deleteFileUpload,
  getCurrentUser,
  saveTokens,
  signInUser,
  signOutUser,
  updateUserByIdRequest
} from 'src/utility/api';
import { getEmployeeOnlyPermissions } from 'src/utility/permissions';
import { refreshCompany } from '../companies/companiesSlice';

const initialState = {
  managedSite: null, // in case of VB admin take over, id of company/site admin will connect to
  appInfo: {},
  user: {},
  siteLinks: {},
  connectionSite: null // stores company admin connection site if different from default
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // use to downgrade an admin connecting as beneficiary to this role
    switchUserToBeneficiary: (state) => {
      state.user.role = 'employee';
      // remove admins permissions
      state.user.permissions = getEmployeeOnlyPermissions(state.user.role);
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    replaceUserCompany: (state, action) => {
      state.user.company = { ...action.payload };
    },
    setUserSite: (state, action) => {
      state.connectionSite = action.payload;
    },
    setManagedSite: (state, action) => {
      state.managedSite = action.payload;
    },
    updateUserCompany: (state, action) => {
      state.user.company = { ...state.user.company, ...action.payload };
    },
    updateSiteLinks: (state, action) => {
      state.siteLinks = { ...state.siteLinks, ...action.payload };
    },
    updateAppInfo: (state, action) => {
      const { pkgVersion, bucket, region } = action.payload;
      state.appInfo = { ...state.appInfo, pkgVersion, bucket, region };
    }
  }
});

export const {
  updateUser,
  updateUserCompany,
  replaceUserCompany,
  setUserSite,
  setManagedSite,
  updateSiteLinks,
  switchUserToBeneficiary,
  updateAppInfo
} = authSlice.actions;

export const logout =
  (resetState = true) =>
  async (dispatch) => {
    console.log('User logout...');
    dispatch({
      type: 'USER_LOGOUT',
      payload: resetState ? 'resetState' : ''
    });
    dispatch(signOut());
  };

export const setToken =
  (token, refreshToken = null) =>
  async (dispatch) => {
    saveTokens(token, refreshToken);
  };

export const signOut = () => async (dispatch) => {
  signOutUser();
};

export const signIn =
  ({ email, password, referer = '' }) =>
  async (dispatch) => {
    try {
      dispatch(setManagedSite(null));
      const { user, token, refreshToken } = await signInUser(email, password, referer);
      dispatch(updateUser(user));
      dispatch(setToken(token, refreshToken));
      return { status: 'success' };
    } catch (e) {
      console.error('signIn Error: ', e);
      return { status: 'error', data: e.message };
    }
  };

export const sendResetEmail = (email) => async (dispatch) => {
  try {
    await api.post('/auth/send-reset-email', { email }).then((res) => res.data);
    return { status: 'success' };
  } catch (e) {
    console.error('sendResetEmail Error: ', e);
    return { status: 'error', data: e?.response?.data?.errors };
  }
};

export const resetPassword =
  ({ resetToken, newPassword }) =>
  async (dispatch) => {
    try {
      const {
        data: { status = 'error' }
      } = await api.post('/auth/reset-password', { resetToken, newPassword });
      return { status };
    } catch (e) {
      console.error('resetPassword Error: ', e.message);
      return { status: 'error', data: e.message };
    }
  };

export const signUpCompany = (formData) => async (dispatch) => {
  try {
    await api.post('/auth/signup-company', formData);
    return { status: 'success' };
  } catch (e) {
    console.error('signUpCompany Error: ', e);
    return { status: 'error', data: e?.response?.data?.errors };
  }
};

export const signUpEmployee = (formData) => async (dispatch) => {
  try {
    await api.post('/auth/signup-company-member', formData);
    return { status: 'success' };
  } catch (e) {
    console.error('signUpEmployee Error: ', e);
    return { status: 'error', data: e?.response?.data?.errors };
  }
};

export const refreshAuthUser = () => async (dispatch) => {
  try {
    const { user } = await getCurrentUser();
    dispatch(updateUser(user));
  } catch (e) {
    console.error('refreshAuthUser error: ', e);
  }
};

export const verifyUserToken = () => async (dispatch, getState) => {
  try {
    const { user } = await getCurrentUser();
    if (user) {
      if (JSON.stringify(user) !== JSON.stringify(getState().auth.user)) {
        // only update user if there are differences with auth.user
        dispatch(updateUser(user));
      }
    }
  } catch (e) {
    // No need to do anything special here, no token requires a new signIn
  }
};

// There is no error handling in this function as there is already some error logic in api.js
// and errors should be handle where the function is called (This pattern will be generalized)
export const updateAuthUser =
  (data, update = true) =>
  async (dispatch, getState) => {
    const userId = getState().auth?.user?._id;
    const response = await updateUserByIdRequest(userId, data);
    if (update) {
      dispatch(updateUser(data));
    }
    return response;
  };

/**
 * Performs practitioner pricing update
 * @param {Object} data - pricing data
 * @returns
 */
export const updatePractitionerPricing = (data) => async (dispatch, getState) => {
  try {
    const { _id: userId, role } = getState().auth?.user;
    if (role !== UserRole.practitioner) {
      return { status: 'error', data: 'Invalid user role' };
    }
    await api.put('/users/' + userId + '/update-pricing', data).then((res) => res.data);
    dispatch(updateUser(data));
    return { status: 'success' };
  } catch (e) {
    console.error('error updating practitioner pricing: ', e);
    return { status: 'error', data: e?.response?.data?.errors };
  }
};

export const updateAuthUserPassword = (data) => async () => {
  try {
    await api.post('/users/update-password', data).then((res) => res.data);
    return { status: 'success' };
  } catch (e) {
    console.error('error while updating auth user pwd: ', e.message);
    return { status: 'error', error: e.message };
  }
};

export const updateAdminPwd = (data) => async () => {
  try {
    await api.post('/users/update-admin-pwd', data).then((res) => res.data);
    return { status: 'success' };
  } catch (e) {
    console.error('error while updating Viabeez admin pwd: ', e.message);
    return { status: 'error', error: e.message };
  }
};

export const takeOverCompanyMgt = (data) => async (dispatch) => {
  try {
    const { id } = data;
    const { data: responseData } = await api.post('/auth/manage-company', data);
    const { user, token, refreshToken } = responseData;
    dispatch(setManagedSite(id));
    dispatch(updateUser(user));
    dispatch(setToken(token, refreshToken));
    return { status: 'success' };
  } catch (e) {
    console.error('error while trying to take over company mgt: ', e.message);
    return { status: 'error', error: e.message };
  }
};

export const requestAuthUserEmailUpdate = (data) => async () => {
  try {
    console.log('Calling /users/request-email-update API...');
    await api.post('/users/request-email-update', data).then((res) => res.data);
    return { status: 'success' };
  } catch (e) {
    console.error('error while requesting auth user login email update: ', e.message);
    return { status: 'error', error: e.message };
  }
};

export const updateUserEmail = (token) => async () => {
  try {
    console.log('Calling /users/update-email API...');
    const { status, email } = await api.post('/users/update-email', { token }).then((res) => res.data);
    return { status, email };
  } catch (e) {
    console.error('error while updating user login email: ', e.message);
    return { status: 'error', error: e.message };
  }
};

export const updateAuthUserCompanyRequest = (data) => async (dispatch, getState) => {
  try {
    const companyId = getState().auth?.user?.company?._id;
    await api.put('/companies/' + companyId, data).then((res) => res.data);
    dispatch(updateUserCompany(data));
    return { status: 'success' };
  } catch (e) {
    console.error('error updating auth user company: ', e);
    return { status: 'error', error: e?.response?.data?.errors };
  }
};

/**
 * Add or upload a file on the server
 * @param {File} fileToUpload The file to upload
 * @param {string} originalName The original name of the file
 * @param {FileUploadKind} fileKind The file kind
 * @param {string} [fileIdToUpdate] The previous file to update by
 * fileIdToUpdate
 * @param {string} targetId The targeted user or company Id
 * @returns {Promise<Object>}
 */
export const addOrUpdateFile =
  (fileToUpload, originalName, fileKind, fileIdToUpdate, targetId, targetType, fileKey) =>
  async (dispatch, getState) => {
    try {
      const authUserRole = getState().auth.user.role;
      addOrUpdateFileUpload(fileToUpload, originalName, targetId, targetType, fileKind, fileIdToUpdate, fileKey).then(
        () => (authUserRole === UserRole.admin ? dispatch(refreshCompany(targetId)) : dispatch(refreshAuthUser()))
      );
      return { status: 'success' };
    } catch (e) {
      console.error('authSlice.js/addOrUpdateFile - error adding or updating user file : ', e);
      return { status: 'error', error: e?.response?.data?.errors };
    }
  };
/**
 * Delete an uploaded file
 * @param {File} fileToDelete
 * @param {string} targetId
 * @returns
 */
export const deleteFile = (fileToDelete, targetId) => async (dispatch, getState) => {
  try {
    if (fileToDelete?._id) {
      const authUserRole = getState().auth.user.role;
      deleteFileUpload(fileToDelete._id, targetId).then(() =>
        authUserRole === UserRole.admin ? dispatch(refreshCompany(targetId)) : dispatch(refreshAuthUser())
      );
    }
    return { status: 'success' };
  } catch (e) {
    console.error('authSlice.js/deleteFile - error deleting user file : ', e);
    return { status: 'error', error: e?.response?.data?.errors || e?.message };
  }
};
export default authSlice.reducer;
