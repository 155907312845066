import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Anchor, Box, FormField } from 'grommet';
import { Button, HelpLink, PasswordReveal, PasswordValidator, T, TextInput, UserConsent } from 'src/components';
import { UserRole } from 'src/config';
import { getUserFromToken, signupPractitioner, signupSpeaker } from 'src/utility/api';
import { normalizeEmail } from 'src/utility/formValidation';
import { getUrlValidationToken } from 'src/utility/userUtils';
import * as Yup from 'yup';
import Layout, { FormHead } from './LayoutAuth';
import SignUpCompleted from './SignUpCompleted';

const PwdForm = ({ formik, pwdRef, pwdConfirmRef, isLoading, feedback, formDisabled, userRole }) => {
  const { t } = useTranslation();
  const [userAccept, setUserAccept] = useState(false);
  const { touched, errors, handleBlur, handleChange, handleSubmit, values, isValid, dirty } = formik;
  return (
    <Layout>
      <FormHead
        title=""
        description={() => (
          <T textAlign="center" size="16px" weight={600}>
            {t('page.signUp.partner.form.enter.your.password')}
          </T>
        )}
        showCatchPhrase
      />
      <Box width="large" margin={{ top: 'small' }}>
        {feedback.status === 'invalid_token' && (
          /* Specific error with sign in redirect link if provider opens token activation link more than once */
          <Box align="center" animation={{ type: 'fadeIn', duration: 300 }} pad={{ top: 'xsmall', bottom: 'medium' }}>
            <T textAlign="center" size="15px" weight={600} color="status-critical">
              <Trans
                i18nKey="common.token.already.validated"
                t={t}
                components={{ br: <br />, HomeLink: <Anchor size="15px" href="/signin" /> }}
              />
            </T>
          </Box>
        )}
        {feedback.message && (
          <Box align="center" animation={{ type: 'fadeIn', duration: 300 }}>
            <T size="small" color={feedback.status === 'error' ? 'status-critical' : 'brand'}>
              {feedback.message}
            </T>
          </Box>
        )}
        {/* Apply opacity to disabled form */}
        <Box style={{ opacity: formDisabled ? 0.4 : 1 }}>
          <Box width="medium" alignSelf="center" justify="center">
            <FormField
              error={touched.password && errors.password}
              name="password"
              htmlFor="password"
              label={t('form.create.password.label')}
            >
              <PasswordReveal inputRef={pwdRef}>
                <TextInput
                  disabled={formDisabled}
                  ref={pwdRef}
                  type="password"
                  id="password"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  placeholder={
                    <T size="16px" color="lightGrey">
                      {t('form.create.password.placeholder')}
                    </T>
                  }
                />
              </PasswordReveal>
            </FormField>
            <FormField
              error={touched.passwordConfirm && errors.passwordConfirm}
              name="passwordConfirm"
              htmlFor="passwordConfirm"
              label={t('form.confirm.password.label')}
            >
              <PasswordReveal inputRef={pwdConfirmRef}>
                <TextInput
                  disabled={formDisabled}
                  ref={pwdConfirmRef}
                  type="password"
                  id="passwordConfirm"
                  name="passwordConfirm"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.passwordConfirm}
                  placeholder={
                    <T size="16px" color="lightGrey">
                      {t('form.confirm.password.placeholder')}
                    </T>
                  }
                />
              </PasswordReveal>
            </FormField>
            <PasswordValidator value={values.password} valueAgain={values.passwordConfirm} />
          </Box>
          <Box width="600px" alignSelf="center" justify="center">
            <UserConsent setUserOk={setUserAccept} disabled={formDisabled} />
          </Box>
          <Box align="center" margin={{ top: 'medium' }}>
            <Box width="250px">
              <Button
                fill
                primary
                onClick={handleSubmit}
                label={t('signup.password.create.form.action')}
                size="medium"
                disabled={!(isValid && dirty) || isLoading || !userAccept}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <HelpLink role={userRole} />
    </Layout>
  );
};

/**
 * finalizes signup for a provider practitioner or speaker user
 * at this step, if validation token check is successful, user is asked to define password
 * and user account setup is completed
 * @component
 */
const SignUpPartner = () => {
  const { t } = useTranslation();
  const [initialValues] = useState({
    password: '',
    passwordConfirm: ''
  });
  const [user, setUser] = useState();
  const history = useHistory();
  const pwdRef = useRef(null);
  const pwdConfirmRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [formDisabled, setFormDisabled] = useState(false);
  const [showEndStep, setShowEndStep] = useState(false);

  const verifyToken = useCallback(
    async (token) => {
      try {
        if (token) {
          const response = await getUserFromToken(token);
          if (response.status === 'success') {
            console.log('Token validation success, user found');
            if (response?.data) {
              let { user: userFromToken } = response.data;
              if (userFromToken) {
                setUser(userFromToken);
              }
            } else if (response?.user) {
              // HTTP 201 case
              setUser(response.user);
            }
          } else {
            console.warn(`get user from token: ${token} failed response: ${JSON.stringify(response)}`);
            setFeedback({ status: 'error', message: t('common.fetch.from.token.failed') });
          }
        } else {
          console.error('No user token in url!');
          setFeedback({ status: 'error', message: t('common.token.missing') });
          // when this error occurs, disable form and render with lower opacity
          setFormDisabled(true);
        }
      } catch (e) {
        console.error(`invalid user token: ${token} error: ${e?.response} ${e.message}`);
        setFeedback({ status: 'invalid_token', message: '' });
        // when this error occurs, disable form and render with lower opacity
        setFormDisabled(true);
      }
    },
    [setFeedback, t]
  );

  useEffect(() => {
    const validationToken = getUrlValidationToken();
    verifyToken(validationToken);
  }, [verifyToken]);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      password: Yup.string().required().checkPassword(),
      passwordConfirm: Yup.string().required().checkPasswordConfirmation()
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      const { role, _id, firstName, lastName, email, phone } = user;
      let { password } = values;
      let formData = {
        _id,
        firstName,
        lastName,
        email: normalizeEmail(email),
        phone,
        password
      };
      if (role === UserRole.speaker) {
        const { gender, specialties, address, organization } = user;
        formData = { ...formData, gender, specialties, address, organization };
      } else {
        const { network, networkName, validationToken } = user;
        formData = { ...formData, validationToken, network, networkName, isProvider: true };
      }
      console.log(`Signup Update API call: role: ${user.role}`);
      const { status } = await (role === UserRole.practitioner
        ? signupPractitioner(formData)
        : signupSpeaker(formData));
      console.log(`Signup Update API status: ${status} role: ${user.role}`);
      if (status === 'success') {
        if (user.role === UserRole.practitioner) {
          history.push(`/onboarding-pro-signin?email=${encodeURIComponent(formData.email)}`);
        } else {
          // speaker user case, redirect to signup completed page after 3 seconds
          setTimeout(setShowEndStep(true), 3000);
        }
      } else {
        setFeedback({ status: 'error', message: t('common.error.unexpected.on.user.action') });
      }
      setIsLoading(false);
    }
  });

  return (
    <>
      {showEndStep ? (
        <SignUpCompleted role={UserRole.speaker} />
      ) : (
        <PwdForm
          formik={formik}
          pwdRef={pwdRef}
          pwdConfirmRef={pwdConfirmRef}
          isLoading={isLoading}
          feedback={feedback}
          formDisabled={formDisabled}
          userRole={user?.role}
        />
      )}
    </>
  );
};

export default SignUpPartner;
