import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text, TextArea } from 'grommet';
import { t } from 'i18next';
import { AvatarWithPlaceholder, UrlLink } from 'src/components';
import { FileUploadKind } from 'src/config';
import { THEME_COLORS } from 'src/config/theme';
import { getUserGender, USER_GENDER } from 'src/constants';
import Modal from './Modal';

//check if speaker prop exists to render modal and prevent useMemo from being called conditionally
const SpeakerProfileModal = (props) => (props?.speaker ? <ProfileModal {...props} /> : null);

const ProfileModal = ({ show, setShow, speaker }) => {
  const { gender, fileUploads, organization, firstName, lastName } = speaker ?? {};
  const { name, url, description } = organization ?? {};

  const avatar = fileUploads?.find((file) => file.kind === FileUploadKind.avatar);
  const userGender = gender ? getUserGender(gender) : '';

  const handleCloseProfile = (e) => {
    e.stopPropagation();
    setShow(false);
  };

  const hasOrgData = useMemo(() => {
    return name !== '';
  }, [name]);

  const orgDescriptionDisplay = useMemo(() => {
    return description === '' ? t('common.not.defined.female.label') : description;
  }, [description]);

  return (
    <Modal show={show} setShow={setShow}>
      <Box round="4px" border={{ color: THEME_COLORS.text, size: '1px', style: 'solid' }} width={{ max: '600px' }}>
        <Box direction="row" alignSelf="end" margin="small">
          <FontAwesomeIcon
            icon={faCircleXmark}
            color={THEME_COLORS.title}
            size="1x"
            onClick={handleCloseProfile}
            cursor="pointer"
          />
        </Box>
        <Box pad={{ left: '45px', bottom: '25px', right: '45px' }}>
          <AvatarWithPlaceholder avatarSrc={avatar?.url} sizePx="96" isCompany={false} />
          <Text weight={700} size="large" color="brand" margin={{ top: '25px' }}>
            {gender !== USER_GENDER.OTHER && userGender} {firstName ? firstName : ''}{' '}
            {lastName ? lastName.toUpperCase() : ''}
          </Text>
          <Box height={{ max: 'medium' }} overflow="auto" pad={{ top: 'small' }}>
            {!hasOrgData ? (
              <Text size="16px" color="text" margin={{ top: '20px' }} alignSelf="center">
                {t('speaker.organization.empty')}
              </Text>
            ) : (
              <>
                {name !== '' && (
                  <>
                    <Text color="title" weight={700} margin={{ top: '20px' }}>
                      {t('speaker.organization.name.label.short')}
                    </Text>
                    <Text size="16px" color="text" weight={400} margin={{ left: '5px', top: '3px' }}>
                      {name}
                    </Text>
                  </>
                )}
                <Text color="title" weight={700} margin={{ top: '20px' }}>
                  {t('speaker.organization.url.label')}
                </Text>
                <Text size="16px" color="text" weight={400} margin={{ left: '5px', top: '3px' }}>
                  {url ? (
                    <UrlLink url={url} linkTitleId="speaker.organization.link" linkTextSize="small" />
                  ) : (
                    t('common.not.defined.female.label')
                  )}
                </Text>
                <Text color="title" weight={700} margin={{ top: '20px' }}>
                  {t('speaker.organization.description.short.label')}
                </Text>
                <TextArea
                  readOnly
                  spellCheck="true"
                  resize={false}
                  rows={6}
                  cols={60}
                  value={orgDescriptionDisplay}
                  size="small"
                />
              </>
            )}
          </Box>
          <Text size="13px" textAlign="center" margin={{ top: '35px' }}>
            <Trans i18nKey="speaker.profile.warning" t={t} components={{ br: <br /> }} />
          </Text>
        </Box>
      </Box>
    </Modal>
  );
};

export default SpeakerProfileModal;
