import React from 'react';
import { useTranslation } from 'react-i18next';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { upperCase } from 'lodash';
import moment from 'moment';
import { THEME_COLORS } from 'src/config/theme';
import { AppointmentStatus, AttendeeStatus } from 'src/constants';
import {
  computeAppointmentRevenue,
  getAppointmentStatusLabel,
  getAttendeeStatusLabel,
  isEventWithEquipment,
  parseRevenue
} from 'src/utility/practitionerUtils';
import { useAppointmentsInformations } from '../../features/events/useAppointmentsInformations';
import { usePopulatedEventRegistration } from '../../features/events/usePopulatedEventRegistration';
import { Spinner, ToolTip } from '../index';
import AnalyticsDocument from './AppointmentsPdf/AnalyticsDocument';

const EventAnalyticsDownloadButton = ({ event, tooltipPlacement }) => {
  const { t } = useTranslation();
  const { status, groupSessionEvent, appointments = [], attendees = [] } = event ?? {};
  const eventRegistration = usePopulatedEventRegistration(event, false, true) || [];
  const isAnEventWithEquipment = isEventWithEquipment(event);
  let tableData = [];

  const { achievedAppointmentsCount, totalRevenue, formattedRevenue } = useAppointmentsInformations(
    appointments,
    event
  );

  if (event && status === 'registered') {
    tableData = eventRegistration.reduce((acc, registration) => {
      const {
        beneficiaryAttended,
        beneficiaryEquipped,
        revenue,
        // only for group session events
        attendanceStatus,
        firstName,
        lastName,
        // only for appointment based events
        status,
        populatedEmployee,
        start,
        end
      } = registration;

      let confirmationMatch = true;
      let validity = upperCase(t('common.label.ok'));
      const userSeen = groupSessionEvent
        ? attendanceStatus && attendanceStatus === AttendeeStatus.seen
        : status && status === AppointmentStatus.seen;

      if (beneficiaryAttended === true) {
        if (!userSeen) {
          confirmationMatch = false;
          validity = t('pdf.events.analytics.validity.error.attendance');
        }
        if (
          isAnEventWithEquipment &&
          ((beneficiaryEquipped === true && !revenue) || (beneficiaryEquipped === false && !!revenue))
        ) {
          confirmationMatch = false;
          validity = t('pdf.events.analytics.validity.error.revenue');
        }
      } else if (beneficiaryAttended === false) {
        if (userSeen) {
          confirmationMatch = false;
          validity = t('pdf.events.analytics.validity.error.attendance');
        }
      }
      let beneficiaryAttendanceLabel = t('common.beneficiary.presence.status.unknown');
      if (beneficiaryAttended === true) {
        beneficiaryAttendanceLabel = t('common.beneficiary.presence.status.present');
      } else if (beneficiaryAttended === false) {
        beneficiaryAttendanceLabel = t('common.beneficiary.presence.status.absent');
      }
      const presenceLabel = groupSessionEvent
        ? attendanceStatus
          ? getAttendeeStatusLabel(t, attendanceStatus, true)
          : ''
        : status
          ? getAppointmentStatusLabel(t, status, true)
          : '';
      acc.push({
        time: groupSessionEvent ? '' : `${start} - ${end}`,
        lastName: groupSessionEvent ? lastName : populatedEmployee?.lastName || '',
        firstName: groupSessionEvent ? firstName : populatedEmployee?.firstName || '',
        beneficiaryConfirmation: beneficiaryAttendanceLabel,
        presenceStatus: presenceLabel || t('common.not.provided.male.label'),
        revenue: parseRevenue(computeAppointmentRevenue(registration, event)),
        confirmationMatch,
        validity
      });
      return acc;
    }, []);
  }

  return (
    <>
      {tableData.length > 0 ? (
        <ToolTip content={t('pdf.events.analytics.button.tooltip')} placement={tooltipPlacement || 'bottom'}>
          <PDFDownloadLink
            document={
              <AnalyticsDocument
                tableData={tableData}
                registrationCount={groupSessionEvent ? attendees.length : achievedAppointmentsCount}
                totalRevenue={totalRevenue}
                formattedRevenue={formattedRevenue}
                event={event}
              />
            }
            fileName={t('pdf.events.analytics.fileName', {
              eventDate: moment(event.dateStart).tz('UTC').format('DD_MM_YYYY')
            })}
            onClick={(e) => e.stopPropagation()}
          >
            {({ loading }) =>
              loading ? (
                <Spinner size={7} />
              ) : (
                <FontAwesomeIcon icon={faDownload} color={THEME_COLORS.brand} size="lg" />
              )
            }
          </PDFDownloadLink>
        </ToolTip>
      ) : (
        <FontAwesomeIcon icon={faDownload} color={THEME_COLORS.brand} opacity={0.3} size="lg" />
      )}
    </>
  );
};

export default EventAnalyticsDownloadButton;
