import { orderBy } from 'lodash';
import { getPropByString } from './objectUtils';

/**
 * @description Utility function that allows sorting by key and order
 * (function needs to be passed to Object.sort method)
 * the sort covers strings with accented characters é, è, ...
 * @param {Object.key} key Object key defining by which value the target object must be sorted by
 * @param {string} order A string parameter that defines whether the target Object must be sorted by ascendant order ("asc", default) or descendant order ("desc")
 * @returns -1 or 1 depending on sorting order
 */
export const dynamicSort = (key, order = 'asc') => {
  const sortOrder = order === 'desc' ? -1 : 1;
  return (a, b) => {
    const propA = getPropByString(a, key);
    const propB = getPropByString(b, key);
    const valA = propA ? propA.toLowerCase() : '';
    const valB = propB ? propB.toLowerCase() : '';
    const res = valA.localeCompare(valB);
    return res * sortOrder;
  };
};

export const ascendantSort = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

/**
 * Order events by specific order
 * @function
 * @param {array} events The events to sort
 * @param {'asc' | 'desc'} order The sort order
 * @returns {array} Returns the new sorted array
 */
export const orderEventsByDate = (events, order) => orderBy(events, [(event) => new Date(event.dateStart)], [order]);
