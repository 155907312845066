import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ResponsiveContext, Text } from 'grommet';
import { t } from 'i18next';
import moment from 'moment';
import { isUnlimitedCatchmentPartner, UserRole } from 'src/config';
import { THEME_COLORS } from 'src/config/theme';
import { EVENT_STATUS } from 'src/constants';
import PractitionerReassignWarning from 'src/features/dashboard/partner/components/practitioner/PractitionerReassignWarning';
import { getEventCardBackground, isEventFull } from 'src/utility/eventUtils';
import { getEventPractitioner, getPractitionerTitleLabel } from 'src/utility/practitionerUtils';
import styled from 'styled-components';
import EventDetailsIcons from '../../features/events/EventDetailsIcons';
import { CardButton } from '../index';
import PractitionerProfileModal from '../Modal/PractitionerProfileModal';
import SpeakerProfileModal from '../Modal/SpeakerProfileModal';
import AppointmentsCount from './AppointmentsCount';
import AttendeesCount from './AttendeesCount';
import AvatarWithPlaceholder from './AvatarWithPlaceholder';
import {
  CARD_ACTION_BOX_MARGIN,
  CARD_ACTION_BOX_MARGIN_X_2,
  CARD_ACTION_BOX_MARGIN_X_3,
  CARD_ACTION_TEXT_SIZE,
  EventLocation,
  getEventCompanyAvatarSrc,
  getEventUserAvatarSrc,
  PractitionerName,
  PractitionerPhone,
  toDate
} from './cardData';

const ShadowBox = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border: ${(props) => props.selected && `2px ${THEME_COLORS.brand} dashed`};
  padding: ${(props) => props.selected && '3px'};
`;

const CompanyActions = ({ event, onClickSendReminderMail, onClickUpdateEvent, onClickDeleteEvent }) => {
  const { t } = useTranslation();
  const handleClickSendReminderMail = useCallback(() => {
    onClickSendReminderMail(event);
  }, [event, onClickSendReminderMail]);
  const handleClickUpdateEvent = useCallback(() => {
    onClickUpdateEvent(event);
  }, [event, onClickUpdateEvent]);
  const handleClickDeleteEvent = useCallback(() => {
    onClickDeleteEvent(event._id);
  }, [event, onClickDeleteEvent]);

  const eventNotFull = useMemo(() => !isEventFull(event), [event]);
  const eventNotToday = useMemo(() => moment().isBefore(event.dateStart, 'day'), [event]);

  const updateActionMargin = useMemo(() => {
    let margin = CARD_ACTION_BOX_MARGIN;
    if (!eventNotFull && !eventNotToday) {
      margin = CARD_ACTION_BOX_MARGIN_X_3;
    } else {
      if (!eventNotToday || !eventNotFull) {
        margin = CARD_ACTION_BOX_MARGIN_X_2;
      }
    }
    return margin;
  }, [eventNotFull, eventNotToday]);

  return (
    <>
      {eventNotFull && eventNotToday && (
        <CardButton onClick={handleClickSendReminderMail} margin={CARD_ACTION_BOX_MARGIN}>
          <Text size={CARD_ACTION_TEXT_SIZE} color="brand">
            {t('page.events.eventCard.sendReminder')}
          </Text>
        </CardButton>
      )}
      <CardButton onClick={handleClickUpdateEvent} margin={updateActionMargin}>
        <Text size={CARD_ACTION_TEXT_SIZE} color="brand">
          {t('page.events.eventCard.update')}
        </Text>
      </CardButton>
      {eventNotToday && (
        <CardButton onClick={handleClickDeleteEvent} margin={CARD_ACTION_BOX_MARGIN}>
          <Text size={CARD_ACTION_TEXT_SIZE} color="danger">
            {t('page.events.eventCard.cancel')}
          </Text>
        </CardButton>
      )}
    </>
  );
};

const PractitionerActions = ({ event, setCurrEventId, onClickUnregister }) => {
  const { t } = useTranslation();
  const handleSeeDetail = useCallback(() => {
    setCurrEventId(event._id);
  }, [event, setCurrEventId]);

  const handleUnregister = useCallback(() => {
    onClickUnregister(event);
  }, [event, onClickUnregister]);

  const notStartedEvent = useMemo(() => moment().isBefore(event.dateStart, 'day'), [event]);

  return (
    <>
      <CardButton
        margin={notStartedEvent ? CARD_ACTION_BOX_MARGIN : CARD_ACTION_BOX_MARGIN_X_2}
        onClick={handleSeeDetail}
      >
        <Text color="brand" size={CARD_ACTION_TEXT_SIZE}>
          {t('page.events.eventCard.details')}
        </Text>
      </CardButton>
      {notStartedEvent && (
        <CardButton margin={CARD_ACTION_BOX_MARGIN} onClick={handleUnregister} justify="center">
          <Text color="danger" size={CARD_ACTION_TEXT_SIZE}>
            {t('page.events.eventCard.unregister')}
          </Text>
        </CardButton>
      )}
    </>
  );
};

const CompanyInfo = ({ event }) => {
  return (
    <>
      <AvatarWithPlaceholder avatarSrc={getEventCompanyAvatarSrc(event)} isCompany sizePx="72" />{' '}
      <Text color="title" weight={700}>
        {event?.company?.name}
      </Text>
      <Text>{event?.company?.address?.street}</Text>
      <Text>
        {event?.company?.address?.zipcode} {event?.company?.address?.city}
      </Text>
    </>
  );
};

const PractitionerInfo = ({ event }) => {
  const [showProfile, setShowProfile] = useState(false);
  const practitioner = getEventPractitioner(event);
  const { status, groupSessionEvent } = event ?? {};
  const { role, email, specialty } = practitioner ?? {};
  const isReassignEvent = status === EVENT_STATUS.reassign;
  const isSpeaker = role === UserRole.speaker;

  return (
    <>
      <Box
        alignContent="around"
        direction="row"
        align="center"
        alignSelf="center"
        style={{
          cursor: isReassignEvent ? 'default' : 'pointer'
        }}
        onClick={
          isReassignEvent
            ? () => {}
            : () => {
                setShowProfile((prevState) => !prevState);
              }
        }
        title={isReassignEvent ? '' : t('common.partner.display.profile')}
      >
        <AvatarWithPlaceholder avatarSrc={getEventUserAvatarSrc(event)} />
        <PractitionerName event={event} nameOnly />
      </Box>
      {isSpeaker ? (
        <SpeakerProfileModal speaker={practitioner} show={showProfile} setShow={setShowProfile} />
      ) : (
        <PractitionerProfileModal practitioner={practitioner} show={showProfile} setShow={setShowProfile} />
      )}
      {isReassignEvent ? (
        <PractitionerReassignWarning isSpeaker={isSpeaker} />
      ) : (
        <>
          <Box direction="row">
            <PractitionerPhone event={event} />
            &nbsp;&nbsp;/&nbsp;&nbsp;
            <Text color="brand" size="small">
              {email || ''}
            </Text>
          </Box>
          <Text color="brand">{getPractitionerTitleLabel(groupSessionEvent ? event?.specialty : specialty)}</Text>
        </>
      )}
    </>
  );
};

const ScheduledEventCard = ({
  onClickDeleteEvent,
  onClickUpdateEvent,
  onClickSendReminderMail,
  event,
  isCompanyCard = false,
  setCurrEventId,
  selected,
  onClickUnregister,
  hasDelegateEvents,
  onClickShowPlanning,
  isPartner = false,
  ...rest
}) => {
  const responsiveSize = useContext(ResponsiveContext);
  const isSmallSize = responsiveSize === 'small';
  const isMediumSize = responsiveSize === 'medium';
  const isUnlimitedCatchment = isUnlimitedCatchmentPartner(event?.practitioner);
  const boxSize = !isUnlimitedCatchment || isCompanyCard ? '1/1' : '1/3';
  const backgroundColor = getEventCardBackground(event, isPartner);
  const { groupSessionEvent } = event ?? {};
  return (
    <Box
      pad="small"
      basis={isSmallSize ? '1/1' : !isCompanyCard && isUnlimitedCatchment && isMediumSize ? '1/2' : boxSize}
      width="100%"
    >
      <ShadowBox
        round="8px"
        overflow="hidden"
        align="center"
        background="brand"
        selected={selected}
        {...rest}
        margin={{ vertical: isSmallSize ? 'medium' : '' }}
      >
        <Box fill="horizontal" background={backgroundColor} direction="row" justify="between" pad="xsmall">
          <Text weight={700} color="white">
            {toDate(event.dateStart)}
            {' - '}
            {event.timeStart}
            {' à '}
            {event.timeEnd}
          </Text>
          {isCompanyCard && <EventDetailsIcons event={event} />}
        </Box>
        <Box fill="horizontal" background={backgroundColor} direction="row" pad="xsmall">
          <Text color="white">{event.specialty.label}</Text>
        </Box>
        <Box fill="horizontal" background={backgroundColor} direction="row" justify="between" pad="xsmall">
          <EventLocation event={event} />
        </Box>
        <Box fill background="white" align="center" justify="center" height={{ min: '300px' }}>
          <Box margin="small" align="center">
            {isCompanyCard ? <PractitionerInfo event={event} /> : <CompanyInfo event={event} />}
            {groupSessionEvent ? (
              <AttendeesCount event={event} hasBookingText hasBookingMeter showWaitingList />
            ) : (
              <AppointmentsCount
                event={event}
                hasBookingText
                hasBookingMeter
                showWaitingList
                onClickShowPlanning={isCompanyCard ? onClickShowPlanning : null}
              />
            )}
          </Box>
          {isCompanyCard ? (
            <CompanyActions
              event={event}
              onClickSendReminderMail={onClickSendReminderMail}
              onClickUpdateEvent={onClickUpdateEvent}
              onClickDeleteEvent={onClickDeleteEvent}
            />
          ) : (
            <PractitionerActions event={event} setCurrEventId={setCurrEventId} onClickUnregister={onClickUnregister} />
          )}
        </Box>
      </ShadowBox>
    </Box>
  );
};

export default ScheduledEventCard;
