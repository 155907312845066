import React, { useCallback, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Anchor, Box, Button, Notification, ResponsiveContext, Text } from 'grommet';
import { ActionModal, CheckBox, ConfirmationDialog, EventTags, Separator, SlotTimeBoxContainer } from 'src/components';
import { THEME_COLORS } from 'src/config/theme';
import EventPractitioner from 'src/features/dashboard/employee/components/EventPractitioner';
import { toggleEventNoLongerFullSubscription } from 'src/utility/api';
import styled from 'styled-components';
import { fetchAndRefreshEmployeeEvents } from '../../features/events/eventsSlice';
import { isPhoneSpecialty, isRemoteSpecialty } from '../../features/specialties/specialtiesUtils';
import SpecialtyDocumentLink from '../../features/specialties/SpecialtyDocumentLink';
import { groupSessionEventSeatStats, isEventFull, isEventPast } from '../../utility/eventUtils';
import { EventLocation, getAppointmentDuration, getAppointmentPrice, toDate } from '../Card/cardData';

const ShadowBox = styled(Box)`
  background: #ffffff;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

/**
 * UI component to manage event waiting list subscription when event is full
 * No UI if event no longer full or completely past now or user already registered to this event
 * @param {Object} props - component properties
 * @param {Object} props.event - event to process
 * @param {boolean} props.registered - true if beneficiary already has either an appointment or is registered to this event
 * @returns {React.FC}
 */
const CardFooter = ({ event, registered }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFull = isEventFull(event);
  const { groupSessionEvent, eventNotFullSubscribers = [], _id: eventId } = event ?? {};
  const currentUser = useSelector((state) => state?.auth?.user);
  const [errorMessageKey, setErrorMessageKey] = useState('');
  const [checked, setChecked] = useState(
    !!eventNotFullSubscribers?.find((subscriberId) => currentUser._id === subscriberId)
  );
  const [showNotification, setShowNotification] = useState({});

  const handleHideNotification = useCallback(() => {
    setShowNotification({});
  }, [setShowNotification]);

  const handleClickCheckbox = useCallback(
    (checkboxNewState) => {
      toggleEventNoLongerFullSubscription(eventId, checkboxNewState)
        .then((res) => {
          if (res.status === 'success') {
            setShowNotification({
              status: 'success',
              messageId: checkboxNewState ? 'added.to.waiting.list.label' : 'removed.from.waiting.list.label'
            });
            setChecked(checkboxNewState);
          }
        })
        .catch((error) => {
          console.error('EmployeeEventCard.js/toggleEventNoLongerFullSubscription | ', error);
          if (error.message === 'event_not_full') {
            setErrorMessageKey(
              groupSessionEvent
                ? 'available.event.card.subscribe.seats.update.error.not.full'
                : 'available.event.card.subscribe.slots.update.error.not.full'
            );
          }
        });
    },
    [eventId, groupSessionEvent, setChecked]
  );

  const handleHideErrorModal = useCallback(() => {
    setErrorMessageKey('');
    dispatch(fetchAndRefreshEmployeeEvents());
  }, [dispatch]);

  if (!isFull || registered || isEventPast(event)) return null;

  return (
    <>
      <Box justify="end" flex="grow" margin={{ top: 'small' }}>
        <Box align="row" justify="center" width="100%">
          <Text color="red" size="small" textAlign="center" weight={700}>
            {groupSessionEvent ? t('available.event.no.seat.available') : t('available.event.no.slot.available')}
          </Text>
          <CheckBox
            label={
              <Text color="text" size="small" weight="normal">
                {groupSessionEvent
                  ? t('available.event.subscribe.seats.update.label')
                  : t('available.event.subscribe.slots.update.label')}
              </Text>
            }
            checked={checked}
            setChecked={handleClickCheckbox}
          />
        </Box>
        <ActionModal
          showModal={!!errorMessageKey}
          onHideModal={handleHideErrorModal}
          body={
            <Trans
              i18nKey={errorMessageKey} // optional -> fallbacks to defaults if not provided
              t={t}
              components={{ br: <br /> }}
            />
          }
          confirmLabel={t('common.label.ok').toUpperCase()}
          onConfirm={handleHideErrorModal}
          noCancelButton
        />
      </Box>
      {showNotification?.status && (
        <Notification
          icon={<FontAwesomeIcon icon={faThumbsUp} color={THEME_COLORS.available} size="xl" />}
          toast
          status="normal"
          title=""
          message={
            <Box align="center">
              <Text size="medium" color="brand" weight={700}>
                {t(showNotification.messageId)}
              </Text>
            </Box>
          }
          onClose={handleHideNotification}
          time={2000}
        />
      )}
    </>
  );
};

const GroupSessionEventRegister = ({ event, onRegister, userId, onEventBooked }) => {
  const { t } = useTranslation();
  const { attendees = [], dateStart, timeStart, timeEnd } = event ?? {};
  const eventFull = isEventFull(event);
  const { available } = groupSessionEventSeatStats(event);
  const date = toDate(dateStart, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
  const alreadyRegistered = attendees.find((attendee) => attendee.employee === userId);
  const [confirmModal, setConfirmModal] = useState(false);
  return (
    <>
      <Box gap="small">
        {eventFull ? (
          <>
            <Text color={THEME_COLORS.danger} textAlign="center" weight={800}>
              {t('event.state.full')}
            </Text>
            <Text textAlign="center" size="xsmall">
              {t('group.session.event.full.tip')}
            </Text>
          </>
        ) : (
          <Text color={THEME_COLORS.available} textAlign="center" weight={800}>
            {t('group.session.event.available.seats', { count: available })}
          </Text>
        )}
        {alreadyRegistered ? (
          <Anchor onClick={() => onEventBooked()} style={{ textAlign: 'center' }}>
            <Text textAlign="center" color="brand" margin={{ top: 'none', bottom: 'small' }} weight={500}>
              {t('beneficiary.already.registered')}
            </Text>
          </Anchor>
        ) : (
          <Button
            alignSelf="center"
            width="140px"
            disabled={eventFull}
            size="medium"
            bold
            primary
            label={t('common.button.register')}
            onClick={() => setConfirmModal(true)}
          />
        )}
      </Box>
      {confirmModal && (
        <ConfirmationDialog
          onConfirm={() => {
            setConfirmModal();
            onRegister({ event });
          }}
          onCancel={() => {
            setConfirmModal();
          }}
          message={t('group.session.confirm.register.message')}
          actionText={t('common.label.confirm')}
          dialogIcon={faCircleQuestion}
          iconColor={THEME_COLORS.brand}
          body={
            <Text size="small" textAlign="center" color="title" weight={700}>
              <Trans
                i18nKey={'group.session.confirm.register.reminder'} // optional -> fallbacks to defaults if not provided
                t={t}
                components={{ u: <u /> }}
                values={{ date, from: timeStart, to: timeEnd }}
              />
            </Text>
          }
        />
      )}
    </>
  );
};

const EmployeeEventCard = ({ event, book, userId, onEventBooked, onShowPractitionerInfo, ...rest }) => {
  const { t } = useTranslation();
  const handleChangeSlots = (slot) => {
    book({ event: event, slot });
  };
  const userData = useSelector((state) => state.auth.user);
  const isDelegateEvent = event?.specialty?.delegate;
  const responsiveSize = useContext(ResponsiveContext);
  const isSmallSize = responsiveSize === 'small';
  const {
    employeeInfo = '',
    slots,
    dateStart,
    timeStart,
    timeEnd,
    specialty: eventSpecialty = {},
    company: { address = {} } = {},
    groupSessionEvent,
    attendees = [],
    appointments = []
  } = event ?? {};

  let appointment;
  let registered = false;
  if (groupSessionEvent) {
    registered = attendees?.find((attendee) => attendee.employee === userId);
  } else {
    appointment = appointments?.find((app) => app.employee === userId);
  }

  return (
    <ShadowBox round="8px" overflow="hidden" {...rest}>
      <Box fill pad={{ bottom: 'small' }}>
        <Box fill="horizontal" background="brand" pad="small">
          <Text color="white" weight="bold">
            {eventSpecialty?.label}
          </Text>
        </Box>
        <Box height={isSmallSize ? '' : '300px'} justify="start">
          <EventPractitioner event={event} />
          <Box margin={{ horizontal: 'medium', vertical: 'small' }}>
            <Text>{address?.street || ''}</Text>
            <Text>
              {address?.zipcode || ''} {address?.city || ''}
            </Text>
            <EventLocation event={event} textColor="accent" textSize="medium" textWeight={600} />
          </Box>
          <EventTags event={event} />
          <Box align="center" margin={{ horizontal: 'small', vertical: 'none' }}>
            <SpecialtyDocumentLink specialty={eventSpecialty} linkTextId="event.description.document.url" />
          </Box>
          {employeeInfo && (
            <Box align="center" margin={{ horizontal: 'small', vertical: 'none' }}>
              <Anchor onClick={() => onShowPractitionerInfo(event)} style={{ textAlign: 'center' }}>
                <Text color="brand" size="xsmall" margin={{ top: 'medium', bottom: 'medium' }} weight={700}>
                  {groupSessionEvent
                    ? t('beneficiary.view.speaker.info.label')
                    : t('beneficiary.view.practitioner.info.label')}
                </Text>
              </Anchor>
            </Box>
          )}
          {isDelegateEvent && !isRemoteSpecialty(eventSpecialty) && (
            <Box margin={{ horizontal: 'small', vertical: 'small' }} alignSelf="center">
              <Text textAlign="center" size="small" weight={700} style={{ fontStyle: 'italic' }}>
                {t('component.event.card.beneficiary.delegate.detail')}
              </Text>
            </Box>
          )}
        </Box>
        <Separator />
        <Box align="center" margin="small" flex="grow">
          <Text weight={600} color="title">
            {/*Set of options make the date display using the following format: "Mardi 17 Octobre 2023" */}
            {toDate(dateStart, {
              weekday: 'long',
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })}
          </Text>
          {groupSessionEvent ? (
            <Text weight={600} color="title">
              {t('beneficiary.group.session.event.duration', { from: timeStart, to: timeEnd })}
            </Text>
          ) : (
            <Text weight={500} size="small">
              {t('beneficiary.event.consultation.duration', { duration: getAppointmentDuration(event) })}
            </Text>
          )}
          {isPhoneSpecialty(eventSpecialty) && userData?.phone && (
            <Text weight={500} size="xsmall">
              {t('beneficiary.available.event.card.phone.call.info')}
            </Text>
          )}
          {getAppointmentPrice(event)}
          {groupSessionEvent ? (
            <>
              {registered && (
                <Anchor onClick={(evt) => onEventBooked()} style={{ textAlign: 'center' }}>
                  <Text color="brand" margin={{ top: 'none', bottom: 'small' }} weight={500}>
                    {t('beneficiary.available.event.card.already.registered')}
                  </Text>
                </Anchor>
              )}
            </>
          ) : (
            <>
              {appointment && (
                <Anchor onClick={(evt) => onEventBooked()} style={{ textAlign: 'center' }}>
                  <Text color="brand" margin={{ top: 'none', bottom: 'small' }} weight={500}>
                    {t('beneficiary.available.event.card.appointment.already.booked')}
                    <br />
                    de{` ${appointment.start} à ${appointment.end}.`}
                  </Text>
                </Anchor>
              )}
            </>
          )}
          <>
            {isPhoneSpecialty(eventSpecialty) && !userData?.phone ? (
              <Text color="accent" margin={{ top: 'medium', bottom: 'medium' }} weight={500} textAlign="center">
                {t('beneficiary.available.event.card.no.phone.warning')}
              </Text>
            ) : (
              <>
                {groupSessionEvent ? (
                  <>
                    {!registered && (
                      <GroupSessionEventRegister
                        event={event}
                        onRegister={book}
                        onEventBooked={onEventBooked}
                        userId={userId}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {!appointment && (
                      <SlotTimeBoxContainer
                        event={event}
                        slots={slots}
                        showBooked={false}
                        showReserved={false}
                        isEmployee={true}
                        onChangeSlot={appointment ? null : handleChangeSlots}
                      />
                    )}
                  </>
                )}
                <CardFooter event={event} registered={groupSessionEvent ? registered : appointment} />
              </>
            )}
          </>
        </Box>
      </Box>
    </ShadowBox>
  );
};

export default EmployeeEventCard;
