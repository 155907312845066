import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Menu, Text } from 'grommet';
import moment from 'moment-timezone';
import { Alert, Modal } from 'src/components';
import { isUserProReferent, UserRole } from 'src/config';
import { THEME_COLORS } from 'src/config/theme';
import { EVENT_STATE, EVENT_STATUS } from 'src/constants';
import { menuIndex } from 'src/features/dashboard/admin/adminDashboardSlice';
import EventDateSelector from 'src/features/dashboard/admin/components/event/EventDateSelector';
import EventMilestonesChart from 'src/features/dashboard/admin/components/event/EventMilestonesChart';
import SendEventReminderModal from 'src/features/events/SendEventReminderModal';
import { unregisterEvent } from 'src/utility/api';
import {
  eventHasFeedback,
  fetchEventFeedback,
  getEventCardBackground,
  isEventFull,
  isEventWithNoTokenUsed
} from 'src/utility/eventUtils';
import { getSpecialtyLabel } from 'src/utility/practitionerUtils';
import EventAnalyticsDownloadButton from '../Analytics/EventAnalyticsDownloadButton';
import { ActionModal, ShadowGrid } from '../index';
import PractitionerProfileModal from '../Modal/PractitionerProfileModal';
import SpeakerProfileModal from '../Modal/SpeakerProfileModal';
import AppointmentsCount from './AppointmentsCount';
import AttendeesCount from './AttendeesCount';
import AvatarWithPlaceholder from './AvatarWithPlaceholder';
import { FeedbackStars, getEventUserAvatarSrc, PractitionerName, toDate } from './cardData';

const AdminEventCard = ({ event, onClickEvent, fetchEvents }) => {
  const { t } = useTranslation();
  const [reminderEvent, setReminderEvent] = useState(false);
  const { menu } = useSelector((state) => state?.adminDashboard?.view);
  const [unregisterUser, setUnregisterUser] = useState(false);
  const isCompanyView = useMemo(() => menu === menuIndex.companies, [menu]);
  const currentUser = useSelector((state) => state?.users?.currentUser);
  const practitionerInView = isCompanyView ? {} : currentUser;
  const [modifyEventDate, setModifyEventDate] = useState(false);
  const [viewEventHistory, setViewEventHistory] = useState(false);
  const [reminderFeedback, setReminderFeedback] = useState();
  const [feedback, setFeedback] = useState({});
  const { total } = feedback;
  const areas =
    isCompanyView || isUserProReferent(practitionerInView)
      ? [['type', 'rowHeader', 'date', 'position', 'meter', 'status', 'feedback', 'menu', 'analytics']]
      : [['type', 'date', 'position', 'meter', 'status', 'feedback', 'menu', 'analytics']];
  const columns =
    isCompanyView || isUserProReferent(practitionerInView)
      ? ['10px', '25%', '13%', '15%', '15%', '15%', '10%', 'auto', 'auto']
      : ['10px', '20%', '15%', '15%', '15%', '10%', 'auto', 'auto'];
  const today = new Date();
  let startDay = moment(today).tz('Europe/Paris').startOf('day').utc().format();
  const {
    _id: eventId,
    status,
    practitioner,
    provider,
    dateStart,
    timeStart,
    timeEnd,
    company = {},
    specialty = {},
    groupSessionEvent
  } = event;
  const { name: companyName = '' } = company ?? {};
  const registeredPractitioner = provider || practitioner;

  useEffect(() => {
    if (eventHasFeedback(event)) {
      fetchEventFeedback(eventId, setFeedback, 'AdminEventCard');
    }
  }, [eventId, event]);

  const eventStatus = useMemo(() => {
    // check if event date is past
    if (moment(startDay).isAfter(dateStart)) {
      return { state: EVENT_STATE.PAST, label: t('common.events.state.past') };
    }
    // event is pending as no practitioner registered yet
    if (!practitioner) {
      if (isEventWithNoTokenUsed(event, company)) {
        return { state: EVENT_STATE.NO_TOKEN, label: t('admin.vb.no.token.used.waiting.event.warning') };
      }
      return {
        state: EVENT_STATE.PENDING,
        label: groupSessionEvent ? t('common.events.state.pending.speaker') : t('common.events.state.pending')
      };
    }
    if (status === EVENT_STATUS.reassign) {
      return {
        state: EVENT_STATE.REASSIGN,
        label: groupSessionEvent ? t('common.events.state.reassign.speaker') : t('common.events.state.reassign')
      };
    }
    // event scheduled in future and is ready (has a practitioner)
    return { state: EVENT_STATE.SCHEDULED, label: t('common.events.state.scheduled') };
  }, [startDay, groupSessionEvent, status, t, dateStart, practitioner, event, company]);

  const handleCloseReminderModal = useCallback(() => {
    setReminderEvent(false);
    fetchEvents();
  }, [setReminderEvent, fetchEvents]);

  const handleReminderFeedback = useCallback(
    (feedback) => {
      setReminderFeedback(feedback);
      setReminderEvent(false);
      fetchEvents();
    },
    [setReminderEvent, fetchEvents]
  );

  const handleClick = useCallback(() => {
    if (practitioner) {
      onClickEvent(event);
    }
  }, [event, onClickEvent, practitioner]);

  const handleUnregisterEvent = () => {
    const { _id: registeredPractitionerId } = registeredPractitioner;

    if (registeredPractitionerId) {
      unregisterEvent(eventId, registeredPractitionerId)
        .catch((error) => {
          console.error('AdminEventCard.js/handleUnregisterEvent | ', error);
        })
        .finally(() => {
          setUnregisterUser(false);
          fetchEvents();
        });
    }
  };

  const menuItems = useMemo(() => {
    const items = [];
    switch (eventStatus.state) {
      case EVENT_STATE.SCHEDULED:
        items.push({
          label: <Text size={'14px'}>{t('common.events.action.unregister')}</Text>,
          onClick: (e) => {
            e.stopPropagation();
            setUnregisterUser(true);
          }
        });
        if (!isEventFull(event)) {
          items.push({
            label: <Text size={'14px'}>{t('page.events.eventCard.sendReminder')}</Text>,
            onClick: (e) => {
              setReminderEvent(true);
              e.stopPropagation();
            }
          });
        }
        break;
      case EVENT_STATE.PENDING:
        items.push({
          label: <Text size={'14px'}>{t('common.events.action.date.change')}</Text>,
          onClick: (e) => {
            e.stopPropagation();
            setModifyEventDate(true);
          }
        });
        break;
      default:
        break;
    }
    items.push({
      label: <Text size={'14px'}>{t('common.events.action.milestone.view')}</Text>,
      onClick: (e) => {
        e.stopPropagation();
        setViewEventHistory(true);
      }
    });
    return items;
  }, [eventStatus, setModifyEventDate, event, t]);

  const [showProfile, setShowProfile] = useState(false);
  const handleViewProfile = (e) => {
    e.stopPropagation();
    setShowProfile((prevState) => !prevState);
  };

  return (
    <>
      <ShadowGrid
        direction="row"
        align="start"
        onClick={handleClick}
        areas={areas}
        rows={['auto']}
        columns={columns}
        /* set default cursor on events with no practitioner (pending state) as card click
           triggers no action */
        cursor={practitioner ? 'pointer' : 'default'}
      >
        <Box
          gridArea="type"
          margin="none"
          style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }}
          background={getEventCardBackground(event)}
          fill="vertical"
          title={groupSessionEvent ? t('event.group.session.tip.label') : ''}
        />
        {isCompanyView || isUserProReferent(practitionerInView) ? (
          <Box pad="small" gridArea="rowHeader" direction="row" align="center" alignSelf="center">
            {!isCompanyView && practitionerInView?._id === registeredPractitioner?._id ? (
              <Text size="small">{t('event.details.referent.self.register')}</Text>
            ) : (
              <>
                <Box
                  align="center"
                  alignSelf="center"
                  onClick={registeredPractitioner && handleViewProfile}
                  title={registeredPractitioner && t('common.partner.display.profile')}
                >
                  <AvatarWithPlaceholder avatarSrc={getEventUserAvatarSrc(event)} />
                </Box>
                <Box
                  alignSelf="center"
                  onClick={registeredPractitioner && handleViewProfile}
                  title={registeredPractitioner && t('common.partner.display.profile')}
                  padding={{ horizontal: 'small' }}
                >
                  <PractitionerName
                    event={event}
                    displayOptions={{ showSubscriber: true, showSubscriberNetwork: true }}
                  />
                </Box>
              </>
            )}
          </Box>
        ) : null}
        <Box pad="small" gridArea="date" align="center" alignSelf="center">
          <>
            <Text textAlign="center">{toDate(dateStart)}</Text>
            <Text size="small" textAlign="center">
              {timeStart} - {timeEnd}
            </Text>
          </>
        </Box>
        <Box pad="small" gridArea="position" align="center" alignSelf="center">
          {isCompanyView ? (
            <Text color="brand" textAlign="center">
              {getSpecialtyLabel(specialty)}
            </Text>
          ) : (
            <>
              <Box fill align="center" alignSelf="center">
                <Text color="brand" textAlign="center">
                  {getSpecialtyLabel(specialty)}
                </Text>
              </Box>
              <Box fill align="center" alignSelf="center">
                <Text size="xsmall" textAlign="center">
                  {companyName.toUpperCase()}
                </Text>
              </Box>
            </>
          )}
        </Box>
        <Box pad="small" gridArea="meter" align="center" alignSelf="center">
          {eventStatus.state !== EVENT_STATE.NO_TOKEN && (
            <>
              {groupSessionEvent ? (
                <AttendeesCount event={event} hasBookingText hasBookingMeter showWaitingList />
              ) : (
                <AppointmentsCount event={event} hasBookingText hasBookingMeter showWaitingList />
              )}
            </>
          )}
        </Box>
        <Box
          gridArea="status"
          align="center"
          alignSelf="center"
          round="6px"
          pad="6px"
          background={{ color: eventStatus.state === EVENT_STATE.NO_TOKEN ? THEME_COLORS.danger : 'inherit' }}
        >
          <Text
            textAlign="center"
            color={
              eventStatus.state === EVENT_STATE.REASSIGN
                ? THEME_COLORS['status-error']
                : eventStatus.state === EVENT_STATE.NO_TOKEN
                  ? THEME_COLORS.white
                  : THEME_COLORS.title
            }
            size="16px"
            weight={600}
          >
            {eventStatus.label}
          </Text>
        </Box>
        {total >= 0 && (
          <Box pad="small" gridArea="feedback" direction="row" justify="center" width="100%" alignSelf="center">
            <FeedbackStars note={total} />
          </Box>
        )}
        <Box pad="small" gridArea="menu" align="center" alignSelf="center">
          <Menu
            disabled={menuItems.length < 1}
            items={menuItems}
            dropProps={{
              a11yTitle: t('common.label.actions'),
              align: { top: 'bottom', right: 'right' },
              elevation: 'large'
            }}
            label={t('common.label.menu')}
            onClick={(e) => e.stopPropagation()}
          >
            <FontAwesomeIcon icon={faEllipsis} color={THEME_COLORS.brand} size="lg" />
          </Menu>
        </Box>
        <Box pad="small" gridArea="analytics" align="center" alignSelf="center">
          <EventAnalyticsDownloadButton event={event} />
        </Box>
      </ShadowGrid>
      {reminderEvent && (
        <SendEventReminderModal event={event} onCancel={handleCloseReminderModal} onComplete={handleReminderFeedback} />
      )}

      <Alert
        icon={reminderFeedback ? reminderFeedback?.icon : null}
        show={reminderFeedback}
        setShow={setReminderFeedback}
        message={reminderFeedback?.title}
        details={reminderFeedback?.message}
      />
      <ActionModal
        modalPadding="none"
        showModal={unregisterUser}
        onHideModal={() => setUnregisterUser(false)}
        title={t('common.events.action.unregister')}
        body={groupSessionEvent ? t('speaker.action.unregister.confirm') : t('practitioner.action.unregister.confirm')}
        cancelLabel={t('common.button.cancel')}
        confirmLabel={t('common.events.action.unregister')}
        onCancel={() => setUnregisterUser(false)}
        onConfirm={() => handleUnregisterEvent()}
      />
      <Modal show={modifyEventDate} setShow={setModifyEventDate}>
        <EventDateSelector event={event} setShow={setModifyEventDate} onChange={fetchEvents} />
      </Modal>
      <ActionModal
        modalPadding="none"
        showModal={viewEventHistory}
        onHideModal={() => setViewEventHistory(false)}
        title={t('event.milestones.title')}
        confirmLabel={t('common.label.ok').toUpperCase()}
        noCancelButton
        autoHideOnConfirm
        modalWidth="large"
      >
        <Box fill align="center" justify="center" overflow="auto">
          <EventMilestonesChart event={event} />
        </Box>
      </ActionModal>
      {registeredPractitioner?.role === UserRole.speaker ? (
        <SpeakerProfileModal speaker={registeredPractitioner} show={showProfile} setShow={setShowProfile} />
      ) : (
        <PractitionerProfileModal practitioner={registeredPractitioner} show={showProfile} setShow={setShowProfile} />
      )}
    </>
  );
};

export default AdminEventCard;
