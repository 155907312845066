import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'grommet';
import { THEME_COLORS } from 'src/config/theme';

/**
 * Component rendering the current events list items counter
 * used for both practitioners & company admins
 * renders nothing if count is zero
 * @param {object} props - component properties
 * @param {number} props.eventCount - the event list items count value
 * @param {function} [props.padding={}] - optional component padding
 * @returns {React.Component} the Event list items counter component
 */
const EventCounter = ({ eventCount, padding = {} }) => {
  const { t } = useTranslation();
  return (
    <>
      {eventCount > 0 && (
        <Box fill pad={padding}>
          <Text color={THEME_COLORS.brand}>{t('jds.label', { count: eventCount })}</Text>
        </Box>
      )}
    </>
  );
};

export default EventCounter;
