import React, { useCallback } from 'react';
import { Box } from 'grommet';
import { ModalLayer } from 'src/components';

/**
 * @description Component that shows a Modal dialog
 * @Component
 * @property {Boolean} show if true Modal should show
 * @property {Boolean} staticBackdrop If true, modal will not close when clicking outside it or ESC key
 * @property {function} setShow function to call to update Modal show status
 * @property {function} [hideAction] function that is called when Modal closed by clicking outside dialog or ESC press
 * @property {String} [titleContent] Modal dialog title
 * Other props like child components can be provided
 * @returns Component
 */
const Modal = (props) => {
  const handleClickOutside = useCallback(() => {
    if (!props.staticBackdrop) {
      props.setShow(false);
      if (props.hideAction) {
        props.hideAction();
      }
    }
  }, [props]);

  const handleEscKey = useCallback(() => {
    if (!props.staticBackdrop) {
      props.setShow(false);
      if (props.hideAction) {
        props.hideAction();
      }
    }
  }, [props]);

  return (
    <>
      {props.show && (
        <ModalLayer onEsc={handleEscKey} onClickOutside={handleClickOutside} borderRadius="10px">
          <Box round="10px" overflow="auto" align="center" background="brand" {...props}>
            {props.titleContent}
            <Box fill background="white" align="center" justify="center" overflow="auto">
              {props.children}
            </Box>
          </Box>
        </ModalLayer>
      )}
    </>
  );
};

export default Modal;
