import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { faBell, faRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { Box, Header, Image, Text } from 'grommet';
import { AppBarMenu, BadgeIcon, DisconnectModal, MenuItemIcon } from 'src/components';
import { getHelpMenuItems } from 'src/utility/helpMenuUtils';
import logo from '../../assets/images/logo.svg';

/**
 * @description Implements application Header Bar with clickable logo and dropdown menu
 * @description Menu entries defined by menuConfig property
 * @component
 * @property {Component} title A title component to display at center of app bar
 * @property {Object} menuConfig Menu configuration
 * @param {boolean}   [menuConfig.hasDisconnectMenu] - if true adds a logout menu item
 * @param {boolean}   [menuConfig.hasHelpMenu] - if true adds a logout menu item
 * @param {Oject[]}  menuConfig.menuItems - array of menu entries
 */
const AppBar = ({
  menuConfig,
  title = '',
  onClickLogo,
  countNotifications,
  canViewNotification,
  showNotifications
}) => {
  const { t } = useTranslation();
  const { menuItems, hasDisconnectMenu, hasHelpMenu } = menuConfig;
  const [menu, setMenu] = useState([]);
  const user = useSelector((state) => state?.auth?.user);

  useEffect(() => {
    const newMenu = [[...menuItems]];
    if (hasHelpMenu) {
      newMenu.push(getHelpMenuItems(user));
    }

    if (hasDisconnectMenu) {
      // Push an array to have separator (group)
      newMenu.push([
        {
          label: <Text size="small">{t('navMenu.item.logout')}</Text>,
          gap: 'small',
          icon: <MenuItemIcon faIcon={faRightFromBracket} />,
          onClick: () => {
            setShowExitConfirmation(true);
          }
        }
      ]);
    }

    setMenu(newMenu);
  }, [user, menuItems, hasDisconnectMenu, hasHelpMenu, t]);

  const [showExitConfirmation, setShowExitConfirmation] = useState(false);

  return (
    <Box>
      <DisconnectModal show={showExitConfirmation} setShow={setShowExitConfirmation} />
      <Header style={{ overflow: 'hidden' }} background="back" height="50px" elevation="small" justify="between">
        <Box direction="row" justify="center">
          <Box margin="medium" height="40px" style={{ cursor: 'pointer' }} onClick={onClickLogo}>
            <Image style={{ marginTop: '5px' }} width="108px" src={logo} />
          </Box>
        </Box>
        <Box justify="center">{title}</Box>
        <Box direction="row" alignSelf="center" alignContent="center" justify="end" gap="small" width="200px">
          {canViewNotification && (
            <BadgeIcon
              icon={<MenuItemIcon faIcon={faBell} />}
              badgeValue={countNotifications}
              badgeColor="status-critical"
              justify={'center'}
              onClick={() => showNotifications()}
            />
          )}
          <AppBarMenu menuItems={menu} />
        </Box>
      </Header>
    </Box>
  );
};

export default AppBar;
