import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Menu, Text } from 'grommet';
import {
  createGoogleEvent,
  createOfficeEvent,
  createOutlookEvent,
  createYahooEvent,
  downloadIcalEvent,
  generateBeneficiaryCalendarData,
  generatePractitionerCalendarData
} from 'src/utility/calendar';

const GenerateMenuLabel = ({ label }) => {
  const { t } = useTranslation();
  return (
    <Box fill="horizontal" align="center">
      <Text color="brand" size="14px">
        {t(label)}
      </Text>
    </Box>
  );
};

/**
 * Generates the Calendar event addition menu items for all supported calendars
 * if user is null, called for a practitioner to add calendar event about JDS registration
 * otherwise it is for a beneficiary and we add a calender event for user appointment/registration
 * @param {object} event - the event to build a calendar event for
 * @param {object|null} user - if not null defines the beneficiary for which calendar event is generated
 * @param {object} company - the user company or company where JDS is scheduled
 * @returns {object[]} Array of Menu items
 */
const generateCalendarItems = (event, user, company) => {
  const menuItemClick = (item) => {
    let calendarData;
    if (user) {
      const { appointments = [], groupSessionEvent, practitioner } = event ?? {};
      // beneficiary case
      const appointment = groupSessionEvent ? null : appointments.find((e) => e.employee === user._id);
      calendarData = generateBeneficiaryCalendarData(event, company, appointment, practitioner);
    } else {
      // practitioner case
      calendarData = generatePractitionerCalendarData(event, company);
    }
    if (calendarData) {
      switch (item) {
        case 'google':
          window.open(createGoogleEvent(calendarData));
          break;
        case 'outlook':
          window.open(createOutlookEvent(calendarData));
          break;
        case 'office':
          window.open(createOfficeEvent(calendarData));
          break;
        case 'yahoo':
          window.open(createYahooEvent(calendarData));
          break;
        case 'iCalendar':
        default:
          downloadIcalEvent(calendarData);
      }
    }
  };

  return [
    {
      label: <GenerateMenuLabel label="common.google.agenda.label" />,
      onClick: () => {
        menuItemClick('google');
      }
    },
    {
      label: <GenerateMenuLabel label="common.outlook.agenda.label" />,
      onClick: () => {
        menuItemClick('outlook');
      }
    },
    {
      label: <GenerateMenuLabel label="common.office.agenda.label" />,
      onClick: () => {
        menuItemClick('office');
      }
    },
    {
      label: <GenerateMenuLabel label="common.yahoo.agenda.label" />,
      onClick: () => {
        menuItemClick('yahoo');
      }
    },
    {
      label: <GenerateMenuLabel label="common.iCalendar.agenda.label" />,
      onClick: () => {
        menuItemClick('iCalendar');
      }
    }
  ];
};

const EventCalendarMenu = ({ event, user = null, company }) => {
  const { t } = useTranslation();
  return (
    <Menu
      label={
        <Box fill="horizontal" align="center">
          <Text color="brand" size="15px">
            {t('common.save.agenda.label')}
          </Text>
        </Box>
      }
      items={generateCalendarItems(event, user, company)}
    />
  );
};

export default EventCalendarMenu;
