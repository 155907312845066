import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToolTip } from 'src/components';
import { THEME_COLORS } from 'src/config/theme';

/**
 * Renders a copy to clipboard icon to copy buffer provided as property
 * icon rotates on click and copy confirm tip displayed when operation completed
 * @param {Object} props - component properties
 * @param {string} props.buffer - text to copy to clipboard
 * @param {string} props.iconTipId - Localization Id of copy to clipboard icon tip
 * @param {string} props.copyTipId - Localization Id of copy confirmation tip
 * @param {string} [props.iconColor=THEME_COLORS.brand] - copy to clipboard icon color
 * @param {string} [props.iconSize='xs'] - copy to clipboard icon size
 * @returns {ReactComponentElement}
 */
const ClipboardCopy = ({ buffer, iconTipId, copyTipId, iconColor = THEME_COLORS.brand, iconSize = 'xs' }) => {
  const { t } = useTranslation();
  const [animate, setAnimate] = useState(false);
  const [showTip, setShowTip] = useState(false);
  return (
    <ToolTip
      content={t(copyTipId)}
      textSize="12px"
      backgroundColor={THEME_COLORS.brand}
      placement="top"
      forceHide={!showTip}
    >
      <span
        title={t(iconTipId)}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setAnimate(true);
          setTimeout(() => {
            setAnimate(false);
            setShowTip(true);
            setTimeout(() => setShowTip(false), 1000);
          }, 500);
          navigator.clipboard.writeText(buffer);
        }}
      >
        <FontAwesomeIcon
          style={{ animationDuration: '900ms' }}
          bounce={animate}
          icon={faCopy}
          color={iconColor}
          size={iconSize}
        />
      </span>
    </ToolTip>
  );
};

export default ClipboardCopy;
