import React from 'react';
import { Box, Text } from 'grommet';
import { theme } from 'src/config/theme';
import styled from 'styled-components';

const Back = styled(Box)`
  box-shadow: 0px 4px 20px rgba(221, 221, 221, 0.25);
`;
const Tab = styled(Box)`
  box-shadow: 0px 4px 20px rgba(221, 221, 221, 0.25);
`;

const MyTabs = (props) => {
  return (
    <Back
      background={theme.global.colors.greyBackground}
      round="7px"
      pad="xsmall"
      gap="small"
      direction="row"
      {...props}
    >
      {props.tabs.map((tab, i) => (
        <Tab
          {...props.tabProps}
          key={i}
          background={props.index === i ? 'white' : 'transparent'}
          round="7px"
          align="center"
          justify="center"
          pad="small"
          onClick={() => props.setIndex(i)}
        >
          <Text
            textAlign="center"
            weight={600}
            size={props.fontSize || 'medium'}
            color={props.index === i ? 'title' : 'text'}
          >
            {tab}
          </Text>
        </Tab>
      ))}
    </Back>
  );
};

export default MyTabs;
