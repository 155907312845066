import React, { useCallback, useEffect, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { Box } from 'grommet';
import { getRefreshToken } from 'src/utility/api';
import { getReactProcessVar } from 'src/utility/appConfig';
import { waitForDomElement } from 'src/utility/chartUtils';
import Spinner from '../../Spinner/Spinner';
import MongoChartError from './MongoChartError';

/**
 *
 * @param {string} [props.dashboardId] MongoDB charts dashboard Id provided by MongoDB Atlas
 * @param {string} [props.height] Any valid height prop value for a div
 * @param {string} [props.width] Any valid width prop value for a div
 * @param {Object[]} [props.charts] Array of object containing chart scope filters {chartId : String, filter : Object}. Filter props being any valid MQL query
 * @returns {Component} Embedded MongoDB Charts dashboard
 */
const MongoDashboard = ({ dashboardId, height = '87vh', width = '100%', charts }) => {
  // Can't use UseRef because we need to do some conditional rendering on error or loading and
  // if loading the div is not rendered hence the ref does not contain the div so the chart render ends up in error
  const sdk = new ChartsEmbedSDK({
    baseUrl: getReactProcessVar('REACT_APP_MONGO_CHARTS_PROJECT_BASE_URL'),
    getUserToken: () => getRefreshToken()
  });
  console.log(`MongoDashboard.js::main - ${dashboardId} - sdk instance`, sdk);
  const [dashboard, setDashboard] = useState();
  const [rendered, setRendered] = useState(false);
  const [error, setError] = useState(false);
  const dashboardDOMId = `#dashboard${dashboardId}`;

  useEffect(() => {
    if (dashboard && !rendered) {
      waitForDomElement(dashboardDOMId).then((dashboardDiv) => {
        console.log(
          `MongoDashboard.js::useEffect - ${dashboardId} - Div is defined and dashboard ready to be rendered`
        );
        console.log(`MongoDashboard.js::useEffect - ${dashboardId} - dashboard instance to be rendered : `, dashboard);
        dashboard
          .render(dashboardDiv)
          .then(() => {
            console.log(`MongoDashboard.js::useEffect - ${dashboardId} - dashboard rendered.`, dashboard);
            setRendered(true);
          })
          .catch((err) => {
            setError(true);
            console.error(`MongoDashboard.js::useEffect - ${dashboardId} - Error during dashboard rendering.`, err);
          });
      });
    }
  }, [dashboard, dashboardId, dashboardDOMId, rendered]);

  useEffect(() => {
    setError(false);
    setRendered(false);
    setDashboard(() => {
      const newDashboard = sdk.createDashboard({ dashboardId, showAttribution: false, autoRefresh: false });
      console.log(
        `MongoDashboard.js::useEffect - ${dashboardId} - dashboardId change detected, new dashboard instance :`,
        newDashboard
      );
      return newDashboard;
    });

    // We disable exhaustive deps because of memory leak issue when instantiating ChartsEmbedSDK in useEffect
    // since there is no destructor to invoke in cleanup process
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardId]);

  useEffect(() => {
    const setFilterToChart = (chart) => {
      const { chartId, filter } = chart;
      dashboard.getChart(chartId).then((chartInstance) => chartInstance.setFilter(filter));
    };
    if (dashboard && rendered && charts) {
      charts.forEach((chart) => setFilterToChart(chart));
    }
  }, [dashboard, rendered, charts]);

  const refreshDashboard = useCallback(() => {
    setError(false);
    dashboard
      .refresh()
      .then(() => setRendered(true))
      .catch((err) => {
        setError(true);
        console.error(
          `MongoDashboard.js::refreshDashboard - ${dashboardId} - Error while refreshing dashboard : `,
          err
        );
      });
  }, [dashboard, dashboardId]);

  return (
    <>
      <div id={`dashboard${dashboardId}`} style={{ height, width, ...((error || !rendered) && { display: 'none' }) }} />
      {error ? <MongoChartError onRefresh={refreshDashboard} fill margin={{ vertical: 'medium' }} /> : null}
      {!rendered && !error ? (
        <Box width={width} height={height}>
          <Spinner />
        </Box>
      ) : null}
    </>
  );
};

export default MongoDashboard;
